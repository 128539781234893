import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection';
import '../Utilities/DocumentStyles.css'

function Safety() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '600px',
                    
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#protect-yourself'> How to protect yourself and others</a></li>
                        <li><a href='#get-sick'> If you get sick</a></li>
                        <li><a href='#washing-hands'>Washing your hands & stopping the spread of germs</a></li>
                        <li><a href='#symptoms'>Symptoms of COVID-19</a></li>
                    </ol>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px',
                }}>

                    <h1 className='topic-headers' style={{
                    }} > Posters and References</h1>
                    <h2 className='sub-headers'> Reference:</h2>
                    <p className='document-content'>
                        <strong><i>Please look at the following posters and references that begin on the next page </i></strong>as they contain important information about returning to work under current conditions:
                    </p>

                    <FadeInSection >
                        <p className='document-content' style={{display:'inline-block'}}>
                            We follow the guiding principles of our state & local governments, OSHA and the CDC in returning to work with COVID-19. The following is an important poster which lists key points for employers in returning to work. For more information about businesses returning to work, <a href='https://www.cdc.gov/coronavirus/2019-ncov/community/general-business-faq.html#Reducing-the-Spread-of-COVID-19-in-Workplaces'>you can find that here:</a>
                        </p>
                        <img className='safety-photos' src="/safety_photos/safety_photo1.png" alt="safety_photo1" />
                    </FadeInSection>
                    <FadeInSection>
                        <h2 id='protect-yourself' className='sub-topic-headers'>How to protect yourself and others </h2>
                        <img className='safety-photos' src="/safety_photos/safety_photo2.png" alt="safety_photo2" />
                    </FadeInSection>
                    <FadeInSection>
                        <h2 id='get-sick' className='sub-topic-headers'>If you get sick</h2>
                        <img className='safety-photos' src="/safety_photos/safety_photo3.png" alt="safety_photo3" />
                    </FadeInSection>
                    <FadeInSection>
                        <img className='safety-photos' src="/safety_photos/safety_photo4.png" alt="safety_photo4" />
                    </FadeInSection>
                    <FadeInSection>
                        <h2 id='washing-hands' className='sub-topic-headers'>Washing your hands and stopping the spread of germs</h2>
                        <img className='safety-photos' src="/safety_photos/safety_photo5.png" alt="safety_photo5" />
                    </FadeInSection>
                    <FadeInSection>
                        <h2 id='symptoms' className='sub-topic-headers'>Symptoms of COVID-19</h2>
                        <img className='safety-photos' src="/safety_photos/safety_photo6.png" alt="safety_photo6" />
                    </FadeInSection>

                </div>
            </div>
        </div>

    )
}


export default Safety;
