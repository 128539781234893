import React, {
    useState,
    useEffect
} from 'react';
import { useParams } from 'react-router';
import { Card } from 'react-bootstrap'
import Part404 from './Part404'
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
API.configure(awsmobile)


const Part = () => {
    const { assetID } = useParams()
    const apiName = 'opsassetinventory';
    const path = '/asset/{assetId}';
    const myInit = {
        queryStringParameters: {
            assetId: assetID
        },
        response: true
    };
    const [loading, setLoading] = useState(true)
    const [assets, setAssets] = useState(null)
    const originalOrder = [
        'AssetID',
        'Description',
        'Location',
        'PartNumber',
        'URL',
        'CreatedAt'
    ]

    const assetList = () => {
        const assetKeys = Object.keys(assets)
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '15% 30%',
            }}>
                <Card style={{
                    overflow: 'hidden',
                    border: 'none',
                    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                    backgroundColor: 'lightblue',
                    padding: '2%'
                }}>
                    <div>
                        {originalOrder.map((k) =>
                            <p style={{ textAlign: 'left' }}>
                                <strong> {k}</strong>: {assets[k]}
                            </p>
                        )}
                    </div>
                    <div>
                        {assetKeys.map((v) => {
                            if (!originalOrder.includes(v)) {
                                return <p style={{ textAlign: 'left' }}><strong>{v}</strong>: {assets[v]}</p>
                            }
                        })}
                    </div>
                </Card>
            </div>
        )
    }

    useEffect(() => {
        const body = assetID
        API.post(apiName, path, myInit)
            .then(res => {
                setAssets(res['data']['Items'][0])
                setLoading(false)
            })
    }, [])

    return (
        <div>
            {(loading == false) ? assets ? assetList() : <Part404 /> : null}
        </div>
    )
}

export default Part;
