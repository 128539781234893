const availableCities = {
    list: [
        'San Francisco',
        'Las Vegas',
        'Los Angeles',
        'Redwood City',
        'Phoenix',
        'Houston',
        'Detroit'
    ]

}

const availableStates = {
    'Detroit': 'MI',
    'Houston': 'TX',
    'Las Vegas': 'NV',
    'Los Angeles': 'CA',
    'Phoenix': 'AZ',
    'Redwood City': 'CA',
    'San Francisco': 'CA',
}

function cityCoordinates(location) {
    // takes the selected location of the current component it is in and then makes a call to
    // an API that uses the location to render said component
    // used in Weather.jsx, Reconstructions.jsx, Telemetry.jsx currently
    let lat, lng;
    switch (location) {
        case 'San Francisco':
            lat = 37.754736
            lng = -122.432741
            break;
        case 'Las Vegas':
            lat = 36.1699
            lng = -115.13982
            break;
        case 'Redwood City':
            lat = 37.487499
            lng = -122.218288
            break;
        case 'Phoenix':
            lat = 33.448376
            lng = -112.074036
            break;
        case 'Houston':
            lat = 29.7632800
            lng = -95.3632700
            break;
        case 'Detroit':
            lat = 42.331429
            lng = -83.045753
            break;
        case 'Los Angeles':
            lat = 34.0522
            lng = -118.2437
            break;
        default:
            throw new Error('Selection is not part of our cities directory.')
    }
    return {
        lat: lat,
        lng: lng
    }
}


module.exports = {
    availableCities,
    availableStates,
    cityCoordinates
}