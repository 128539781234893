import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import './DriverForm.css'

const DriverForm = () => {
    let navigate = useNavigate()
    const [formAttributes, setFormAttributes] = useState({
        FirstName: null,
        LastName: null,
        Email: null,
        City: null,
        LocationState: null,
        Rig: null,
        SSID: null,
        Password: null,
        UploadSpeed: null,
        BaselineMeters: null,
        Year: null,
        Make: null,
        Model: null,
        Trim: null,
        KeyCore: null,
        LiveStreamHotSpots: null,
        InstallNotes: null

    })
    async function handleChange(evt) {
        let name = evt.target.name
        setFormAttributes({
            ...formAttributes,
            [name]: evt.target.value
        })
    }

    async function handleFormSubmit(evt) {
        evt.preventDefault();
        const body = { ...formAttributes }
        axios.post('https://5pnlsriv3g.execute-api.us-west-2.amazonaws.com/dev', { body })
        .then(res => console.log(body))
        .then(res => { navigate('/drivers/driverdirectory') })
    }

    return (
        <div style={{
            margin: `3% auto`,
            width: '50%',
            backgroundColor: `rgb(71, 71, 71)`,
            padding: '5%',
            color: 'white',
            boxShadow: ` 0px 8px 16px 0px rgb(0 0 0 / 80%)`
        }}>
            <h1 style={{ textShadow: `1px 1px white` }}>Add A Driver Form</h1>
            <Form onSubmit={handleFormSubmit} >
                <h1 className='form-header'>Contact Info</h1>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control name='FirstName' onChange={(evt) => handleChange(evt)} placeholder="Enter first name" />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control name='LastName' onChange={(evt) => handleChange(evt)} placeholder="Enter last name" />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Email </Form.Label>
                        <Form.Control name='Email' onChange={(evt) => handleChange(evt)} type="email" placeholder="Enter email" />
                    </Form.Group>
                </Row>

                <h1 className='form-header'>Vehicle Info</h1>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Vehicle Year </Form.Label>
                        <Form.Control name='Year' onChange={(evt) => handleChange(evt)} placeholder='Enter vehicle year' />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Vehicle Make </Form.Label>
                        <Form.Control name='Make' onChange={(evt) => handleChange(evt)} placeholder='Enter vehicle make' />
                    </Form.Group>

                    <Form.Group as={Col} >
                        <Form.Label>Vehicle Model  </Form.Label>
                        <Form.Control name='Model' onChange={(evt) => handleChange(evt)} placeholder='Enter vehicle model' />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label> Vehicle Trim</Form.Label>
                        <Form.Control name='Trim' onChange={(evt) => handleChange(evt)} placeholder='Enter trim of vehicle ' />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Baseline (meters) </Form.Label>
                        <Form.Control name='BaselineMeters' onChange={(evt) => handleChange(evt)} placeholder='Width of vehicle at A pillar ' />
                    </Form.Group>
                </Row>

                <h1 className='form-header'>Rig Info</h1>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>City</Form.Label>
                        <Form.Control name='City' onChange={(evt) => handleChange(evt)} placeholder="Enter city" />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>State</Form.Label>
                        <Form.Control name='LocationState' onChange={(evt) => handleChange(evt)} placeholder="Enter State" />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Rig</Form.Label>
                        <Form.Control name='Rig' onChange={(evt) => handleChange(evt)} placeholder='Please use agx-## format. For e.g. "agx-19" ' />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>SSID</Form.Label>
                        <Form.Control name='SSID' onChange={(evt) => handleChange(evt)} placeholder='Enter WiFi network name. For e.g. "MyHouse123"' />
                    </Form.Group>

                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>SSID Password</Form.Label>
                        <Form.Control name='Password' onChange={(evt) => handleChange(evt)} placeholder='Password to log into Wifi network ' />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Upload Speed (Mbps)</Form.Label>
                        <Form.Control name='UploadSpeed' onChange={(evt) => handleChange(evt)} placeholder='Enter upload speed & verify with screenshot' />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Key Core Number</Form.Label>
                        <Form.Control name='KeyCore' onChange={(evt) => handleChange(evt)} placeholder='Enter key core number' />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Live Stream Hotspot(s)</Form.Label>
                        <Form.Control name='LiveStreamHotSpots' onChange={(evt) => handleChange(evt)} placeholder='Enter one or many hotspots with comma seperation ' />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Install Notes</Form.Label>
                        <Form.Control style={{ height: '100px' }} as="textarea" name='InstallNotes' onChange={(evt) => handleChange(evt)} />
                    </Form.Group>
                </Row>

                <Button style={{ backgroundColor: 'rgb(69, 173, 168)', color: 'white', border: 'none' }} variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default DriverForm;