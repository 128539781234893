import React from "react";
import { number } from 'prop-types';

function DownArrow(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 256 256"
            version="1.1"
            viewBox="0 0 256 256"
            xmlSpace="preserve"
            width={props.size}
            height={props.size}
        >
            <path d="M225.813 48.907L128 146.72 30.187 48.907 0 79.093 128 207.093 256 79.093z"></path>
        </svg>
    );
}

DownArrow.propTypes = {
    size: number
}
DownArrow.defaultProps = {
    size: 50
}

export default DownArrow;
