import React from "react";

function UnauthorizedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            width={`300px`}
            height={`300px`}
        >
            <path
                fill="#455A64"
                d="M256 0c-82.436.094-149.239 66.898-149.333 149.333v96c0 5.891 4.776 10.667 10.667 10.667H160c5.891 0 10.667-4.776 10.667-10.667v-96C170.667 102.205 208.872 64 256 64s85.333 38.205 85.333 85.333v96c0 5.891 4.776 10.667 10.667 10.667h42.667c5.891 0 10.667-4.776 10.667-10.667v-96C405.239 66.898 338.436.094 256 0z"
            ></path>
            <path
                fill='rgba(0,255,106,1'
                d="M394.667 234.667H117.333c-17.673 0-32 14.327-32 32v192c0 29.455 23.878 53.333 53.333 53.333h234.667c29.455 0 53.333-23.878 53.333-53.333v-192c.001-17.673-14.326-32-31.999-32z"
            ></path>
            <path
                fill="#455A64"
                d="M284.821 372.693a42.066 42.066 0 0013.845-31.36c0-23.564-19.103-42.667-42.667-42.667s-42.667 19.103-42.667 42.667a42.07 42.07 0 0013.845 31.36l-13.312 39.936c-1.862 5.589 1.16 11.629 6.749 13.491 1.091.363 2.234.548 3.384.547h64c5.891.007 10.673-4.762 10.68-10.653a10.663 10.663 0 00-.547-3.384l-13.31-39.937z"
            ></path>
        </svg>
    );
}

export default UnauthorizedIcon;
