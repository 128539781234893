import React from 'react';
import { Card, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import DriverDirectoryIcon from '../NavBar/Icons/DriverDirectoryIcon'
import RigCommentsIcon from '../NavBar/Icons/RigCommentsIcon'
import '../Utilities/DocumentStyles.css'


function DriversMain() {
    return (
        <div>
            <h2
                className='card-headers-h2'
                style={{ textAlign: 'center' }}
            >
                Driver Portal</h2>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '0px auto',
                justifyContent: 'space-evenly'

            }}>
                <div className='card-media-layout' style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                }}>
                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'
                    }}>
                        <NavLink
                            to="/drivers/driverdirectory"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{
                                borderColor: 'transparent',
                                backgroundColor: 'transparent'
                            }}>
                                <Card.Body style={{
                                    borderColor: 'transparent',
                                    backgroundColor: 'transparent'
                                }}>
                                    <Card.Title style={{ color: 'black' }}>Driver Directory </Card.Title>
                                    <DriverDirectoryIcon size={250} />
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>
                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'
                    }}>
                        <NavLink
                            to="/drivers/rigcomments"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{
                                borderColor: 'transparent',
                                backgroundColor: 'transparent'
                            }}>
                                <Card.Body style={{
                                    borderColor: 'transparent',
                                    backgroundColor: 'transparent'
                                }}>
                                    <Card.Title style={{ color: 'black' }}>Comments Directory </Card.Title>
                                    <RigCommentsIcon size={250} />
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>
                </div>
            </div>
        </div>
    )
}


export default DriversMain;
