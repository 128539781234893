import React, { Component } from "react";
import { number } from 'prop-types';

class ClearDay extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 483.512 483.512"
                version="1.1"
                viewBox="0 0 483.512 483.512"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <path
                    fill="#FCB641"
                    d="M475.512 241.691L421.2 289.571 444.016 358.283 372.952 372.267 358.336 443.203 289.76 419.915 241.752 474.131 193.752 419.915 125.168 443.203 110.552 372.259 39.488 358.275 62.312 289.563 8 241.691 62.312 193.819 39.488 125.107 110.56 111.123 125.176 40.187 193.752 63.467 241.752 9.259 289.76 63.475 358.336 40.187 372.952 111.131 444.024 125.115 421.2 193.827z"
                ></path>
                <path
                    fill="#4D4D4D"
                    d="M241.752 482.131a8.021 8.021 0 01-5.992-2.696l-44.48-50.232-63.544 21.568c-2.168.728-4.56.512-6.544-.632a8.002 8.002 0 01-3.856-5.328l-13.544-65.728-65.848-12.96a7.991 7.991 0 01-6.048-10.368l21.152-63.688-50.336-44.368a8 8 0 01-2.712-6 8 8 0 012.712-6l50.336-44.376-21.152-63.696a8.039 8.039 0 01.68-6.552 7.974 7.974 0 015.368-3.816l65.848-12.96 13.544-65.72a8.002 8.002 0 013.856-5.328 7.924 7.924 0 016.544-.632l63.536 21.568 44.48-50.232c3.032-3.432 8.944-3.432 11.976 0l44.48 50.232 63.544-21.568c2.176-.736 4.552-.504 6.544.632s3.4 3.08 3.856 5.328l13.544 65.728 65.84 12.96a8.027 8.027 0 015.368 3.816 8.005 8.005 0 01.68 6.552l-21.16 63.696 50.344 44.368a7.892 7.892 0 012.744 6 7.972 7.972 0 01-2.712 6l-50.344 44.368 21.16 63.696a8.008 8.008 0 01-.68 6.552 8.027 8.027 0 01-5.368 3.816l-65.848 12.96-13.544 65.72c-.464 2.248-1.864 4.184-3.856 5.328s-4.376 1.376-6.544.632l-63.536-21.568-44.48 50.232a8.081 8.081 0 01-6.008 2.696zm-48-70.208a8.01 8.01 0 015.992 2.696l42.016 47.448 42.016-47.448a8.003 8.003 0 018.56-2.272l59.992 20.368 12.792-62.056a7.988 7.988 0 016.288-6.232l62.168-12.232-19.96-60.096a8.003 8.003 0 012.304-8.52l47.504-41.872-47.504-41.872a8.003 8.003 0 01-2.304-8.52l19.96-60.096-62.16-12.232a7.999 7.999 0 01-6.288-6.232l-12.792-62.064-60 20.368a7.963 7.963 0 01-8.56-2.272l-42.024-47.464-42.016 47.448c-2.128 2.416-5.512 3.328-8.56 2.272l-60-20.368-12.792 62.056a7.987 7.987 0 01-6.296 6.232l-62.16 12.232 19.96 60.096a8.003 8.003 0 01-2.304 8.52L20.08 241.683l47.504 41.864a7.989 7.989 0 012.304 8.52l-19.96 60.096 62.16 12.232a8.008 8.008 0 016.296 6.232l12.792 62.064 60-20.368c.84-.264 1.712-.4 2.576-.4z"
                ></path>
                <circle cx="241.752" cy="244.339" r="133.64" fill="#FDC567"></circle>
                <path
                    fill="#4D4D4D"
                    d="M241.752 385.979c-78.104 0-141.64-63.536-141.64-141.64s63.536-141.64 141.64-141.64 141.64 63.536 141.64 141.64c0 78.096-63.536 141.64-141.64 141.64zm0-267.28c-69.28 0-125.64 56.36-125.64 125.64s56.368 125.64 125.64 125.64c69.28 0 125.64-56.36 125.64-125.64s-56.36-125.64-125.64-125.64z"
                ></path>
            </svg>
        );
    }
}

ClearDay.propTypes = {
    size: number
}
ClearDay.defaultProps = {
    size: 500
}
export default ClearDay;
