import React from 'react';
import '../Utilities/DocumentStyles.css'

function EmployeeHandbook() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#employment'> Employment</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#at-will'>At-will Employment</a></li>
                            <li><a href='#equal-opp'>Equal Opportunity Employment</a></li>
                            <li><a href='#disabilities-act'>Disabilities Act</a></li>
                        </ol>
                        <li><a href='#conduct-and-behavior'> Conduct &amp; Behavior</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#anti-retaliation'>Anti-retaliation</a></li>
                            <li><a href='#confidentiality'>Confidentiality</a></li>
                        </ol>
                        <li><a href='#compensation'>Compensation</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#employee-classification'>Employee Classification</a></li>
                            <ol className='jumplink-ordered-list'>
                                <li><a href='#non-exempt'>Non-exempt</a></li>
                                <li><a href='#temporary'>Temporary</a></li>
                                <li><a href='#part-time'>Part-time</a></li>
                            </ol>
                            <li><a href='#payroll-schedule'>Payroll schedule</a></li>
                            <li><a href='#time-records'>Time Records</a></li>
                            <li><a href='#overtime'>Overtime</a></li>
                            <li><a href='#payroll-deductions'>Payroll deductions</a></li>
                        </ol>
                        <li><a href='#benefits'>Benefits</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#california-state'>California State Disability Insurance</a></li>
                            <li><a href='#workers-comp'>Workers’ Compensation</a></li>
                            <li><a href='#eligibility'>Eligibility</a></li>
                            <li><a href='#procedures'>Procedures</a></li>
                            <li><a href='#sick-leave'>Sick Leave</a></li>
                            <li><a href='#school-appearance'>School Appearance Leave</a></li>
                            <li><a href='#school-activities'>School Activities Leave</a></li>
                            <li><a href='#parental-leave'>Parental Leave</a></li>
                            <li><a href='#california-paid'>California Paid Family Leave</a></li>
                            <li><a href='#voting'>Voting</a></li>
                            <li><a href='#military-leave'>Military leave</a></li>
                            <li><a href='#spousal-military'>Spousal Military Leave</a></li>
                            <li><a href='#domestic-violence'>Domestic Violence, Sexual Assault, Stalking Leave Policy</a></li>
                            <li><a href='#crime-victim'>Crime Victim Leave Policy</a></li>
                        </ol>
                        <li><a href='#benefits-parttime'>Benefits for Part-time Employees</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#holidays'>Holidays</a></li>
                            <li><a href='#vacation'>Vacation</a></li>
                            <li><a href='#jury-duty'>Jury Duty</a></li>
                            <li><a href='#bereavement'>Bereavement</a></li>
                        </ol>
                        <li><a href='#agreement'>Employee Handbook Agreement</a></li>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <div style={{ width: '800px' }}>
                        <h2 id='employment' className='topic-headers'>Employment</h2>
                        <h3 id='at-will' className='sub-headers'>At-Will Employment</h3>
                        <p className='document-content'>Employment at Compound Eye is on an at-will basis unless otherwise stated in a written individual employment agreement signed by the CEO of the company.</p>
                        <p className='document-content'>This means that either the employee or the company may terminate the employment relationship at any time, for any reason, with or without notice.</p>
                        <p className='document-content'>Nothing in this employee handbook is intended to or creates an employment agreement, express or implied. Nothing contained in this or any other document provided to the employee is intended to be, nor should it be, construed as a contract that employment or any benefit will be continued for any period of time. In addition, no company representative is authorized to modify this policy for any employee or to enter into any agreement, oral or written, that changes the at-will relationship.</p>
                        <p className='document-content'>Nothing in this statement is intended to interfere with, restrain, or prevent concerted activity as protected by the National Labor Relations Act. Such activity includes employee communications regarding wages, hours, or other terms or conditions of employment. Compound Eye employees have the right to engage in or refrain from such activities.</p>
                        <h3 id='equal-opp' className='sub-headers'>Equal Opportunity Employment</h3>
                        <p className='document-content'>Our Company is committed to providing equal employment opportunities to all employees and applicants without regard to race, religion, color, sex, pregnancy (including breastfeeding and related medical conditions), national origin, citizenship status, uniform service member status, age, genetic information, disability, ancestry, gender identity and expression, sexual orientation, marital status, protected medical condition, or any other protected status in accordance with all applicable federal, state and local laws. </p>
                        <p className='document-content'>This policy extends to all aspects of our employment practices, including but not limited to, recruiting, hiring, placing, promoting, transferring, discipline, terminating, layoff, recall, leave of absence, compensation, training, and other terms and conditions of employment.</p>
                        <p className='document-content'>Compound Eye expressly prohibits any form of unlawful employee harassment or discrimination based on any of the characteristics mentioned above. Improper interference with the ability of other employees to perform their expected job duties is absolutely not tolerated.</p>
                        <p className='document-content'>Any employees with questions or concerns about equal employment opportunities in the workplace are encouraged to bring these issues to the attention of an HR representative. The Company will not allow any form of retaliation against individuals who raise issues of equal employment opportunity. If an employee feels he or she has been subjected to any such retaliation, he or she should bring it to the attention of an HR representative.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>Retaliation means adverse conduct taken because an individual reported an actual or perceived violation of this policy, opposed practices prohibited by this policy, or participated in the reporting and investigation process described below. “Adverse conduct” includes but is not limited to:</p>
                        <p className='document-content'>(1) shunning and avoiding an individual who reports harassment, discrimination or retaliation;</p>
                        <p className='document-content'>(2) express or implied threats or intimidation intended to prevent an individual from reporting harassment, discrimination or retaliation; or</p>
                        <p className='document-content'>(3) denying employment benefits because an applicant or employee reported harassment, discrimination or retaliation or participated in the reporting and investigation process.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>Complaints of discrimination should be filed according to the procedures described in the Harassment and Complaint Procedure.</p>
                        <h3 id='disabilities-act' className='sub-headers'>Americans with Disabilities Act (ADA) and Reasonable Accommodation</h3>
                        <p className='document-content'>Our Company is also committed to ensuring equal employment opportunities to qualified individuals with a disability, as well as employees’ religious beliefs and observances. Our Company will provide a reasonable accommodation for any known physical or mental disability of an otherwise qualified individual with a disability and/or employees’ religious beliefs and observances, provided the requested accommodation does not create an undue hardship for our Company and/or does not pose a direct threat to the health or safety of others in the workplace and/or to the individual.</p>
                        <p className='document-content'>Employees with a disability who believe they need a reasonable accommodation to perform the essential functions of their job are encouraged to contact their supervisor or another member of management. Depending on the nature of the accommodation sought, Compound Eye may request additional information to evaluate the feasibility of a particular accommodation.</p>
                        <h2 id='conduct-and-behavior' className='topic-headers'>Conduct &amp; Behavior</h2>
                        <h3 id='anti-retaliation' className='sub-headers'>Anti-Harassment, Including Sexual Harassment and Discrimination &amp; Retaliation</h3>
                        <p className='document-content'>Compound Eye is committed to providing a work environment that is free of unlawful harassment, discrimination, and retaliation. Further to this commitment, we strictly prohibit all forms of unlawful discrimination and harassment, which includes discrimination and harassment on the basis of actual or perceived race, religion, color, sex, pregnancy, national origin, citizenship status, uniform member or veteran status, age, genetic information or characteristics, disability, domestic partner or civil union status, sexual orientation, gender identity or expression, AIDS or HIV status, creed, marital status, domestic violence or stalking victim status, or any other category protected by applicable state or federal law.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>It is Compound Eye’s policy to provide a work environment free of sexual and other harassment. To that end, harassment of Compound Eye’s employees by management, supervisors, coworkers, or nonemployees who are in the workplace is absolutely prohibited. We prohibit employees from harassing co-workers as well as our customers, vendors, suppliers, independent contractors and others doing business with our company. Any such harassment will subject an employee to disciplinary action, up to and including termination. In addition, we prohibit our customers, vendors, suppliers, independent contractors and others doing business with us from harassing our employees.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>Further, retaliation against any employee who in good faith reports a claim of harassment or discrimination is strictly prohibited. The fact that an employee has raised a concern in good faith, or has provided information in an investigation, cannot be a basis for denial of benefits, termination, demotion, suspension, threats, harassment, discrimination, or any other mistreatment.</p>
                        <p className='document-content'></p>
                        <p className='document-content'><strong>Definition of Unlawful Harassment. </strong>“Unlawful harassment” is conduct that has the purpose or effect of creating an intimidating, hostile, or offensive work environment; has the purpose or effect of substantially and unreasonably interfering with an individual’s work performance; or otherwise adversely affects an individual’s employment opportunities because of the individual’s membership in a protected class.</p>
                        <p className='document-content'>Unlawful harassment includes, but is not limited to, epithets; slurs; jokes; pranks; innuendo; comments; written or graphic material; stereotyping; or other threatening, hostile, or intimidating acts based on race, color, ancestry, national origin, gender, sex, sexual orientation, marital status, religion, age, disability, veteran status, or other characteristic protected by state or federal law.</p>
                        <p className='document-content'></p>
                        <p className='document-content'><strong>Definition of Sexual Harassment. </strong>While all forms of harassment are prohibited, special attention should be paid to sexual harassment. “Sexual harassment” is generally defined under both state and federal law as unwelcome sexual advances, requests for sexual favors, and other verbal or physical conduct of a sexual nature where:</p>
                        <ul className='unordered-list'>
                            <li>Submission to or rejection of such conduct is made either explicitly or implicitly a term or condition of any individual’s employment or as a basis for employment decisions; or</li>
                            <li>Such conduct has the purpose or effect of unreasonably interfering with an individual’s work performance or creating an intimidating, hostile, or offensive work environment.</li>
                        </ul>
                        <p className='document-content'>Other sexually oriented conduct, whether intended or not, that is unwelcome and has the effect of creating a work environment that is hostile, offensive, intimidating, or humiliating to workers may also constitute sexual harassment.</p>
                        <p className='document-content'>While it is not possible to list all those additional circumstances that may constitute sexual harassment, the following are some examples of conduct that, if unwelcome, may constitute sexual harassment depending on the totality of the circumstances, including the severity of the conduct and its pervasiveness:</p>
                        <ul className='unordered-list' >
                            <li>Unwanted sexual advances;</li>
                            <li>Sexual epithets, jokes, written or oral references to sexual conduct, gossip regarding one’s sex life, comments about an individual’s body, comments about an individual’s sexual activity, deficiencies, or prowess;</li>
                            <li>Displaying sexually suggestive objects, pictures, or cartoons;</li>
                            <li>Unwelcome leering, whistling, brushing up against the body, sexual gestures, or suggestive or insulting comments;</li>
                            <li>Inquiries into one’s sexual experiences; and</li>
                            <li>Discussion of one’s sexual activities.</li>
                        </ul>
                        <p className='document-content'>All employees should take special note that, as stated above, retaliation against an individual who has complained about sexual harassment and retaliation against individuals for cooperating with an investigation of sexual harassment complaint is unlawful and will not be tolerated at Compound Eye.</p>
                        <p className='document-content'></p>
                        <p className='document-content'><strong>Report Procedure. </strong>Any employee who believes he or she has been subject to or witnessed illegal discrimination, including sexual or other forms of unlawful harassment, is requested and encouraged to make a report. You may report directly to your immediate supervisor, an HR representative, or any other member of management with whom you feel comfortable bringing such a report. Similarly, if you observe acts of discrimination toward or harassment of another employee, you are requested and encouraged to report this to one of the individuals listed above. Managers who receive any complaint of harassment or inappropriate behavior must promptly report such a complaint to the Leadership team.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>No reprisal, retaliation, or other adverse action will be taken against an employee for making a complaint or report of discrimination or harassment or for assisting in the investigation of any such complaint or report in good faith. Any suspected retaliation or intimidation should be reported immediately to one of the persons identified above.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>All complaints of unlawful harassment that are reported to management will be addressed and appropriate corrective action will be taken where warranted. We prohibit employees from hindering internal investigations and the internal complaint procedure. It is a condition of employment that employees cooperate in all of the Company’s investigations. All complaints of harassment that are reported to management will be treated confidentially to the extent practical and appropriate under the circumstances.</p>
                        <p className='document-content'></p>
                        <p className='document-content'>Violation of this policy will subject an employee to disciplinary action, up to and including immediate termination. Moreover, any employee who condones or ignores potential violations of this policy will be subject to appropriate disciplinary action, up to and including termination.</p>
                        <h3 id='confidentiality' className='sub-headers'>Confidential Information &amp; Conflicts of Interest</h3>
                        <p className='document-content'>Compound Eye expects all employees to conduct themselves and company business in a manner that reflects the highest standards of ethical conduct, and in accordance with all federal, state, and local laws and regulations. This includes avoiding real and potential conflicts of interests.</p>
                        <p className='document-content'>As a condition of employment, all Compound Eye employees have signed the Employee Invention Assignment And Confidentiality Agreement, which includes the following sections:</p>
                        <ol start={9}>
                            <li><strong> Confidentiality.</strong> At all times, both during my employment and after its termination, and to the fullest extent permitted by law, I will keep and hold all Proprietary Information in strict confidence and trust. I will not use or disclose any Proprietary Information without the prior written consent of the Company in each instance, except as may be necessary to perform my duties as an employee of the Company for the benefit of the Company. Upon termination of my employment with the Company, I will promptly deliver to the Company all documents and materials of any nature pertaining to my work with the Company, and I will not take with me or retain in any form any documents or materials or copies containing any Proprietary Information. Nothing in this Section 9 or otherwise in this Agreement shall limit or restrict in any way my immunity from liability for disclosing the Company’s trade secrets as specifically permitted by 18 U.S. Code Section 1833, the pertinent provisions of which are attached hereto as Exhibit C.</li>
                        </ol>
                        <ol start={13}>
                            <li><strong> Company Opportunities; Duty Not to Compete.</strong> During the period of my employment, I will at all times devote my best efforts to the interests of the Company, and I will not, without the prior written consent of the Company, engage in, or encourage or assist others to engage in, any other employment or activity that: (i) would divert from the Company any business opportunity in which the Company can reasonably be expected to have an interest; (ii) would directly compete with, or involve preparation to compete with, the current or future business of the Company; or (iii) would otherwise conflict with the Company’s interests or could cause a disruption of its operations or prospects.</li>
                        </ol>
                        <p className='document-content'>All inquiries from the media must be referred to the CEO.</p>
                        <p className='document-content'>This provision is not intended to, and should not be interpreted to prohibit employees from discussing wages and other terms and conditions of employment if they so choose. </p>
                        <h2 id='compensation' className='topic-headers'>Compensation</h2>
                        <h3 id='employee-classifcation' className='sub-headers'>Employee Classification</h3>
                        <p className='document-content'>Prior to their first day, all Compound Eye employees must have returned an executed offer letter outlining their relationship to the company and classifying them as a full-time employee, a part-time employee, or a short-term employee. Employees are also required to execute an Employee Invention Assignment And Confidentiality Agreement (EIACA) prior to or immediately upon the commencement of employment. All employees are designated as either nonexempt or exempt under state and federal wage and hour laws.</p>
                        <p className='document-content'>The following is intended to help drivers understand employment classifications, employment status, and benefit eligibility. These classifications do not guarantee employment for any specified period of time. The right to terminate the employment-at-will relationship at any time is retained by both the employee and Compound Eye.</p>
                        <p id='non-exempt' className='document-content'><strong>Non-exempt employees</strong> are employees whose work is covered by the Fair Labor Standards Act (FLSA). They are ​not exempt from the law’s requirements concerning minimum wage and overtime, meaning that such employees are eligible for overtime pay.</p>
                        <p className='document-content'>Compound Eye has established the following categories for non-exempt drivers:</p>
                        <p id='temporary' className='document-content'><strong>Temporary employees</strong> are employees who are hired to temporarily supplement the workforce or to work on special projects for short periods of time, typically no longer than 90 days. These positions are not intended to be a part of continuing operations. Employment of temporary employees beyond any initially stated period does not in any way imply a change in employment status.</p>
                        <p id='part-time' className='document-content'><strong>Part-time employees</strong> are not in a temporary status and are regularly scheduled to work less than 30 hours each week. Regular, part-time employees are eligible for some of the benefits offered by the company subject to the terms, conditions, and limitations of each benefits program.</p>
                        <p className='document-content'>If you have any questions concerning your classification or the benefits for which you qualify, please contact an HR representative or the applicable benefit plan documents.</p>
                        <h3 id='payroll-schedule' className='sub-headers'>Payroll Schedule</h3>
                        <p className='document-content'>Compound Eye’s payroll cycle runs bi-weekly (every other week). Weekly pay periods run Sunday at 12:00am to Saturday at 11:59pm.</p>
                        <p className='document-content'>With your consent, you will be paid via direct deposit into either a savings or checking account at the financial institute of your choice. If you are a new employee and enroll in direct deposit, in your first pay period you may still receive a physical check as the direct deposit is being set up. You may change your deposit selections at any time. If you choose direct deposit your check stub will be made available at the time of issuance through the employee self-service portal. (You will not receive a paper stub.) To view your actual pay stubs, which itemize your deductions and provide tax withholding and other details, simply login into Sequoia One at <a href="https://seq.prismhr.com/">https://seq.prismhr.com</a>​.</p>
                        <p className='document-content'>Any questions about the number of hours for which you have been credited and paid, the amount of your pay or deductions should be brought to the attention of your supervisor or an HR representative.</p>
                        <h3 id='time-records' className='sub-headers'>Time Records</h3>
                        <p className='document-content'>All nonexempt employees are required to complete accurate weekly time reports showing all time actually worked. These records are required by governmental regulations and are used to calculate regular and overtime pay.</p>
                        <p className='document-content'>Temporary and Part-Time drivers time sheets are logged in the Sequoia One HR system. A digital time clock is provided for convenience, however all drivers will have full access to edit their own timesheets and will be held accountable to their accuracy. Digital audits of timesheets will be performed against driving records on a regular basis. Drivers are expected to keep at least 4 weeks of driving records for this purpose. These logs can be kept via the mobile driver application provided by the company to track your progress.</p>
                        <p className='document-content'>Temporary and Part-Time drivers are given the utmost freedom in their schedule and set their own hours prior to the work week. Because of this: Any missed shifts should be reported to fleet support as soon as possible. Missed shifts without notice or multiple absences from scheduled shifts may result in disciplinary action.</p>
                        <h3 id='overtime' className='sub-headers'>Overtime and Work Schedule </h3>
                        <p className='document-content'>On average, Temporary and Part-Time drivers may not work more than 20 hours per week. Occasional supervisors will request or grant permission to drivers to work over 20 hours per week. Pre-approval must be granted in writing.</p>
                        <p className='document-content'>Temporary and Part-Time drivers report their expected work schedule by 10am the Friday before the week begins and are subject to manager approval. It is at this time that the Driver may request additional hours in writing or request time off from work. In general, it is the responsibility of the driver to schedule themselves for 20 hours.</p>
                        <p className='document-content'>Compound Eye may periodically be required to schedule overtime or weekend work in order to meet business needs. Overtime is actual hours worked in excess of 40 in a single workweek. Non-exempt employees will be paid overtime compensation at the rate of one and one half their regular rate of pay for all hours over 40 actually worked in a single workweek. Paid leave, such as holiday, PTO, bereavement time, and jury duty does not apply toward work time.</p>
                        <p className='document-content'>We will attempt to give as much advance notice as possible, and we expect that all employees who are scheduled to work overtime will be at work, unless excused by their supervisor. Otherwise, all overtime work must be pre-approved by your supervisor. Working overtime without your supervisor’s approval may result in discipline, up to and including termination.</p>
                        <p className='document-content'>Your supervisor will inform you of the hours you are to work. Due to changing needs of our business, your actual work schedule may vary from time to time. If it does, your supervisor will notify you. Management retains the right to reassign employees to a different shift where it is necessary for the efficient operation of our company. </p>
                        <h3 id='payroll-deductions' className='sub-headers'>Payroll Deductions</h3>
                        <p className='document-content'>Compound Eye complies with the salary basis requirements of the Fair Labor Standards Act (FLSA). Employees classified as exempt from the overtime pay requirements of the FLSA will be notified of this classification at the time of hire or change in position.</p>
                        <p className='document-content'><strong>Permitted deductions. </strong>The FLSA limits the types of deductions that may be made from the pay of an exempt employee. Deductions that are permitted include:</p>
                        <ol style={{ textAlign: 'left' }}>
                            <li>Deductions that are required by law, e.g., income taxes;</li>
                            <li>Deductions for employee benefits when authorized by the employee;</li>
                            <li>Absence from work for one or more full days for personal reasons other than sickness or disability;</li>
                            <li>Absence from work for one or more full days due to sickness or disability if the deduction is made in accordance with a bona fide plan, policy or practice of providing compensation for salary lost due to illness;</li>
                            <li>Offset for amounts received as witness or jury fees, or for military pay; or</li>
                            <li>Unpaid disciplinary suspensions of one or more full days imposed in good faith for workplace conduct rule infractions.</li>
                        </ol>
                        <p className='document-content'>During the week an exempt employee begins work for the company or during the last week of employment, the employee will only be paid for actual hours worked. In addition, an employee may be paid only for hours worked during a period when the employee is using unpaid leave under the Family and Medical Leave Act (FMLA).</p>
                        <p className='document-content'><strong>Improper deductions.</strong> If an employee classified as exempt believes that an improper deduction has been taken from his or her pay, the employee should immediately report the deduction to the Human Resources Department. The report will be promptly investigated and if it is found that an improper deduction has been made, the company will reimburse the employee for the improper deduction.</p>
                        <h2 id='benefits' className='topic-headers'>Benefits</h2>
                        <p className='document-content'>Our Company provides the following benefits to eligible employees. We reserve the right to terminate or modify these plans at any time, for any reason, with or without advance notice to employees.</p>
                        <h3 className='sub-headers'>California State Disability Insurance</h3>
                        <p className='document-content'>The California State Disability Insurance (SDI) program provides short-term disability insurance (DI) and paid family leave (PFL) wage replacement benefits to eligible workers who need time off work.</p>
                        <p className='document-content'>DI is a partial wage replacement program to provide short-term benefits to eligible California workers who suffer a loss of wages when they are unable to work due to a nonwork-related illness or injury or when they are medically disabled due to pregnancy or childbirth. It is typically funded through employee payroll deductions and is administered through the Employment Development Department (EDD).</p>
                        <p className='document-content'>For more information on eligibility and enrollment, visit <a href="https://www.edd.ca.gov/disability/">https://www.edd.ca.gov/disability/</a></p>
                        <h3 id='workers-comp' className='sub-headers'>Workers’ Compensation</h3>
                        <p className='document-content'>Compound Eye provides workers’ compensation insurance benefits to all employees who experience an injury or illness that arises out of the course and scope of employment. Workers' compensation insurance provides six basic benefits: medical care, temporary disability benefits, permanent disability benefits, supplemental job displacement benefits or vocational rehabilitation, and death benefits.</p>
                        <p className='document-content'>Entitlement to workers’ compensation benefits is controlled by applicable law, and as detailed in the company’s Work-Related Accident and Injury Policy, employees are required to immediately report all work-related accidents, injuries and illnesses.</p>
                        <h4 id='eligibility' className='sub-headers'>Eligibility</h4>
                        <p className='document-content'>This policy applies to all employees who experience an injury or illness that arises out of the course and scope of employment.</p>
                        <h4 id='procedures' className='sub-headers'>Procedures</h4>
                        <p className='document-content'>All work-related accidents, injuries or illnesses involving employees, even those that are not serious, must be immediately reported to your immediate supervisor or an HR representative. Employees who experience a work-related accident, illness or injury will be required to complete the appropriate forms and cooperate with the company in complying with its recording, reporting and investigation obligations.</p>
                        <p className='document-content'>If the work-related accident, injury or illness results in being placed on a leave of absence, the company’s various leave policies will apply to that absence. The company strives to bring employees back to work as soon as possible following a work-related accident, injury or illness. Thus, while on a leave of absence, you should stay in contact with immediate supervisor or an HR representative regarding your expected return to work.</p>
                        <p className='document-content'>When requested, the company will provide a reasonable accommodation for any known physical or mental disability of a qualified individual, provided the requested accommodation does not create an undue hardship for the company or pose a direct threat to the health or safety of others in the workplace or of the requesting employee. Once the company is aware of the need for an accommodation, the company will engage with the employee in an interactive process to identify possible accommodations.</p>
                        <h3 id='sick-leave' className='sub-headers'>Sick Leave</h3>
                        <p className='document-content'>Non-exempt employees accrue sick leave at a rate of 1 hour for every 30 hours worked. Employees are entitled to be paid for sick leave used, to a maximum of the time accrued, under the following conditions:</p>
                        <ul className='unordered-list'>
                            <li>Sick leave may be used for an employee or a family member, for preventive care or diagnosis, care or treatment of an existing health condition, or for specified purposes if the employee is a victim of domestic violence, sexual assault or stalking.</li>
                            <li>Family members include the employee’s parent, child, spouse, registered domestic partner, grandparent, grandchild, sibling, mother-in-law and/or father-in-law.</li>
                        </ul>
                        <p className='document-content'>Employees should notify their supervisor as promptly as possible if they are requesting authorization for sick leave. When an employee separates from Compound Eye, their remaining sick leave allowance will not be added to their final compensation.</p>
                        <h3 id='school-appearance' className='sub-headers'>School Appearance Leave</h3>
                        <p className='document-content'>Compound Eye will not, in any way, discriminate against an employee who takes time off to appear at school in conjunction with a child’s or ward’s suspension from a class or school.</p>

                        <h3 id='school-activities' className='sub-headers'>School Activities Leave</h3>
                        <p className='document-content'>Parents (including parent, guardian, stepparent, foster parent, grandparent or person who stands in loco parentis to the child) are entitled to job-protected leave for up to 40 hours each year for the following reasons:</p>
                        <ul className='unordered-list'>
                            <li>To find, enroll, or re-enroll your child in a school or with a licensed child care provider, or participate in activities of the school or licensed child care provider of their child.</li>
                            <li>To address a child care provider or school emergency.</li>
                        </ul>
                        <p className='document-content'>You must provide reasonable notice to your supervisor. For absences due to enrollment, reenrollment and activities of a school or licensed child care provider, employees must provide at least two week’s notice. If absence is due to a child care or school emergency, you must notify your supervisor as soon as possible.</p>
                        <p className='document-content'>Time taken for enrollment, reenrollment, and activities may be limited to eight (8) hours in any calendar month.</p>
                        <h3 id='parental-leave' className='sub-headers'>Parental Leave</h3>
                        <p className='document-content'>Compound Eye complies with the federal, state, and local laws governing parental leave.</p>
                        <h4 id='california-paid' className='sub-headers'>California Paid Family Leave</h4>
                        <p className='document-content'>Under California state law, the California Employment Development Department (EDD) funds California's Paid Family Leave (PFL) program. PFL is not a "leave" program, and it does not provide employees with leave beyond what is provided in the company's policies. Instead, PFL is a disability benefits program that provides eligible employees with compensation for lost wages, for up to six weeks in a 12-month period when employees need to take time off work to care for a seriously ill spouse, domestic partner, parent, parent-in-law, grandparent, grandchild or sibling or to bond with a new child. For information concerning these benefits, employees should call the EDD at (877) 238-4373, or visit <a href="http://www.edd.ca.gov/Disability/SDI_Online.htm">the PFL website</a>.</p>
                        <p className='document-content'>Employees who need to take time off work to care for a spouse, domestic partner, parent, parent-in-law, grandparent, grandchild or sibling with a serious health condition, or to bond with a new child, should contact an HR representative as soon as practicable.</p>
                        <h3 id='voting' className='sub-headers'>Voting</h3>
                        <p className='document-content'>Compound Eye recognizes that voting is a right and privilege of being a citizen of the United States and encourages employees to exercise their right to vote. In almost all cases, you will have sufficient time outside working hours to vote. If for any reason you think this won’t be the case, contact your supervisor to discuss scheduling accommodations.</p>
                        <h3 id='military-leave' className='sub-headers'>Military Leave</h3>
                        <p className='document-content'>Compound Eye is committed to protecting the job rights of employees absent on military leave. In accordance with federal and state law, it is the Company’s policy that no employee or prospective employee will be subjected to any form of discrimination on the basis of that person's membership in or obligation to perform service for any of the Uniformed Services of the United States. Specifically, no person will be denied employment, reemployment, promotion, or other benefit of employment on the basis of such membership.</p>
                        <p className='document-content'>Upon return from military leave, employees will be granted the same seniority, pay, and benefits as he or she had before leaving. Failure to report for work within the prescribed time after completion of military service will be considered a voluntary termination. The absence may not exceed five years combined from the same employer.</p>
                        <p className='document-content'>Additionally, employees may choose to use accrued paid leave concurrently with some or all of the leave under this policy. To receive paid leave, eligible employees must comply with the company’s normal procedures for the applicable paid-leave policy.</p>
                        <p className='document-content'>For more information regarding military leave, employees should contact and HR representative.</p>
                        <h4 id='spousal-military' className='sub-headers'>Spousal Military Leave</h4>
                        <p className='document-content'>Employees may take up to ten days of unpaid leave while their spouse is on leave from military deployment. To be eligible for this leave, the employee’s spouse must be a member of the Armed Forces of the United States on leave from deployment during a period of military conflict in an area designated as a combat theater or combat zone. Compound Eye may request a copy of the orders for his/her spouse certifying that the spouse will be on leave from deployment during the time of the employee’s leave.</p>
                        <h3 id='domestic-violence' className='sub-headers'>Domestic Violence, Sexual Assault, Stalking Leave Policy</h3>
                        <p className='document-content'>Compound Eye provides reasonable and necessary unpaid leave and other reasonable accommodations to employees who are victims of domestic violence, sexual assault or stalking to attend legal proceedings or obtain other needed relief.</p>
                        <p className='document-content'>Unpaid leave under this policy is available for an employee who is the victim of domestic violence, sexual assault or stalking to attend legal proceedings or to obtain or attempt to obtain any relief necessary, including a restraining order, to ensure the employee’s own health, safety or welfare, or that of the employee’s child or children. Employees may also request unpaid leave for the following purposes:</p>
                        <ol style={{ textAlign: 'left' }}>
                            <li>Obtain services from a domestic violence shelter or rape crisis center.</li>
                            <li>Seek medical attention for injuries caused by domestic violence or sexual assault.</li>
                            <li>Obtain psychological counseling for the domestic violence or sexual assault.</li>
                            <li>Take action, such as relocation, to protect against future domestic violence or sexual assault.</li>
                        </ol>
                        <p className='document-content'>To request leave under this policy, an employee should provide his or her supervisor or an HR representative with as much advance notice as practicable under the circumstances. If advance notice is not possible, the employee requesting leave under this policy should provide other documentation upon returning back to work.</p>
                        <p className='document-content'>Employees requesting leave under this policy may choose to use accrued paid leave.</p>
                        <p className='document-content'>In addition, the company will provide reasonable accommodations to employees who are victims of domestic violence, sexual assault or stalking for the employees’ safety while at work. A reasonable accommodation may include the implementation of safety measures, such as a transfer, reassignment, modified schedule, changed work telephone, changed work station or installed lock; assistance in documenting domestic violence, sexual assault or stalking that occurs in the workplace; an implemented safety procedure; or another adjustment to the employee’s job duties and position.</p>
                        <p className='document-content'>To request an accommodation under this policy, an employee should contact an HR representative. The company will engage the employee in a timely, good faith and interactive process to determine effective reasonable accommodations.</p>
                        <h3 id='crime-victim' className='sub-headers'>Crime Victim Leave Policy</h3>
                        <p className='document-content'>Compound Eye provides unpaid leave to each eligible employee who is a victim of certain, specified felony crimes, or who is an immediate family member of a victim, a registered domestic partner of a victim or the child of a registered domestic partner of a victim of certain, specified felony crimes to attend and participate in judicial proceedings related to the crime. Appropriate documentation may be requested before the leave, or if not possible, as soon as is reasonable to do so.</p>
                        <p className='document-content'>Employees may use accrued, unused paid time off for leave taken under this policy. To request an accommodation under this policy, an employee should contact an HR representative.</p>
                        <h2 id='benefits-parttime' className='topic-headers'>Benefits for Part-time Employees</h2>
                        <p className='document-content'>Compound Eye extends some benefits to eligible part-time (non-temporary) employees. As with all benefits, we reserve the right to terminate or modify these plans at any time, for any reason, with or without advance notice to employees.</p>
                        <h3 id='holidays' className='sub-headers'>Holidays</h3>
                        <p className='document-content'>Compound Eye observes 10 holidays per year. All observed holidays are viewable in Sequoia at <a href="https://seq.prismhr.com/">https://seq.prismhr.com/</a>. Holidays are as follows:</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p className='document-content'>New Year's Day</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>Independence Day</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>Labor Day</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>Thanksgiving</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>Day after Thanksgiving</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>Christmas Eve</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>Christmas Day</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='document-content'>New Year's Eve</p>
                                    </td>
                                    <td>
                                        {/* <p className='document-content'> place holder </p> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className='document-content'>When a Holiday Observance falls on a scheduled work day and the employee does not work on that day, part-time non-exempt employees receive pay for their straight-time hours scheduled for the day.</p>
                        <h3 id='vacation' className='sub-headers'>Vacation</h3>
                        <p className='document-content'>Part-time employees are entitled to vacation with pay and accrue 1 vacation hour for each 20 hours worked. The time at which hourly employees shall be granted vacations is at the discretion of their supervisor. When an hourly employee separates from Compound Eye, their remaining vacation allowance will be added to their final compensation.</p>
                        <h3 id='jury-duty' className='sub-headers'>Jury Duty &amp; Court Appearance Leave</h3>
                        <p className='document-content'>Compound Eye encourages all employees to accept their civic responsibilities, and as a good corporate citizen, the company supports employees in the performance of these duties. The company provides leave to eligible employees who are called to serve as jurors or who are summoned to appear as witnesses in a judicial proceeding, pursuant to a subpoena or other court order.</p>
                        <p className='document-content'>Employees should notify the company as soon as practicable of the need for leave under this policy when they are called to jury service or summoned to witness duty, including providing appropriate documentation.</p>
                        <p className='document-content'>Non-exempt employees will be compensated for up to two weeks of their regularly scheduled pay when serving as jurors or appearing as witnesses in a judicial proceeding. After two weeks, employees may use accrued, unused paid time off for additional leave taken under this policy.</p>
                        <p className='document-content'>Employees requesting leave under this policy should notify their immediate supervisor or an HR representative as soon as possible. Compound Eye may request appropriate documentation, including a copy of the jury summons, subpoena or other court order as soon as practicable. An employee who is released from jury service or witness duty before the end of his or her regularly scheduled shift is expected to call his or her supervisor as soon as possible and report to work if requested.</p>
                        <h3 id='bereavement' className='sub-headers'>Bereavement Leave</h3>
                        <p className='document-content'>We are sympathetic to the needs of our employees and their families when there is a loss of a loved one. Compound Eye provides Bereavement Leave to give employees time to attend to the legal, financial, and emotional needs of their families in the event of such a loss.</p>
                        <p className='document-content'>All non-exempt employees under accrued PTO policy are eligible for up to 5 days of paid time off in the event of a death in their immediate family. Immediate family is defined as a spouse or significant other, child, parent, sibling, grandparent, grandchild, or immediate in-laws (other relationships may be approved on a case-by-case basis). Employees are eligible for up to 3 days paid time off in the event of death of the employee's extended family or close friend. Employees are eligible for up to 1 day paid time off to attend all other funerals. <br /><br /></p>
                        <p className='document-content'>Please notify your manager and an HR representative if you need to use Bereavement Leave. We are always here to help.</p>
                        <h2 id='agreement' className='topic-headers'>Employee Handbook Agreement</h2>
                        <p className='document-content'>The undersigned employee hereby acknowledges that he/she has received a copy of the Employee Handbook for Compound Eye.</p>
                        <p className='document-content'>The employee further understands and agrees that:</p>
                        <ul className='unordered-list'>
                            <li>The handbook is intended to be a general guide to the rules, policies and procedures of Compound Eye and is not an employment agreement or a guarantee of employment.</li>
                            <li>The employee is an at-will employee, meaning that both the employee and the Company may terminate the employment relationship at any time, with or without cause or advance notice. The employee´s at-will status can only be changed through a written employment contract that is authorized and signed by the CEO. Any oral statements, promises, or other contracts are hereby deemed invalid.</li>
                            <li>The Company reserves the right to make changes to the employment handbook without prior notice.</li>
                        </ul>
                        <p className='document-content'><strong>Employee Statement</strong></p>
                        <p className='document-content'>I acknowledge receipt of Compound Eye´s employee handbook and understand that it is up to me to read and familiarize myself with its contents. I have read and understood all of the above information, and I acknowledge my at-will employment status.</p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default EmployeeHandbook;