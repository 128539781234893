import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function ShiftChecklist() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#pre-shift'> Pre-Shift</a></li>
                        <li><a href='#post-shift'> Post-Shift</a></li>
                        <li><a href='#driving-rules'>Driving Rules</a></li>
                        <li><a href='#vehicle-exit'>Vehicle Exit</a></li>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > Shift Checklist</h1>

                    <h2 id='pre-shift' className='sub-headers'> Pre-Shift:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li> Wipe down the glass on the cameras with moist cloth or paper towel. Use a fresh part of the towel for each wipe or else you will trap a piece of dirt and scratch the glass.</li>
                            <li>Make sure the rig is attached firmly. If necessary, give a quick righty-tighty twist with your fingers on the gold SMA connectors to tighten.</li>
                        </ol>
                    </p>
                    <h2 id='post-shift' className='sub-headers'> Post-Shift:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li> Make sure the car is parked safely.</li>
                            <li>Make sure you are in range of your wifi, the system should do the rest.</li>
                        </ol>
                    </p>
                    <h2 id='driving-rules' className='sub-headers'> Driving rules:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li> Follow the additional COVID-19 checklist.</li>
                            <li>Keep a reasonable following distance (so that the vehicle in front of you obstructs less of the camera view).</li>
                            <li>Try not to use your wiper fluid while driving-- clean your windshields while stopped and wipe off the camera glass if droplets get on there.</li>
                            <li>Don't drive in the rain, waterproof enclosures are still in-progress.</li>
                        </ol>
                    </p>
                    <h4 id='vehicle-exit' className='sub-headers'> If you exit the vehicle, please log the information <a href='https://docs.google.com/spreadsheets/d/1HZTkbTscdN1OLrHkZKUio7GTfvpPO2afXCUubqd7EMk/edit#gid=1627619981'> here</a>. </h4>
                </div>
            </div>
        </div>

    )
}


export default ShiftChecklist;
