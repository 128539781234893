import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import { Card, Button, Media } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import '../Utilities/DocumentStyles.css'


function DashboardsMain() {
    return (
        <div>
            <h2 className='card-headers-h2' style={{ textAlign: 'center' }}>Dashboards</h2>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '0px auto',
                justifyContent: 'space-evenly'

            }}>
                <div className='card-media-layout' style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                }}>

                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'
                    }}>

                        <NavLink
                            to="/dashboards/weather"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                <Card.Body style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                    <Card.Title style={{ color: 'black' }}>Weather</Card.Title>

                                    <Card.Img src='/card_photos/weather-app.png' />
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>
                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'

                    }}>
                        <NavLink
                            to="/dashboards/reconstructions"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                <Card.Body style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                    <Card.Title style={{ width: '120%', color: 'black' }}>Reconstructions</Card.Title>
                                    <Card.Img src='/card_photos/3d-cube.png' />
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>

                </div>
            </div>
        </div>
    )
}


export default DashboardsMain;
