import {
    Map,
    TileLayer,
} from 'react-leaflet';
import PixiOverlay from '../../../Utilities/CustomPixiOverlay'
import {
    rigsCity
} from '../../../Utilities/rigs'

import { renderToString } from 'react-dom/server';
import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import * as moment from 'moment';
import './Reconstructions.css';
import {
    cityCoordinates,
    availableCities,
    availableStates
} from '../../../Utilities/utilities';
import {
    rigColor
} from '../../../Utilities/rigs'
import { currentDrivers } from '../../../Utilities/userprofiles'
import "rc-calendar/assets/index.css"
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
    registerLocale,
    setDefaultLocale
} from "react-datepicker";
import us from 'date-fns/locale/en-US';
import {
    Amplify,
    API,
    input
} from 'aws-amplify';
import awsmobile from '../../../aws-exports'
import * as AWS from 'aws-sdk/global';
import * as aws from 'aws-sdk';
Amplify.configure(awsmobile);
const Reconstructions = (
    {
        currentUser,
        AWS_ACCESS_KEY_ID,
        AWS_SECRET_KEY_ID
    }) => {

    // Misc Constants
    setDefaultLocale('us')
    registerLocale('us', us)
    const mapRef = useRef();
    const vidarProdBucket = 'ce-vidar-ingest-prod'

    // AWS credentials 
    AWS.config.credentials.refresh()
    AWS.config.update({
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_KEY_ID,
        region: 'us-west-2'
    });
    AWS.config.credentials = new AWS.TemporaryCredentials({
        RoleArn: process.env.REACT_APP_VIDAR_PROD_ROLE,
    })
    
    let s3 = new AWS.S3();

    //  Date converters, initial and continuous checks
    const utcToMonthDayYearConverter = (time) => moment.unix(time).format('YYYY-MM-DD');
    const utcToMonthDayYearAndTimeConverter = (time) => moment.unix(time).format('YYYY-MM-DD hh:mm:ss A');

    // Tile server 
    const TILESERVER_HOST = 'surveyor-tiles.s3-us-west-2.amazonaws.com';

    // Markers for PIXI
    let markers = []

    // Initial location and lat/long coordinates
    const [location, setLocation] = useState('San Francisco');

    // Current city checker
    const [nextCity, setNextCity] = useState('');

    // Current position of map's center
    const [position, setPosition] = useState({
        lat: 37.7749,
        lng: -122.4194
    });

    // Which reconstructions are loaded
    const [data, setData] = useState({
        reconstructionJson: null
    });

    // Rig filtering
    const [rigID, setRigId] = useState({
        id: null
    });
    const [currentRigID, setCurrentRigID] = useState({
        id: null
    });



    // Datepick
    const userSelectedDateState = new Date(Date.now())
    const startDatePlaceHolderState = new Date(Date.now())
    const [startDate, setStartDate] = useState(startDatePlaceHolderState.setDate(startDatePlaceHolderState.getDate() - 14));
    const [endDate, setEndDate] = useState(Date.now());


    // ------------------------ HELPER FUNCTIONS  ------------------------//
    const onDateChange = dates => {
        const [start, end] = dates;
        setStartDate(start)
        setEndDate(end)
        enumerateDaysBetweenDates(start, end)
    };

    const enumerateBetweenTwoDatesDaily = (start, end) => {
        let now = moment(start).clone(), dates = [];
        while (now.isSameOrBefore(end)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
        return dates
    }

    const [userSelectedDates, setUserSelectedDates] = useState(Array.from(enumerateBetweenTwoDatesDaily(userSelectedDateState.setDate(userSelectedDateState.getDate() - 14), Date.now())))
    const enumerateDaysBetweenDates = (startDate, endDate) => {
        if (startDate !== null && endDate !== null) {
            setUserSelectedDates(enumerateBetweenTwoDatesDaily(startDate, endDate))
        }
    }

    const generateAllReconstructionKeysBetweenDates = (starterDate, enderDate) => {
        let now = moment(starterDate).clone(), dates = [];
        while (now.isSameOrBefore(enderDate)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'week');
        }
        return dates;
    }

    // Process strings into YYYY-MM-DD
    const beginningOfRange = (dateArray) => userSelectedDates && userSelectedDates.filter(date => dateArray.indexOf(date) !== -1)[0]
    const endOfRange = (dateArray) => userSelectedDates && userSelectedDates.filter(date => dateArray.indexOf(date) !== -1)[userSelectedDates.length - 1]

    // Grabs first day of the week to match with weekly JSON file
    const firstDateOfRange = (momentObj) =>
        moment(momentObj).clone().day() !== 1
            ?
            moment(momentObj).clone().subtract(moment(momentObj).clone().subtract(1, 'days').day(), 'days').format('YYYY-MM-DD')
            :
            moment(momentObj).clone().format('YYYY-MM-DD')

    const lastDateOfRange = (momentObj) =>
        moment(momentObj).clone().day() !== 1
            ?
            moment(momentObj).clone().subtract(moment(momentObj).clone().subtract(1, 'days').day(), 'days').format('YYYY-MM-DD')
            :
            moment(momentObj).clone().format('YYYY-MM-DD')
    // Converts rig 
    const reconKeyDateExtractor = (jsonDate) => `20${jsonDate.split('/')[2].slice(0, 2)}-${jsonDate.split('/')[2].slice(2, 4)}-${jsonDate.split('/')[2].slice(4, 6)}`

    // Changes city in state
    const handleCityClick = (location) => {
        setPosition(cityCoordinates(location))
    }

    // Rig ID helper function
    async function handleRigChange(evt) {
        setCurrentRigID({
            id: (evt.target.selectedOptions.length !== 0) ? (Array.from(evt.target.selectedOptions, (item) => item.value)) : rigsCity[location].map(rig => rig)
        })
    }

    // Identifies the rigs by color
    function colorGenerator(rig) {
        if (rigID.id === null) return 'black'
        else return rigColor(rigID.id.indexOf(rig), rigID.id.length)
    }


    function weeklyPointConstructor(
        utc_sec,
        lat,
        lon,
        rig,
        quality
    ) {
        let weeklyPoint = {};
        weeklyPoint = {
            id: utc_sec,
            position: [lat, lon],
            tooltip: renderToString(<ul>
                <li><strong>rig: </strong> {rig}</li>
                <li><strong>lat: </strong> {lat}</li>
                <li><strong>long: </strong> {lon}</li>
                <li><strong>timestamp: </strong> {utcToMonthDayYearAndTimeConverter(utc_sec)}</li>
                <li><strong>quality: </strong>{(quality.hasOwnProperty('quality') && quality['quality'].hasOwnProperty('score')) ? quality['quality']['score'].toFixed(2) : parseInt(-1)}</li>
            </ul>),
            iconColor: colorGenerator(rig)
        }
        return weeklyPoint
    }

    function generateListOfAvailableCities() {
        let cities = [];
        availableCities.list.forEach(city => {
            cities.push(<option value={city}>{city}, {availableStates[city]}</option>)
        })
        return cities.map(city => city)
    }

    // Changes current city state
    function handleCityChange(evt) {
        console.log('Location set to: ' + evt.target.value)
        setNextCity(evt.target.value)
        setLocation(evt.target.value)
        handleCityClick(evt.target.value)
        setCurrentRigID({
            id: rigsCity[evt.target.value].map(rig => rig)
        })
    }

    function visibleRigsForCurrentUserAndLocation() {
        if (!currentRigID.id) {
            if (currentUser.includes('@compoundeye')) {
                return rigsCity[location]
            }
            else if (currentUser.includes('@compoundops')) {
                return currentDrivers[currentUser].rig
            }
        }
        else if (currentRigID.id && currentRigID.id.length > 0) {
            return currentRigID.id
        }
    }

    async function loadReconstructions() {
        try {
            const arrayOfDateRange = enumerateBetweenTwoDatesDaily(
                userSelectedDates[0],
                userSelectedDates[userSelectedDates.length - 1]
            )

            const allReconstructionKeys = generateAllReconstructionKeysBetweenDates(
                firstDateOfRange(beginningOfRange(arrayOfDateRange)),
                lastDateOfRange(endOfRange(arrayOfDateRange))
            )
            const promiseOfListObjects = visibleRigsForCurrentUserAndLocation().map(function (rig) {
                let dailySummaryParams = {
                    Bucket: vidarProdBucket,
                    Delimiter: '/',
                    Prefix: `${rig}/reconstruction_summaries/`
                }
                return s3.listObjects(dailySummaryParams).promise().then(file => file.Contents.flat(1))
            })
            let reconstructionKeys = [];
            await Promise.all(promiseOfListObjects).then(file => file.map(keys => {
                keys.map(rigSummaryKey => {
                    if (rigSummaryKey.Key.includes('.gz')) return
                    if (allReconstructionKeys.indexOf(reconKeyDateExtractor(rigSummaryKey.Key)) !== -1) {
                        reconstructionKeys.push(rigSummaryKey.Key)
                    }
                })
            }))
            const promisesOfS3Objects = reconstructionKeys.map(function (key) {
                return s3.getObject({
                    Bucket: vidarProdBucket,
                    Key: `${key}`,
                }).promise()
                    .then(file => file)
            })
            Promise.all(promisesOfS3Objects)
                .then(data => {
                    let mergedArray = []
                    data.map(data => {
                        mergedArray.push(JSON.parse(data.Body.toString()))
                    })
                    mergedArray = mergedArray.filter(emptyObj => Object.keys(emptyObj).length !== 0)
                    return mergedArray
                }).then(filteredArray => {
                    setData({
                        reconstructionJson: filteredArray.flat(1)
                    })
                })
        } catch (err) {
            console.log(err)
        }
    }
    // ------------------------ SIDE EFFECT FUNCTIONS  ------------------------//

    // Checks if date is within buffer of dates, if it isn't then loadReconstruction runss
    useEffect(() => {
        console.log(performance.now())
        if (startDate !== null && endDate !== null) loadReconstructions()
        console.log(performance.now())
    }, [currentUser, location, userSelectedDates, startDate, endDate])

    useEffect(() => {
        // Continuous update of which reconstructions should be loaded based off state change then updates array of available rigs
        async function getCurrentRigsAndLocation() {
            if (currentUser.includes('@compoundeye.com')) {
                if (data.reconstructionJson) {
                    setRigId({
                        id: rigsCity[location].map(rig => rig)
                    })
                    setCurrentRigID({
                        id: rigsCity[location].map(rig => rig)
                    })
                }
            } else if (currentUser.includes('@compoundops.com')) {
                if (data.reconstructionJson) {
                    setPosition(cityCoordinates(currentDrivers[currentUser].city))
                    setLocation(currentDrivers[currentUser].city)
                    setRigId({
                        id: currentDrivers[currentUser].rig.map(rig => rig)
                    })
                    setCurrentRigID({
                        id: currentDrivers[currentUser].rig.map(rig => rig)
                    })
                }
            }
        }
        getCurrentRigsAndLocation()
    }, [data.reconstructionJson])

    // Side effect used to recalculate size based off height and width. Informs that the container size has changed.
    // Also, sometimes the tiles don't load and this corrects the height and width.
    useEffect(() => {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;
        console.log(map)
        setTimeout(() => {
            map.invalidateSize()
        }, 1000)
    }, [mapRef])

    return (
        <div style={{
            margin: '0px auto',
            display: 'flex',
            flexDirection: 'row',
        }}>
            {/* Displays the current city */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                background: '#474747',
                paddingRight: '3vw',
            }}>
                <table style={{ marginLeft: '50px', marginTop: '2rem' }}>
                    <tbody>
                        <tr>
                            <DatePicker
                                locale='us'
                                dateFormat='YYYY-MM-DD'
                                onChange={(date) => onDateChange(date)}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </tr>
                        <tr>
                            <td >
                                <div className='row-spacer'>
                                    <h3 className='listbox-headers'> Cities</h3>
                                    <hr className='listbox-row-divider'></hr>
                                    <form>
                                        <td>
                                            <select onChange={handleCityChange} id="location" value={location}>
                                                {generateListOfAvailableCities()}
                                            </select>
                                        </td>
                                    </form>
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <td>
                                <div className='row-spacer'>
                                    <h3 className='listbox-headers'> Rigs</h3>
                                    <hr className='listbox-row-divider'></hr>
                                    <form >
                                        <select style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                            multiple={true} onChange={handleRigChange} id='rigID'>
                                            {rigID.id && rigID.id.map(rig => {
                                                return (
                                                    <option style={{ backgroundColor: colorGenerator(rig), color: 'grey' }} value={rig}>{rig}</option>
                                                )
                                            })}
                                        </select>
                                    </form>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                borderLeft: 'solid 3px black',
                background: '#474747'
            }}>
                {/* Map that gets displayed from surveyor's tileserver */}
                <Map
                    center={position}
                    zoom={14}
                    minZoom={9}
                    preferCanvas={true}
                    ref={mapRef}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url={`https://${TILESERVER_HOST}/public/tiles/{z}/{x}/{y}.png`}
                        noWrap={true}
                    />
                    {/* Conditional render of Vector layer based off all reconstruction summaries' rigs if any rigs were uploaded to state */}

                    {startDate !== null && endDate !== null && data.reconstructionJson && data.reconstructionJson.length > 0 ? data.reconstructionJson.map((d) => {
                        for (let key in d) {
                            rigID.id && d[key]['rig'] == (currentRigID.id && currentRigID.id.length > 0 ? currentRigID.id : rigID.id).filter(data => data == d[key]['rig']) && d[key]['gps'] && d[key]['gps'].map((points) => {
                                if (userSelectedDates && utcToMonthDayYearConverter(d[key]['start_time']) == userSelectedDates.filter((date) => date == utcToMonthDayYearConverter(d[key]['start_time']))) {
                                    markers.push(
                                        weeklyPointConstructor(
                                            points['utc_sec'],
                                            points['lat'],
                                            points['lon'],
                                            key.split('/')[0],
                                            d[key]
                                        )
                                    )
                                }
                            })
                        }
                    }) : null}
                    <PixiOverlay markers={markers} />
                </Map>
            </div>
        </div >
    );
}
export default Reconstructions;