import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import './PartForm.css'
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
API.configure(awsmobile)

const PartForm = () => {
    let navigate = useNavigate()
    const newAssetApiPath = '/assetform';
    const apiName = 'opsassetinventory';
    const path = '/assetidlist';
    const myInit = {
        response: true
    };
    const [formAttributes, setFormAttributes] = useState({
        AssetID: null,
        Location: null,
        Description: null,
        PartNumber: null,
        URL: null,
        CreatedAt: new Date().toUTCString()
    })
    const [customFormAttributes, setCustomFormAttributes] = useState(
        [{ customName: "", customValue: "" }]
    )
    const [optionalRowsToggle, setOptionalRowsToggle] = useState(false)
    const [customOptions, setCustomOptions] = useState([])
    const [tempRemovedOptions, setTempRemovedOptions] = useState([])
    const originalOptions = []

    const requiredFormRowTemplate = (
        label,
        name,
        placeholderinput,
        readOnlyBool = false
    ) =>
        <Row className="mb-3">
            <Form.Group as={Col} >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Form.Label style={{ width: '15%' }}>{label}</Form.Label>
                    <Form.Control
                        style={{ width: '50%' }}
                        name={name}
                        value={formAttributes[name]}
                        onChange={(evt) => handleChange(evt)}
                        placeholder={placeholderinput}
                        readOnly={readOnlyBool}
                    />
                </div>
            </Form.Group>
        </Row>

    const requiredFormRows = () => {
        let formInputs = Array.from(Object.keys(formAttributes))
        return formInputs.map(k => {
            if (k === 'AssetID') {
                return null
            }
            else if (k === 'CreatedAt') {
                return requiredFormRowTemplate(
                    k,
                    k,
                    new Date().toUTCString(),
                    true
                )
            }
            else if (k === 'URL') {
                return requiredFormRowTemplate(
                    k,
                    k,
                    `https://www.compoundops.com/parts/${formAttributes['AssetID']}`,
                    true
                )
            }
            else {
                return requiredFormRowTemplate(k, k, null)
            }
        })
    }

    const optionalFormRows = () => {
        return (customFormAttributes && optionalRowsToggle) ? customFormAttributes.map((k, i) =>
            <Row className="mb-3">
                <Form.Group as={Col} >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Form.Label style={{ width: '15%' }}>Field</Form.Label>
                        <Form.Control
                            style={{
                                width: '25%',
                                overflowWrap: 'anywhere',
                            }}
                            name='customName'
                            value={k['customName']}
                            onChange={handleOptionalChange(i)}
                        >
                        </Form.Control>
                        <Form.Label style={{ width: '15%' }}>Value</Form.Label>
                        <Form.Control
                            style={{ width: '50%' }}
                            name='customValue'
                            value={k['customValue']}
                            onChange={handleOptionalChange(i)}
                        />
                        <input
                            className='input-buttons-add'
                            type='button'
                            value='add input'
                            onClick={() => addRowButton(i)}
                        />
                        {(i === 0) ?
                            <input
                                className='input-buttons-remove'
                                type='button'
                                value='remove all'
                                onClick={removeRowButton(i)}
                            />
                            :
                            <input
                                className='input-buttons-remove'
                                type='button'
                                value='remove'
                                onClick={removeRowButton(i)}
                            />
                        }
                    </div>
                </Form.Group>
            </Row>
        )
            :
            <input
                className='input-buttons-add'
                type='button'
                value='add inputs'
                onClick={() => setOptionalRowsToggle(true)}
            />
    }

    const handleOptionalChange = i => e => {
        let { name, value } = e.target;
        let tempCustomFormAttributes = [...customFormAttributes];
        tempCustomFormAttributes[i] = { ...tempCustomFormAttributes[i], [name]: value };
        setCustomFormAttributes(tempCustomFormAttributes)
    }

    const removeRowButton = (i) => e => {
        if (i === 0) {
            setCustomFormAttributes([{ customName: "", customValue: "" }])
            setOptionalRowsToggle(false)
            setCustomOptions(originalOptions)
            setTempRemovedOptions([])
        }
        else if (i > 0) {
            let tempCustomFormAttributes = [...customFormAttributes]
            tempCustomFormAttributes.splice(i, 1)
            setCustomFormAttributes(tempCustomFormAttributes)
            if (customFormAttributes[i]['customName'] === "") {
                setCustomOptions([
                    ...customOptions
                ])
            }
            else if (customFormAttributes[i]['customName'] !== "") {
                setCustomOptions([
                    ...customOptions,
                    tempRemovedOptions[tempRemovedOptions.indexOf(customFormAttributes[i]['customName'])]
                ])
                tempRemovedOptions.splice(tempRemovedOptions.indexOf(customFormAttributes[i]['customName']), 1)
                setTempRemovedOptions(tempRemovedOptions)

            }
        }
    }

    const addRowButton = (i) => {
        let tempCustomFormAttributes = [...customFormAttributes]
        tempCustomFormAttributes[i + 1] = { customName: "", customValue: "" }
        if (tempCustomFormAttributes[i].customName !== "" && tempCustomFormAttributes[i].customValue !== "")
            setCustomFormAttributes(tempCustomFormAttributes)
    }

    const handleChange = async (evt) => {
        let name = evt.target.name
        setFormAttributes({
            ...formAttributes,
            [name]: evt.target.value
        })
    }

    async function handleFormSubmit(evt) {
        evt.preventDefault();
        const newAttributes = {};
        for (const k of customFormAttributes) {
            if (k["customName"] && k["customValue"]) {
                newAttributes[k["customName"]] = k["customValue"];
            }
        }
        const body = Object.assign({}, formAttributes, newAttributes)
        API.post(apiName, newAssetApiPath, { body })
            .then(res => { navigate('/parts/directory') })
    }

    useEffect(() => {
        API.get(apiName, path, myInit)
            .then(res => {
                let assetIDPlusOne = 'A' + '-' + (parseInt(res['data'].sort()[res['data'].length - 1].split('-')[1]) + 1)
                setFormAttributes((prevAttributes) => ({
                    ...prevAttributes,
                    AssetID: assetIDPlusOne.toString(),
                    URL: `https://www.compoundops.com/parts/${assetIDPlusOne.toString()}`,
                }))
            })
    }, [])

    return (
        <div style={{
            margin: `3% auto`,
            width: '50%',
            backgroundColor: `rgb(71, 71, 71)`,
            padding: '5%',
            color: 'white',
            boxShadow: ` 0px 8px 16px 0px rgb(0 0 0 / 80%)`
        }}>
            <h1 style={{ textShadow: `1px 1px white` }}>Add an Asset {formAttributes['AssetID']}</h1>
            <h2 style={{ textShadow: `1px 1px white`, textAlign: 'left' }}>Required Info</h2>
            <Form onSubmit={handleFormSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <Form.Label style={{ width: '15%' }}>Asset ID (generated)</Form.Label>
                            <Form.Control
                                style={{ width: '50%' }}
                                name='AssetID'
                                value={formAttributes['AssetID']}
                                placeholder={formAttributes['AssetID']}
                                readOnly={true}
                            />
                        </div>
                    </Form.Group>
                </Row>
                {requiredFormRows()}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        margin: '2%'
                    }}>
                        <h2 style={{ textShadow: `1px 1px white`, textAlign: 'left' }}>Optional Info </h2>
                        {optionalFormRows()}
                    </div>
                    <div>
                        <Button style={{
                            backgroundColor: 'rgb(69, 173, 168)',
                            color: 'white',
                            border: 'none',
                            height: '21%',
                            width: '20%'
                        }} variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </div >
            </Form >
        </div >
    )
}

export default PartForm;