import React, { Component } from "react";
import { number } from 'prop-types';

class CloudyNight extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 509.984 509.984"
                version="1.1"
                viewBox="0 0 509.984 509.984"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <path
                    fill="#B0BEC5"
                    d="M443.157 362.321a16 16 0 00-17.824-4.96 169.25 169.25 0 01-57.312 9.632c-97.202-.102-175.917-78.983-175.815-176.185.061-58.167 28.857-112.547 76.935-145.287 7.316-4.955 9.23-14.904 4.275-22.22a16.002 16.002 0 00-10.995-6.868 155.239 155.239 0 00-22.4-1.44C107.473 14.982.012 122.424 0 254.972s107.431 240.009 239.979 240.021c83.333.007 160.705-43.213 204.394-114.176a16 16 0 00-1.216-18.496z"
                ></path>
                <path
                    fill="#E3F2FD"
                    d="M427.893 174.993c-17.78-68.42-87.66-109.471-156.079-91.691a128.004 128.004 0 00-78.545 59.691c-53.019-.345-96.279 42.357-96.624 95.376s42.357 96.279 95.376 96.624h240c44.183-1.14 79.076-37.881 77.936-82.064s-37.881-79.076-82.064-77.936z"
                ></path>
                <path d="M240.021 494.993c-132.548 0-240-107.452-240-240s107.452-240 240-240c7.492-.061 14.978.42 22.4 1.44 8.747 1.252 14.824 9.357 13.572 18.105a16.002 16.002 0 01-6.916 11.015 175.358 175.358 0 00-43.904 42.784c-5.161 7.175-15.161 8.809-22.336 3.648-7.175-5.161-8.809-15.161-3.648-22.336a219.173 219.173 0 0117.6-21.376C102.64 61.167 20.555 164.155 33.449 278.305S149.33 474.538 263.48 461.645a208 208 0 00127.933-63.931 212.96 212.96 0 01-106.624-16c-7.986-3.783-11.392-13.324-7.609-21.31 3.62-7.641 12.56-11.146 20.409-8.002a178.53 178.53 0 00127.68 5.056c8.276-3.096 17.496 1.103 20.592 9.38a16 16 0 01-1.392 14.044c-43.468 71.211-121.019 114.496-204.448 114.111z"></path>
                <path d="M432.021 334.993h-240c-53.019-.336-95.728-43.589-95.392-96.608.336-53.019 43.589-95.728 96.608-95.392 35.432-61.172 113.746-82.037 174.917-46.605a128.001 128.001 0 0159.739 78.605c44.183-1.14 80.924 33.753 82.064 77.936s-33.753 80.924-77.936 82.064zm-240-160c-35.346 0-64 28.654-64 64s28.654 64 64 64h240c26.51 0 48-21.49 48-48s-21.49-48-48-48a44.804 44.804 0 00-12.48 1.6 15.999 15.999 0 01-13.312-2.016 15.999 15.999 0 01-7.04-11.424c-6.629-52.603-54.646-89.873-107.249-83.244a96.001 96.001 0 00-74.895 54.444 16 16 0 01-16 9.12c-3.008-.192-5.824-.48-9.024-.48z"></path>
            </svg>
        );
    }
}

CloudyNight.propTypes = {
    size: number
}
CloudyNight.defaultProps = {
    size: 500
}
export default CloudyNight;
