import React from "react";
import { number } from 'prop-types';

const RigCommentsIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        fill="none"
        viewBox="0 0 48 48"
    >
        <path fill="#fff" fillOpacity="0.01" d="M0 0H48V48H0z"></path>
        <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M33 38H22v-8h14v-8h8v16h-5l-3 3-3-3z"
        ></path>
        <path
            fill="#2F88FF"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M4 6h32v24H17l-4 4-4-4H4V6z"
        ></path>
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="4"
            d="M12 22h6M12 14h12"
        ></path>
    </svg>
);

RigCommentsIcon.propTypes = {
    size: number
}
RigCommentsIcon.defaultProps = {
    size: '30%'
}

export default RigCommentsIcon;
