import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { rigs } from '../../Utilities/rigs';
import DateTimePicker from 'react-datetime-picker';
import { availableCities, availableStates } from '../../Utilities/utilities'
import './RigCommentForm.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
API.configure(awsmobile)

const RigCommentForm = ({ userName }) => {
    let navigate = useNavigate()
    const apiName = 'opsrigcomments'
    const path = '/rigcomments'

    const categoryIssues = [
        "hardware issues",
        "software issues",
        "staffing issues",
        "automobile issues"
    ]

    const weatherCategories = ["Sunny/Clear",
        "Partially cloudy",
        "Cloudy",
        "Overcast",
        "Rain",
        "Drizzle",
        "Snow",
        "Stormy",
        "Tornadoes",
        "Thundersnows",
        "Fog",
        "Hurricanes",
        "Sandstorms",
        "Seasons",
        "Tracker"
    ]
    
    const [formAttributes, setFormAttributes] = useState({
        timestamp_modified_utc: new Date().toUTCString(),
        rig_id: null,
        category: null,
        city: null,
        state: null,
        weather: null,
        temperature_f: null,
        time_of_error: new Date(),
        description: null,
        submitted_by: userName.toString(),
        timestamp_created_utc: new Date().toUTCString()
    })

    const dropdownMenu = (opts) =>
        opts.map((opt) => {
            return <option key={`${opt}`} value={opt}>{opt}</option>
        })

    async function handleChange(evt) {
        let name = evt.target.name
        setFormAttributes({
            ...formAttributes,
            [name]: evt.target.value
        })
    }

    async function handleDateTimeChange(evt) {
        return setFormAttributes({
            ...formAttributes,
            time_of_error: evt
        })
    }

    async function handleFormSubmit(evt) {
        evt.preventDefault();
        const body = { ...formAttributes }
        API.post(apiName,path, { body })
        .then(res => console.log(body))
        .then(res => { navigate('/drivers/rigcomments') })
    }

    return (
        <div style={{
            margin: `3% auto`,
            width: '50%',
            backgroundColor: `rgb(71, 71, 71)`,
            padding: '5%',
            color: 'white',
            boxShadow: ` 0px 8px 16px 0px rgb(0 0 0 / 80%)`
        }}>
            <h1 style={{ textShadow: `1px 1px white` }}>Rig Comment Form</h1>
            <Form onSubmit={handleFormSubmit} >
                <h1 className='form-header'>Comment Details</h1>
                <Col>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Rig ID</Form.Label>
                            <Form.Control as="select" name='rig_id' onChange={(evt) => handleChange(evt)}>
                                <option key='blankChoice' hidden value> Please select a rig</option>
                                {dropdownMenu(rigs)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>City of Rig </Form.Label>
                            <Form.Control as="select" name='city' onChange={(evt) => handleChange(evt)}>
                                <option key='blankChoice' hidden value> Please select a city</option>
                                {dropdownMenu(availableCities.list)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>State of Rig </Form.Label>
                            <Form.Control as="select" name='state' onChange={(evt) => handleChange(evt)}>
                                <option key='blankChoice' hidden value> Please select a state</option>
                                {dropdownMenu(Array.from(new Set(Object.values(availableStates))))}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} >
                            <Form.Label>Category</Form.Label>
                            <Form.Control as="select" name='category' onChange={(evt) => handleChange(evt)}>
                                <option key='blankChoice' hidden value> Please select an issue category</option>
                                {dropdownMenu(categoryIssues)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>Weather </Form.Label>
                            <Form.Control as="select" name='weather' onChange={(evt) => handleChange(evt)}>
                                <option key='blankChoice' hidden value> Please select a weather category</option>
                                {dropdownMenu(weatherCategories)}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                </Col>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Time of Error (Pacific Time)</Form.Label>
                        <Form.Control as={DateTimePicker} value={formAttributes.time_of_error} name={'time_of_error'} onChange={(evt) => handleDateTimeChange(evt)} />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label>Temperature (Farenheit) </Form.Label>
                        <Form.Control name='temperature_f' onChange={(evt) => handleChange(evt)} placeholder='Please enter outside temperature of rig e.g. 87' />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} >
                        <Form.Label>Description</Form.Label>
                        <Form.Control style={{ height: '100px' }} as="textarea" name='description' onChange={(evt) => handleChange(evt)} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Submitted By</Form.Label>
                        <Form.Control name='submitted_by' value={userName} disabled />
                    </Form.Group>
                </Row>
                <Button style={{ backgroundColor: 'rgb(69, 173, 168)', color: 'white', border: 'none' }} variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div >
    )
}

export default RigCommentForm;
