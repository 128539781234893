
import axios from 'axios';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'

const twoWeeksBeforeCurrentDate = () => {
    let currentDate = new Date()
    return new Date(currentDate.setDate(currentDate.getDate() - 14))
}

const fetchEmbedUrl = async (api, STS_PARAMS, headers) => {
    try {
        const result = await axios.post(api, STS_PARAMS, { headers }).then(data => {
            console.log(data.data)
            return data.data.toString()
        })
        return result
    } catch (error) {
        console.log("Error ", error)
    }
};

const getQuickSightDashboardEmbedURL = async (url, startDate, endDate) => {
    const containerDiv = document.getElementById("dashboardContainer");
    const options = {
        url: await url,
        container: containerDiv,
        parameters: {
            StartDate: startDate,
            EndDate: endDate
        },
        scrolling: "no",
        height: 'AutoFit',
        footerPaddingEnabled: true,
    };
    const dashboard = await QuickSightEmbedding.embedDashboard(options);
};


export { fetchEmbedUrl, getQuickSightDashboardEmbedURL, twoWeeksBeforeCurrentDate };
