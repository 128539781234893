import React, { Component } from "react";
import { number } from 'prop-types';

class Thunder extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 481.12 481.12"
                version="1.1"
                viewBox="0 0 481.12 481.12"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <path
                    fill="#77B9C8"
                    d="M427.2 162.4h-42.744c.744-8 1.24-11.184 1.256-15.976.128-42.768-28.424-81.376-70.192-90.584-23.808-5.248-46.536-1.984-65.336 8.16-22.936-34.144-60.952-57.48-104.512-60.144-5.608-.768-11.28-1.384-17.096-1.384C57.568 2.472 0 61.8 0 135.032c0 38.16 15.72 72.432 40.744 96.624 22.12 24.544 52.84 40.384 89.216 43.024 0 0 296.12 1.464 297.24 1.464 29.776 0 53.92-24.8 53.92-56.872S456.976 162.4 427.2 162.4z"
                ></path>
                <g fill="#92C7D3">
                    <path d="M34.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496c2.208-3.832 7.112-5.144 10.928-2.928a7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4zM82.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496c2.208-3.832 7.112-5.144 10.928-2.928a7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4zM130.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496a7.99 7.99 0 0110.928-2.928 7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4zM178.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496c2.208-3.832 7.112-5.144 10.928-2.928a7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4zM370.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496c2.208-3.832 7.112-5.144 10.928-2.928a7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4zM322.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496c2.216-3.832 7.104-5.144 10.928-2.928a7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4zM418.552 478.648a7.936 7.936 0 01-3.992-1.072 7.99 7.99 0 01-2.928-10.928l28-48.496a7.99 7.99 0 0110.928-2.928 7.99 7.99 0 012.928 10.928l-28 48.496a7.987 7.987 0 01-6.936 4z"></path>
                </g>
                <g fill="#FDC567">
                    <path d="M88.272 369.2L113.768 298.992 68.904 273.088 171.472 159.464 144.104 259.04 201.624 292.248z"></path>
                    <path d="M260.616 452.152L286.112 381.944 241.248 356.04 343.816 242.416 316.448 341.984 373.968 375.192z"></path>
                </g>
            </svg>
        );
    }
}

Thunder.propTypes = {
    size: number
}
Thunder.defaultProps = {
    size: 500
}
export default Thunder;
