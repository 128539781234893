const domainCheck = (email, domain) => email.toString().includes(domain)


const compoundEyesOnly = (email, page, unauth) =>
    (email !== null) && domainCheck(email, 'compoundeye.com')
        ? page
        : unauth

const driverAccess = (email, page, unauth) =>
    (email !== null) && (domainCheck(email, 'compoundeye.com') || domainCheck(email, 'compoundops.com'))
        ? page
        : unauth

const cfAccess = (email, page, unauth) =>
    (email !== null) && (domainCheck(email, 'compoundeye.com') || domainCheck(email, 'cloudfactory.com'))
        ? page
        : unauth



module.exports = {
    domainCheck,
    compoundEyesOnly,
    driverAccess,
    cfAccess
}