import React, { useState, useEffect } from 'react';
import {fetchEmbedUrl, getQuickSightDashboardEmbedURL, twoWeeksBeforeCurrentDate} from '../../Utilities/quicksightHelpers'

const DriverMetrics = ({
    STS_PARAMS }) => {
    console.log('STS Params: ' + STS_PARAMS)
    const api = 'https://8ju2v5rp1d.execute-api.us-west-2.amazonaws.com/prod'
    const headers = {
        'Content-Type': 'application/json',
    }
    
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getQuickSightDashboardEmbedURL(
            fetchEmbedUrl(
                api,
                STS_PARAMS,
                headers
            ),
            twoWeeksBeforeCurrentDate(),
            new Date()
        )
        setLoading(false)
    }, [STS_PARAMS])

    return (
        <>
            { loading && (
                <h1> Loading . . .</h1>
            )}
            <div id="dashboardContainer"></div>
        </>
    )
}

export default DriverMetrics;