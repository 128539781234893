import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import './NavBar.css'
import Home from './Icons/Home'
import Docs from './Icons/Docs'
import Safety from './Icons/Safety'
import Dashboards from './Icons/Dashboards'
import DownArrow from './Icons/DownArrow'
import RightArrow from './Icons/RightArrow'
import Drivers from './Icons/Drivers'
import DataCollectionIcon from './Icons/DataCollectionIcon'
import PartsMainIcon from './Icons/PartsMainIcon'
import {
    domainCheck,
    driverAccess,
    compoundEyesOnly,
    cfAccess
} from '../Utilities/RouteAccess';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'

const NavBar = (props) => {
    const location = useLocation();
    const dontToggleArray = [
        '/datacollection',
        '/documentation',
        '/safety',
        '/drivers',
        '/dashboards',
        '/parts'
    ]
    useEffect(() => {
        if (dontToggleArray.indexOf(location.pathname) === -1) props.toggleMenu()
    }, [location])

    function replaceEmail() {
        let ret = props.username.toString().split('@')
        return ret[0]
    }
    return (
        <div className="navBar" >
            <nav>
                <div style={{
                    color: 'white',
                    background: '#45ADA8',
                }}>
                    <h3 style={{
                        marginLeft: '2rem'
                    }}>
                        {replaceEmail()}
                    </h3>
                </div>
                <Accordion>
                    <Card>
                        <NavLink
                            to="/"
                            exact={true}
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <Home size={25} />
                                    <span className='navText'> home </span>
                                </Accordion.Toggle>
                            </Card.Header>
                        </NavLink>
                    </Card>
                    {compoundEyesOnly(props.username,
                        <Card>
                            <NavLink
                                to="/datacollection"
                                className='navlink'
                                activeClassName='navlink-active'>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <DataCollectionIcon size={25} />
                                        <span className='navText'> data collection</span>
                                    </Accordion.Toggle>
                                </Card.Header>
                            </NavLink>
                            <Accordion.Collapse
                                className='toggle-on-navbar'
                                eventKey="1">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <NavLink
                                        to="/datacollection/driveronboarding"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Driver Onboarding
                                    </NavLink>
                                    <NavLink
                                        to="/datacollection/fleetsupport"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Fleet Support
                                    </NavLink>
                                    <NavLink
                                        to="/datacollection/hiringguide"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Hiring Guide
                                    </NavLink>
                                    <NavLink
                                        to="/datacollection/vehiclerequirementsguide"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Vehicle Requirements Guide
                                    </NavLink>
                                    <NavLink
                                        to="/datacollection/drivertraining"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Driver Training
                                    </NavLink>
                                </div>
                            </Accordion.Collapse>
                        </Card>,
                        null
                    )}
                    {driverAccess(
                        props.username,
                        <Card>
                            <NavLink
                                to="/documentation"
                                className='navlink'
                                activeClassName='navlink-active'>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        <Docs size={25} />
                                        <span className='navText'> documents</span>
                                    </Accordion.Toggle>
                                </Card.Header>
                            </NavLink>
                            <Accordion.Collapse
                                className='toggle-on-navbar'
                                eventKey="2">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    {/* TODO add incident report */}
                                    <NavLink
                                        to="/documentation/appwalkthrough"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        App Walkthrough
                                    </NavLink>
                                    <NavLink
                                        to="/documentation/driverroutingguide"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Driver Routing Guide
                                    </NavLink>
                                    <NavLink
                                        to="/documentation/employeehandbook"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Employee Handbook
                                    </NavLink>
                                    <NavLink
                                        to="/drivers/shiftchecklist"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Shift Checklist
                                    </NavLink>
                                </div>
                            </Accordion.Collapse>
                        </Card>,
                        null
                    )}
                    {driverAccess(
                        props.username,
                        <Card>
                            <NavLink
                                to="/safety"
                                className='navlink'
                                activeClassName='navlink-active' >
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <Safety size={25} />
                                        <span className='navText'> safety </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                            </NavLink>
                            <Accordion.Collapse
                                className='toggle-on-navbar'
                                eventKey="4">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <NavLink
                                        to="/safety/covid19procedures"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        Covid-19 Procedures
                                    </NavLink>

                                </div>
                            </Accordion.Collapse>
                        </Card>
                        ,
                        null
                    )}
                    <Card >
                        <NavLink
                            to="/drivers"
                            exact={true}
                            className='navlink'
                            activeClassName='navlink-active'
                        >
                            <Card.Header>
                                <Accordion.Toggle style={{
                                    textDecoration: 'none',
                                    background: 'transparent',
                                    textAlign: 'left',
                                }}
                                    as={Button}
                                    variant="link"
                                    eventKey="5"
                                >
                                    <Drivers size={20} />
                                    <span className='navText' > drivers</span>
                                    <DownArrow size={10} />
                                </Accordion.Toggle>
                            </Card.Header>
                        </NavLink>
                        <Accordion.Collapse className='toggle-on-navbar' eventKey="5">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/drivers/form"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        add a driver
                                    </NavLink>,
                                    null
                                )}
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/drivers/driverdirectory"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        driver directory
                                    </NavLink>,
                                    null
                                )}
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/drivers/rigcommentform"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        add a comment
                                    </NavLink>,
                                    null
                                )}
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/drivers/rigcomments"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        rig comments 
                                    </NavLink>,
                                    null
                                )}
                            </div>
                        </Accordion.Collapse>
                    </Card>

                    <Card >
                        <NavLink
                            to="/dashboards"
                            exact={true}
                            className='navlink'
                            activeClassName='navlink-active'
                        >
                            <Card.Header>
                                <Accordion.Toggle style={{
                                    textDecoration: 'none',
                                    background: 'transparent',
                                    textAlign: 'left',
                                }}
                                    as={Button}
                                    variant="link"
                                    eventKey="6"
                                >
                                    <Dashboards size={20} />
                                    <span className='navText' > dashboards</span>
                                    <DownArrow size={10} />
                                </Accordion.Toggle>
                            </Card.Header>
                        </NavLink>
                        <Accordion.Collapse className='toggle-on-navbar' eventKey="6">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {driverAccess(
                                    props.username,
                                    <>
                                        <NavLink
                                            to="/dashboards/weather"
                                            className='navlink'
                                            activeClassName='navlink-active'>
                                            weather
                                        </NavLink>
                                        <NavLink
                                            to="/dashboards/reconstructions"
                                            className='navlink'
                                            activeClassName='navlink-active'>
                                            reconstructions
                                        </NavLink>
                                    </>,
                                    null
                                )}
                                {cfAccess(
                                    props.username,
                                    <NavLink
                                        to="/dashboards/annotationmetrics"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        annotation metrics
                                    </NavLink>,
                                    null
                                )}
                                {cfAccess(
                                    props.username,
                                    <NavLink
                                        to="/dashboards/cuboidmetrics"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        cuboid metrics
                                    </NavLink>,
                                    null
                                )}
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/dashboards/annotationcosts"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        annotation costs
                                    </NavLink>,
                                    null
                                )}
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/dashboards/rigmetrics"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        rig metrics
                                    </NavLink>,
                                    null
                                )}

                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/dashboards/telemetry"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        telemetry
                                    </NavLink>,
                                    null
                                )}
                            </div>
                        </Accordion.Collapse>
                        
                    </Card>
                    <Card >
                        <NavLink
                            to="/parts"
                            exact={true}
                            className='navlink'
                            activeClassName='navlink-active'
                        >
                            <Card.Header>
                                <Accordion.Toggle style={{
                                    textDecoration: 'none',
                                    background: 'transparent',
                                    textAlign: 'left',
                                }}
                                    as={Button}
                                    variant="link"
                                    eventKey="7"
                                >
                                    <PartsMainIcon size={20} />
                                    <span className='navText' > parts</span>
                                    <DownArrow size={10} />
                                </Accordion.Toggle>
                            </Card.Header>
                        </NavLink>
                        <Accordion.Collapse className='toggle-on-navbar' eventKey="7">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/parts/partform"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        new part
                                    </NavLink>,
                                    null
                                )}
                                {compoundEyesOnly(
                                    props.username,
                                    <NavLink
                                        to="/parts/directory"
                                        className='navlink'
                                        activeClassName='navlink-active'>
                                        all parts
                                    </NavLink>,
                                    null
                                )}
                            </div>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </nav>
        </div>
    )
}

export default NavBar;