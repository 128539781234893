import React, { Component } from "react";
import { number } from 'prop-types';

class ClearNight extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <path
                    fill="#959CB3"
                    d="M502.198 284.74c-48.855 71.105-141.801 102.013-225.666 69.348-97.852-38.114-146.28-148.337-108.166-246.189 17.222-44.214 49.167-78.334 88.039-98.942-98.63.216-191.668 59.918-229.628 157.377-49.5 127.084 13.395 270.233 140.478 319.733 127.084 49.5 270.233-13.395 319.732-140.478 7.801-20.028 12.804-40.453 15.211-60.849z"
                ></path>
                <g fill="#7C8293">
                    <path d="M129.994 69.527c-31.729 81.459-3.475 171.483 63.331 221.351-37.979-50.827-49.684-119.502-24.96-182.979 17.221-44.214 49.167-78.334 88.039-98.942-37.849.083-74.87 8.935-108.275 25.31a190.955 190.955 0 00-18.135 35.26zM428.137 348.172c-61.528 99.02-186.687 143.368-299.254 99.523-23.953-9.329-45.622-21.989-64.691-37.238 26.22 32.765 61.147 59.284 103.063 75.61 127.084 49.5 270.233-13.395 319.732-140.478 7.8-20.027 12.804-40.452 15.211-60.848-19.039 27.71-44.776 49.314-74.061 63.431z"></path>
                </g>
                <g fill="#AEB7CE">
                    <circle cx="113.679" cy="212.32" r="28.363"></circle>
                    <circle cx="312.999" cy="408.89" r="19.883"></circle>
                    <circle cx="301.059" cy="460.24" r="14.06"></circle>
                    <circle cx="227.669" cy="438.03" r="42.1"></circle>
                    <circle cx="105.019" cy="338.9" r="19.705"></circle>
                    <circle cx="53.069" cy="203.66" r="11.717"></circle>
                </g>
                <path d="M505.352 276.357a8.953 8.953 0 00-10.536 3.311c-15.286 22.247-35.82 41.114-59.382 54.559-23.306 13.299-49.717 21.394-76.378 23.408a8.956 8.956 0 00-8.256 9.606c.373 4.931 4.668 8.637 9.606 8.256 29.291-2.214 58.305-11.104 83.906-25.712 15.569-8.884 29.936-19.923 42.572-32.633a240.61 240.61 0 01-8.243 25.185c-23.072 59.234-67.83 105.937-126.029 131.508-58.2 25.57-122.873 26.945-182.107 3.874-59.234-23.072-105.937-67.83-131.508-126.029-25.57-58.199-26.946-122.872-3.874-182.106a240.658 240.658 0 0116.119-33.485 8.957 8.957 0 00-3.243-12.244c-4.277-2.488-9.759-1.034-12.244 3.243a258.557 258.557 0 00-17.322 35.985c-24.81 63.692-23.33 133.233 4.166 195.814 27.495 62.58 77.714 110.706 141.407 135.514C194.132 506.148 225.568 512 256.958 512c34.97 0 69.882-7.265 102.861-21.754 62.58-27.495 110.706-77.714 135.514-141.406 7.92-20.332 13.223-41.545 15.761-63.05a8.96 8.96 0 00-5.742-9.433z"></path>
                <path d="M56.41 111.382a8.956 8.956 0 0012.569-1.576c22.048-28.369 50.861-51.752 83.324-67.621 22.054-10.781 45.822-18.113 70.068-21.709a199.156 199.156 0 00-62.35 84.172c-39.846 102.297 10.963 217.94 113.26 257.785 17.859 6.957 36.513 11.302 55.445 12.915a8.958 8.958 0 001.521-17.848c-17.224-1.468-34.203-5.424-50.464-11.758-93.093-36.261-139.33-141.498-103.071-234.592 15.766-40.476 45.558-73.959 83.888-94.281A8.956 8.956 0 00256.405 0h-.02c-38.492.084-77.204 9.107-111.949 26.091C109.532 43.153 78.549 68.3 54.834 98.814a8.956 8.956 0 001.576 12.568z"></path>
            </svg>
        );
    }
}

ClearNight.propTypes = {
    size: number
}
ClearNight.defaultProps = {
    size: 500
}
export default ClearNight;
