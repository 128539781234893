// Weather images and styling components
import React, { useState, useEffect } from 'react';
import ClearDay from './Components/WeatherImages/ClearDay'
import CloudyDay from './Components/WeatherImages/CloudyDay';
import CloudyNight from './Components/WeatherImages/CloudyNight';
import ClearNight from './Components/WeatherImages/ClearNight';
import Rain from './Components/WeatherImages/Rain';
import Sleet from './Components/WeatherImages/Sleet';
import Windy from './Components/WeatherImages/Windy';
import Snow from './Components/WeatherImages/Snow';
import PartlyCloudy from './Components/WeatherImages/PartlyCloudy'
import Hail from './Components/WeatherImages/Hail'
import Fog from './Components/WeatherImages/Fog'
import Thunder from './Components/WeatherImages/Thunder'
import Sort from './Components/WeatherImages/Sort'

// Weather components
import Current from './TimeFrames/Current'
import Daily from './TimeFrames/Daily'
import Hourly from './TimeFrames/Hourly'

// Utilies
import { cityCoordinates, availableCities, availableStates } from '../../Utilities/utilities'
// Amplify 
import Amplify, { API, Auth } from 'aws-amplify';
import awsmobile from '../../aws-exports';
API.configure(awsmobile)



const Weather = () => {
    // Initial GET request
    const [location, setLocation] = useState('San Francisco')
    const [lat, setLat] = useState(37.754736)
    const [lng, setLng] = useState(-122.432741)

    const apiName = 'opsweatherapi';
    const path = '/weather';
    const myInit = {
        headers: {},
        response: true,
        queryStringParameters: {
            lat,
            lng
        },
    };

    // City weather data
    const [currentWeather, setCurrentWeather] = useState({})
    const [dailyWeather, setDailyWeather] = useState([])
    const [hourlyWeather, setHourlyWeather] = useState([])
    const [nextCity, setNextCity] = useState('')

    //Helpers
    const currentCityCheck = (city) => {
        city = location
        if (city !== nextCity)
            return city
    }

    const dailyWeatherImage = (desc) => {
        let icon = {
            'partly-cloudy-day': <PartlyCloudy size={50} />,
            'cloudy': <CloudyDay size={50} />,
            'clear-day': <ClearDay size={50} />,
            'partly-cloudy-night': <CloudyNight size={50} />,
            'clear-night': <ClearNight size={50} />,
            'rain': <Rain size={50} />,
            'sleet': <Sleet size={50} />,
            'wind': <Windy size={50} />,
            'snow': <Snow size={50} />,
            'hail': <Hail size={50} />,
            'fog': <Fog size={50} />,
            'thunder': <Thunder size={50} />
        }
        for (let key in icon) {
            if (key === desc) {
                return icon[desc]
            }
        }
    }

    const hourlyWeatherImage = (desc) => {
        let icon = {
            'partly-cloudy-day': <PartlyCloudy size={25} />,
            'cloudy': <CloudyDay size={25} />,
            'clear-day': <ClearDay size={25} />,
            'partly-cloudy-night': <CloudyNight size={25} />,
            'clear-night': <ClearNight size={25} />,
            'rain': <Rain size={25} />,
            'sleet': <Sleet size={25} />,
            'wind': <Windy size={25} />,
            'snow': <Snow size={25} />,
            'hail': <Hail size={25} />
        }
        for (let key in icon) {
            if (key === desc) {
                return icon[desc]
            }
        }
    }

    const weatherSummary = (desc) => {
        let icon = {
            'partly-cloudy-day': 'Partly Cloudy',
            'cloudy': 'Cloudy',
            'clear-day': 'Clear',
            'partly-cloudy-night': "Partly Cloudy",
            'clear-night': 'Clear',
            'rain': 'Rain',
            'sleet': 'Sleet',
            'wind': 'Windy',
            'snow': 'Snow',
            'hail': 'Hail',
            'fog': 'Foggy',
            'thunder': 'Stormy'
        }
        for (let key in icon) {
            if (key === desc) {
                return icon[desc]
            }
        }
    }

    //Date info
    const timeFinder = (time) => {
        let day = new Date(time)
        return day
    }
    const daysOfWeek = (day) => {
        let week = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']
        return week[day]
    }
    const month = (mon) => {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return months[mon]
    }
    const dayOrNight = time => (time <= 12) ? 'AM' : "PM"


    function generateListOfAvailableCities() {
        let cities = [];
        availableCities.list.forEach(city => {
        cities.push(<option value={city}>{city}, {availableStates[city]}</option>)
        })
        return cities.map(city => city)
    }

    function handleChange(evt) {
        let { lat, lng } = cityCoordinates(evt.target.value)
        console.log('Location set to: ' + evt.target.value)
        setNextCity(evt.target.value)
        setLocation(evt.target.value)
        setLat(lat)
        setLng(lng)
    }

    function handleHourlyChange(evt) {
        let beginning = hourlyWeather[0]
        setHourlyWeather(hourlyWeather.slice(beginning, parseInt(evt.target.value)))
    }

    async function handleSubmit(evt) {
        console.log('Location Submitted: ' + evt.target.value)
        evt.persist()
        evt.preventDefault();
        try {
            const result = await API.get(apiName, path, myInit)
            setCurrentWeather(result.data.currently)
            setDailyWeather(result.data.daily.data)
            setHourlyWeather(result.data.hourly.data.slice(0, evt.target.value))
            console.log('this is result.data ' + result.data)
            setNextCity(' ')
            currentCityCheck(evt.target.value)
        } catch (error) {
            console.log("Error ", error)
        }
    }

    // Side effects
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await API.get(apiName, path, myInit)
                console.log('Success! adding weather to state')
                setCurrentWeather(result.data.currently)
                setDailyWeather(result.data.daily.data)
                setHourlyWeather(result.data.hourly.data.slice(0, 37))
                setNextCity(' ')
            } catch (error) {
                console.log("Error ", error)
            }
        };
        fetchData();
    }, [location, lat, lng]);




    return (
        <div className="weather">
            {(nextCity === ' ') ? <h2> {currentCityCheck().toUpperCase()}</h2> : <h2> Changing to: {nextCity}</h2>}
            <div className='dropdown'>
                <form onSubmit={handleSubmit}>
                    <select onChange={handleChange} id="location">
                        {generateListOfAvailableCities()}
                    </select>
                </form>

            </div>

            {/* Current Weather */}
            <Current
                currentWeather={currentWeather}
                timeFinder={timeFinder}
                onSubmit={handleSubmit}
                dailyWeather={dailyWeather}
                daysOfWeek={daysOfWeek}
                dailyWeatherImage={dailyWeatherImage}
                weatherSummary={weatherSummary}
                dayOrNight={dayOrNight}
            />

            {/* Daily Weather */}
            <Daily
                timeFinder={timeFinder}
                onSubmit={handleSubmit}
                dailyWeather={dailyWeather}
                daysOfWeek={daysOfWeek}
                dailyWeatherImage={dailyWeatherImage}
                weatherSummary={weatherSummary}
            />

            {/* Hourly Weather */}
            <Hourly
                hourlyWeather={hourlyWeather}
                timeFinder={timeFinder}
                onSubmit={handleSubmit}
                handleHourlyChange={handleHourlyChange}
                daysOfWeek={daysOfWeek}
                dayOrNight={dayOrNight}
                weatherSummary={weatherSummary}
                hourlyWeatherImage={hourlyWeatherImage}
            />

        </div >
    )
}

export default Weather; 