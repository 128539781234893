import React, { Component } from "react";
import { number } from 'prop-types';

class Sleet extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 484 484"
                version="1.1"
                viewBox="0 0 484 484"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <path
                    fill="#92C7D3"
                    d="M422 160h-41.2c.7-7.9 1.2-9.6 1.2-14.3.1-41.2-27.4-77.9-67.7-86.8-23-5.1-44.9-1.6-63 8.2-22.1-32.9-58.8-55.3-100.8-57.9-5.4-.7-10.9-1.3-16.5-1.3-68.5.1-124 57.3-124 128 0 36.8 15.2 69.9 39.3 93.2 21.3 23.7 51 39 86 41.5 0 0 285.6 1.4 286.7 1.4 28.7 0 52-25.1 52-56s-23.3-56-52-56z"
                ></path>
                <path
                    fill="#4D4D4D"
                    d="M422 280c-1.1 0-275-1.4-286.7-1.4-36.7-2.6-68.3-17.9-91.9-44.2C16.8 208.8 2 173.7 2 135.9 2 61 61.2 0 134 0c5.4 0 11 .4 17.6 1.3 40 2.4 77.9 23.1 102.3 55.6 19.1-8.6 40.5-10.6 62.2-5.8 43 9.5 74.1 49.3 74 94.6 0 2.4-.1 4.2-.3 6.2H422c33.1 0 60 28.7 60 64S455.1 280 422 280zm-286.1-16c3.6 0 284.1.7 286.1.7 24.2 0 44-21.9 44-48.4S446.3 168 422 168h-50l.8-8.7c.3-2.9.5-5 .7-6.8.3-2.6.5-4.2.5-6.8.1-37.9-25.7-71.1-61.4-78.9-20.3-4.5-40.2-1.9-57.5 7.4l-6.4 3.4-4-6c-21.6-32.2-56.1-52-94.6-54.3-6.5-.9-11.4-1.2-16-1.2C70 16 18 69.8 18 135.9c0 33.4 13.1 65.2 36.9 88.2 21.2 23.4 49.1 39.9 81 39.9z"
                ></path>
                <circle cx="74" cy="328" r="24" fill="#E4F1F4"></circle>
                <path
                    fill="#4D4D4D"
                    d="M74 360c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm0-48c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z"
                ></path>
                <circle cx="438" cy="336" r="24" fill="#E4F1F4"></circle>
                <path
                    fill="#4D4D4D"
                    d="M438 368c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm0-48c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z"
                ></path>
                <circle cx="211" cy="452" r="24" fill="#E4F1F4"></circle>
                <g fill="#4D4D4D">
                    <path d="M211 484c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm0-48c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zM274 336h-12.7l9-9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-9 9V312c0-4.4-3.6-8-8-8s-8 3.6-8 8v12.7l-9-9c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l9 9H210c-4.4 0-8 3.6-8 8s3.6 8 8 8h12.7l-9 9c-3.1 3.1-3.1 8.2 0 11.3 1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l9-9V376c0 4.4 3.6 8 8 8s8-3.6 8-8v-12.7l9 9c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3c3.1-3.1 3.1-8.2 0-11.3l-9-9H274c4.4 0 8-3.6 8-8s-3.6-8-8-8zM418 424h-12.7l9-9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-9 9V400c0-4.4-3.6-8-8-8s-8 3.6-8 8v12.7l-9-9c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l9 9H354c-4.4 0-8 3.6-8 8s3.6 8 8 8h12.7l-9 9c-3.1 3.1-3.1 8.2 0 11.3 1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l9-9V464c0 4.4 3.6 8 8 8s8-3.6 8-8v-12.7l9 9c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3c3.1-3.1 3.1-8.2 0-11.3l-9-9H418c4.4 0 8-3.6 8-8s-3.6-8-8-8zM10 448h12.7l-9 9c-3.1 3.1-3.1 8.2 0 11.3 1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l9-9V472c0 4.4 3.6 8 8 8s8-3.6 8-8v-12.7l9 9c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3c3.1-3.1 3.1-8.2 0-11.3l-9-9H74c4.4 0 8-3.6 8-8s-3.6-8-8-8H61.3l9-9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-9 9V408c0-4.4-3.6-8-8-8s-8 3.6-8 8v12.7l-9-9c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l9 9H10c-4.4 0-8 3.6-8 8s3.6 8 8 8z"></path>
                </g>
            </svg>
        );
    }
}

Sleet.propTypes = {
    size: number
}
Sleet.defaultProps = {
    size: 500
}
export default Sleet;
