import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function Covid19Procedures() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#before-shift'> Before a shift</a></li>
                        <li><a href='#guidelines'> On-Duty Guidelines</a></li>
                        <li><a href='#end-shift'>End of Shift</a></li>
                        <li><a href='#family-sick'>If you or a family member feels sick</a></li>
                        <li><a href='#office-visits'>Office Visits</a></li>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > COVID-19 Procedures</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        Since the declaration of the COVID-19 pandemic by the WHO, we have fallen into confusing, dynamic and challenging times. This challenge is especially felt by those companies with real world operations. This document covers the necessary information required to operate safely in the phased “return to normal” environment. How these phases will be executed on an overall basis can be found in the HR Return to Work document.
                    </p>

                    <h2 className='sub-headers'> PURPOSE</h2>
                    <p className='document-content'>
                        This document sets forth the special pandemic procedures for Compound Eye operations. This includes supplemental equipment & precautions for shifts, handling of common situations, OSHA guidelines. Full return to work details are not covered in the scope of this document. These rules are in effect until further notice and are subject to change.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='before-shift' className='sub-headers'> Before a Shift:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li> Ensure you have any and all necessary items (*REQUIRED)   </li>
                            <ol type='a'>
                                <li>Disinfectant*</li>
                                <li>Gloves</li>
                                <li>Mask*</li>
                                <li>Hand Sanitizer*</li>
                                <li>Bags for refuse</li>
                                <li>Water + Snack</li>
                                <li>Etc… </li>
                            </ol>
                            <li>If necessary or desired, wipe down the vehicle cabin with disinfectant.</li>
                            <li>Load your equipment into the vehicle.</li>
                            <li>Use your home restroom and wash your hands thoroughly before departing.</li>
                        </ol>
                    </p>
                    <h2 id='guidelines' className='sub-headers'> On-Duty Guidelines:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li> Do your best to remain in the vehicle at all times.</li>
                            <ol type='a'>
                                <li>Should you need to exit the vehicle, returning home is the best option.</li>
                                <li>If you otherwise need to exit the vehicle: Wear a mask and gloves at all times.</li>
                            </ol>
                            <li>Maintain social distancing at all times, both inside and outside the vehicle.</li>
                            <li>In the event of vehicle or equipment malfunction, find the nearest low traffic side street to diagnose the problem.</li>
                            <li>Upon returning to the vehicle (while not at home):</li>
                            <ol type='a'>
                                <li>Dispose of mask and gloves.</li>
                                <li>Sanitize your hands and door handle.</li>
                                <li>Contact fleet support as necessary.</li>
                            </ol>
                        </ol>
                    </p>
                    <h2 id='end-shift'className='sub-headers'> End of Shift:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li>End of shift can proceed as normal unless you left the vehicle away from home. In this case you must: </li>
                            <ol type='a'>
                                <li>Sanitize the vehicle cabin.</li>
                                <li>Wash your hands thoroughly.</li>
                                <li>Log the exit from the vehicle <a href='https://docs.google.com/forms/d/e/1FAIpQLSeTb60JdhMSrGPkQR7p_lGeceKDn4qjvSnKMvFdjns-oIm86g/viewform'>here</a>.</li>
                            </ol>
                        </ol>
                    </p>
                    <h2 id='family-sick'className='sub-headers'> If you or a family member feels sick:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li>Check for symptoms listed on the provided poster.</li>
                            <li>If symptoms are confirmed immediately contact your doctor. </li>
                            <li>Contact fleet support as soon as possible. </li>
                            <li>DO NOT COME TO WORK.</li>
                            <li>Begin implementing following the steps on the provided “What to do if you are sick” Poster.</li>
                            <li>Do not return to work until you are cleared by your manager.</li>
                        </ol>
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='office-visits' className='sub-headers'> Office Visits:</h2>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li>If your role requires normal office access you are required to be trained on standard return to work protocols in an individual session.</li>
                            <li>All office visits must be logged.</li>
                            <li>Upon entry to the office, all employees must complete a temperature check.</li>
                            <li>All other guidelines listed in the return to work document or posted in the office must be followed, no exceptions.</li>
                            <li>If for any reason you must exclude yourself from a planned office visit after arriving, follow the exit procedure and leave immediately. Once you are away from the office and in a safe place contact Fleet Support.</li>
                        </ol>
                    </p>
                    <p className='document-content' style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                        These rules are in place for the safety of you, your household and co-workers. Any violation of these rules will result in immediate termination.
                    </p>


                </div>
            </div>
        </div>

    )
}


export default Covid19Procedures;
