import React, { useState } from 'react';
import Sort from '../Components/WeatherImages/Sort'


const Hourly = (props) => {
    const hourlyTable = props.hourlyWeather
    const [sortStatus, setSortStatus] = useState({ category: [, 'default'] })
    const [isSorted, setIsSorted] = useState(false)

    const sorter = {
        'up': {
            class: 'sort-up',
            fn: (sortStatus.category[0] === 'icon') ? (a, b) => a[sortStatus.category[0]].localeCompare(b[sortStatus.category[0]]) : ((a, b) => a[sortStatus.category[0]] - b[sortStatus.category[0]])
        },
        'down': {
            class: 'sort-up',
            fn: (sortStatus.category[0] === 'icon') ? ((a, b) => { return a[sortStatus.category[0]] > b[sortStatus.category[0]] ? -1 : 1 }) : (a, b) => b[sortStatus.category[0]] - a[sortStatus.category[0]]
        },
        'default': {
            class: 'unsorted',
            fn: ((a, b) => a),

        }
    }
    function hourlyDropdownNumber (){
        let options = [];
        for(let i = 1; i < 37; i++){
            options.push(<option value={i}>{i}</option>)
        }
        return options.map(data=>data)
    }
    const handleSortChange = (evt) => {
        let current = evt
        let nextSort;
        if (sortStatus.category[1] === 'down') {
            nextSort = 'up'
            setIsSorted(true)
        }
        else if (sortStatus.category[1] === 'up') {
            nextSort = 'default';
            setIsSorted(true)
        }
        else if (sortStatus.category[1] === 'default') {
            nextSort = 'down';
            setIsSorted(true)
        }
        setSortStatus({ category: [current, nextSort] });
    }

    return (
        <>
            <h2>HOURS</h2>
            <form onSubmit={() => props.handleSubmit}>
                <select onChange={props.handleHourlyChange}>
                    {hourlyDropdownNumber()}
                </select>
                <button input='submit'>reset</button>
            </form >

            <hr style={{
                width: '60%',
                height:'10%'
            }}></hr>
            < table style={{
                margin: 'auto',
                display: 'inline-block',
                height: '175px',
                overflowY: 'scroll',
                border: 'solid 4px grey',
            }
            }>
                <thead style={{ backgroundColor: 'green', color: 'white' }}>
                    <tr>
                        <th>Time</th>
                        <th>Day </th>
                        <th>Weather
                            <button className='sort-buttons' onClick={() => handleSortChange('icon')}>
                                <Sort size={12} />
                            </button>
                        </th>
                        <th>
                            Temp.
                            <button className='sort-buttons' onClick={() => handleSortChange('temperature')}>
                                <Sort size={12} />
                            </button>
                        </th>
                        <th>
                            Rain
                            <button className='sort-buttons' onClick={() => handleSortChange('precipProbability')}>
                                <Sort size={12} />
                            </button>
                        </th>
                        <th>Details</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {(isSorted === true) ?
                        [...hourlyTable].sort(sorter[sortStatus.category[1]].fn).map((weather, index) => {
                            return (
                                <tr
                                    key={index}
                                    style={(index % 2 === 0)
                                        ?
                                        {
                                            backgroundColor: 'lightgrey',
                                            color: 'black'
                                        }
                                        :
                                        {
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}>
                                    <td>
                                        {((props.timeFinder(weather.time * 1000).getHours() + 11) % 12) + 1}:00
                                        {props.dayOrNight(props.timeFinder(weather.time * 1000).getHours())}
                                    </td>
                                    <td>{props.daysOfWeek(props.timeFinder(weather.time * 1000).getDay()).slice(0, 3)}</td>
                                    <td>{props.weatherSummary(weather.icon)}</td>
                                    <td>{weather.temperature}˚F </td>
                                    <td>{parseFloat(weather.precipProbability * 100).toFixed(1)}% </td>
                                    <td>{weather.summary} {props.hourlyWeatherImage(weather.icon)}</td>
                                </tr>
                            )
                        })
                        :
                        [...hourlyTable].map((weather, index) => {
                            return (
                                <tr
                                    key={index}
                                    style={(index % 2 === 0)
                                        ?
                                        {
                                            backgroundColor: 'lightgrey',
                                            color: 'black'
                                        }
                                        :
                                        {
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}>
                                    <td>
                                        {((props.timeFinder(weather.time * 1000).getHours() + 11) % 12) + 1}:00
                                        {props.dayOrNight(props.timeFinder(weather.time * 1000).getHours())}
                                    </td>
                                    <td>{props.daysOfWeek(props.timeFinder(weather.time * 1000).getDay()).slice(0, 3)}</td>
                                    <td>{props.weatherSummary(weather.icon)}</td>
                                    <td>{weather.temperature}˚F </td>
                                    <td>{parseFloat(weather.precipProbability * 100).toFixed(1)}% </td>
                                    <td>{weather.summary} {props.hourlyWeatherImage(weather.icon)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table >
        </>
    )
}

export default Hourly;