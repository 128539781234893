import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function VehicleRequirementsGuide() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#qualified-vehicles'> Qualified Vehicles</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li style={{ listStyle: 'circle' }}><a href='#raised-rails'>Raised Rails</a></li>
                            <li style={{ listStyle: 'circle' }}><a href='#factory-track'>Factory Track</a></li>
                            <li style={{ listStyle: 'circle' }}><a href='#fixed-mounting-points'>Fixed Mounting Points</a></li>
                        </ol>
                        <li><a href='#age-requirement'> Disqualified Vehicles</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li style={{ listStyle: 'circle' }}><a href='#bare-roof-vehicles'>Bare roof vehicles</a></li>
                            <li style={{ listStyle: 'circle' }}><a href='#exceptions'>Exceptions</a></li>
                        </ol>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > Vehicle Requirements Guide</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        In maintaining efficiency, certain vehicles are too difficult to accommodate for hardware installation and thus should be excluded from the data collection platform. This guide touches on how to prioritize vehicles and their features in a way to help with the application process.
                    </p>
                    <h2 className='sub-headers'> Purpose</h2>
                    <p className='document-content'>
                        This document serves as the current authoritative document pertaining to vehicle qualifications of the data collection platform and specific guidelines around excluding vehicles.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='qualified-vehicles' className='sub-headers'> Qualified Vehicles:</h2>
                    <h4 id='raised-rails' className='paragraph-headers'> Raised Rails:</h4>
                    <p className='document-content'>
                        Raised rails are the easiest as a separate mounting component is not required. In this one scenario the tower will mount to the rails directly. Any vehicle under a 10’ clearance should be easily outfitted for data collection. The most popular options for this type of rack is the <a href='https://www.rackattack.com/product-pages/thule-450r-rapid-crossroad.asp'>Thule Crossroad</a> or the <a href='https://www.rackattack.com/factory-roof-mount-rack-systems/yakima-railgrab/'>Yakima RailGrab</a>
                    </p>
                    <h4 id='factory-track' className='paragraph-headers'> Factory Track:</h4>
                    <p className='document-content'>
                        If the roof has a track (two long slots running front to back), vehicles meeting clearance requirements can be outfitted with control towers attached to the appropriate landing pads inside the track. As the landing pads are specific to the towers used and not the vehicle, this vehicle install is also easy to maintain inventory for.
                    </p>
                    <h4 id='fixed-mounting-points' className='paragraph-headers'> Fixed Mounting Points:</h4>
                    <p className='document-content'>
                        Some vehicles such as Mazdas, BMWs and Volkswagens have fixed mounting points hidden on the vehicle. Although these require a fit kit they are normally cheaper and readily available within 2 days. This specific mounting style is ideal. When hiring someone with fixed mounting points, ordering a fit kit will be necessary.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='disqualified-vehicles' className='sub-headers'> Disqualified Vehicles:</h2>
                    <h4 id='bare-roof-vehicles' className='paragraph-headers'> Bare roof vehicles:</h4>
                    <p className='document-content'>
                        Minus the specified exceptions, all bare roof vehicles will be excluded from the data collection platform.
                    </p>
                    <h4 id='exceptions' className='paragraph-headers'> Exceptions:</h4>
                    <p className='document-content'>
                        The only exception to this rule is to maintain an inventory of rain gutter fit kits for the 10 most common ride sharing vehicles. This is the current list of vehicles, with the inventory subject to rotate:
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                            style={{ width: '20%', height: '20%' }}
                            src='https://static.slab.com/prod/uploads/rf8mjc2p/posts/images/mGsV7C-dziJdWujGgpMzwZnd.png'
                        />
                        <p className='document-content' style={{ width: '40vw' }}>
                            Should the applicant be excluded due to these qualifications and is willing to get a permanent rack install of their own accord, they can be reconsidered.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default VehicleRequirementsGuide;
