import React, { useState, useEffect } from 'react';
import CELogo from './LandingPageImages/CELogo'
import './LandingPage.css'
import SVGLogo from '../NavBar/Icons/SVGLogo'

const LandingPage = () => {
    const [time, setTime] = useState({
        time: new Date().toLocaleTimeString()
    })
    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID)
        }
    })
    function tick() {
        setTime({ time: new Date().toLocaleTimeString() })
    }
    return (
        <div className='landing-page'>
            <div style={{ marginTop: '3.5%' }}>
                <SVGLogo />
            </div>
            <div style={{
                fontSize: '100px'
            }}>
                {time.time}
            </div>
        </div>
    )

}

export default LandingPage;