import React from 'react';
import { Card, Button, Media } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import '../Utilities/DocumentStyles.css'


function PartsMain() {
    return (
        <div>
            <h2 className='card-headers-h2' style={{ textAlign: 'center' }}>Parts</h2>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '0px auto',
                justifyContent: 'space-evenly'
            }}>
                <div className='card-media-layout' style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                }}>
                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'
                    }}>

                        <NavLink
                            to="/parts/partform"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                <Card.Body style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                    <Card.Title style={{ color: 'black' }}>New Part</Card.Title>
                                    <Card.Img src='/card_photos/parts-form.svg'/>
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>
                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'
                    }}>
                        <NavLink
                            to="/parts/directory"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                <Card.Body style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                    <Card.Title style={{ width: '100%', color: 'black' }}>All Parts</Card.Title>
                                    <Card.Img src='/card_photos/spare-parts.svg'/>
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>
                </div>
            </div>
        </div>
    )
}


export default PartsMain;
