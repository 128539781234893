import React from "react";
import { number } from 'prop-types';

function DataCollectionIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            viewBox="0 0 64 64"

        >
            <g data-name="Chemistry">
                <path
                    fill="#c6c5ca"
                    d="M61 11v50H19V11a8 8 0 018-8h26a8 8 0 018 8z"
                ></path>
                <path
                    fill="#898890"
                    d="M61 11v50h-4V11a8 8 0 00-8-8h4a8 8 0 018 8z"
                ></path>
                <g fill="#57a4ff">
                    <path d="M50 51h5v4h-5zM50 47h5v4h-5zM50 43h5v4h-5zM50 39h5v4h-5z"></path>
                </g>
                <path fill="#00eed1" d="M25 9h30v16H25z"></path>
                <path fill="#00ccb3" d="M51 9h4v16h-4z"></path>
                <circle cx="53" cy="32" r="2" fill="#ff3051"></circle>
                <circle cx="45" cy="32" r="2" fill="#ff3051"></circle>
                <path fill="#00eed1" d="M38 49h6v6h-6z"></path>
                <path fill="#ff7956" d="M38 39h6v6h-6z"></path>
                <path fill="#ffde55" d="M34 25a6 6 0 016-6 6.009 6.009 0 016 6z"></path>
                <circle cx="37" cy="32" r="2" fill="#ff3051"></circle>
                <path fill="#ff3051" d="M25 49h6v6h-6z"></path>
                <path fill="#ffde55" d="M25 39h6v6h-6z"></path>
                <circle cx="28" cy="32" r="3" fill="#57a4ff"></circle>
                <path
                    fill="#00eed1"
                    d="M10 46a5 5 0 01-4.53-2.91v-.01A4.876 4.876 0 015 41c0-2.05 1-4 3-4v-5h4v5c2 0 3 1.95 3 4a4.876 4.876 0 01-.47 2.08v.01A5 5 0 0110 46zM8 26h4v6H8z"
                ></path>
                <path d="M53 2H27a9.014 9.014 0 00-9 9v20h-5v-4h2v-2H5v2h2v4H2v2h5v3.13c-1.81.5-3 2.36-3 4.87a5.845 5.845 0 00.52 2.41.663.663 0 00.09.19 5.972 5.972 0 0010.78 0 .663.663 0 00.09-.19A5.845 5.845 0 0016 41c0-2.51-1.19-4.37-3-4.87V33h5v28a1 1 0 001 1h42a1 1 0 001-1V11a9.014 9.014 0 00-9-9zM9 27h2v4H9zm5 14a3.884 3.884 0 01-.37 1.65c0 .01 0 .01-.01.02a3.977 3.977 0 01-7.24 0c0-.01-.01-.01-.01-.02A3.884 3.884 0 016 41c0-1.11.42-3 2-3a1 1 0 001-1v-4h2v4a1 1 0 001 1c1.58 0 2 1.89 2 3zm46 19H20V33h4.14a4 4 0 100-2H20V11a7.008 7.008 0 017-7h26a7.008 7.008 0 017 7zM26 32a2 2 0 112 2 2.006 2.006 0 01-2-2z"></path>
                <path d="M55 8H25a1 1 0 00-1 1v16a1 1 0 001 1h30a1 1 0 001-1V9a1 1 0 00-1-1zM35.1 24a5.017 5.017 0 014.9-4 5.005 5.005 0 014.9 4zM54 24h-7.08a6.879 6.879 0 00-1.32-3.19l6.11-6.1-1.42-1.42-6.1 6.11A6.985 6.985 0 0033.08 24H26V10h28zM40 32a3 3 0 10-3 3 3 3 0 003-3zm-4 0a1 1 0 111 1 1 1 0 01-1-1zM45 29a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zM53 29a3 3 0 103 3 3 3 0 00-3-3zm0 4a1 1 0 111-1 1 1 0 01-1 1zM55 38h-5a1 1 0 00-1 1v16a1 1 0 001 1h5a1 1 0 001-1V39a1 1 0 00-1-1zm-1 2v2h-3v-2zm-3 6v-2h3v2zm3 2v2h-3v-2zm-3 6v-2h3v2zM31 48h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zM44 48h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zM31 38h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zM44 38h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4z"></path>
            </g>
        </svg>
    );
}
DataCollectionIcon.propTypes = {
    size: number
}
DataCollectionIcon.defaultProps = {
    size: 50
}

export default DataCollectionIcon;
