import React from "react";
import { number } from 'prop-types';
function Dashboards(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512.141 512.141"
            version="1.1"
            viewBox="0 0 512.141 512.141"
            xmlSpace="preserve"
            width={props.size}
            height={props.size}
        >
            <g transform="translate(-1)">
                <path
                    fill="#2C3E50"
                    d="M186.45 432.622H327.69100000000003V485.588H186.45z"
                ></path>
                <path
                    fill="#556080"
                    d="M513.071 370.829v30.455c1.261 20.815-14.506 38.749-35.31 40.165H36.381c-20.805-1.417-36.571-19.351-35.31-40.165v-30.455h512z"
                ></path>
                <path
                    fill="#3D324C"
                    d="M513.071 40.236v330.593h-512V40.236C-.19 19.421 15.576 1.487 36.381.071H477.76c20.805 1.416 36.571 19.35 35.311 40.165z"
                ></path>
                <path
                    fill="#F3D55B"
                    d="M168.795 81.373v109.639a3.434 3.434 0 01-3.531 3.266H66.395a3.363 3.363 0 01-3.531-3.178V81.373l50.229-45.021a4.265 4.265 0 015.473 0l50.229 45.021z"
                ></path>
                <path
                    fill="#F0C419"
                    d="M115.829 229.588v52.966l-44.138 26.483-1.236.794a52.141 52.141 0 01-7.592-27.277c.001-29.253 23.714-52.966 52.966-52.966z"
                ></path>
                <path
                    fill="#FF5364"
                    d="M168.795 282.553a52.471 52.471 0 01-15.537 37.429l-37.429-37.429v-52.966c29.252.001 52.966 23.714 52.966 52.966z"
                ></path>
                <path
                    fill="#24AE5F"
                    d="M153.258 319.982a52.889 52.889 0 01-82.803-10.151l1.236-.794 44.138-26.483 37.429 37.428z"
                ></path>
                <path
                    fill="#F0C419"
                    d="M292.381 44.209h17.655a8.828 8.828 0 018.828 8.828v105.931h-35.31V53.036a8.826 8.826 0 018.827-8.827z"
                ></path>
                <path
                    fill="#FF5364"
                    d="M360.618 88.346h22.422a6.444 6.444 0 016.444 6.444v64.177h-35.31V94.791a6.445 6.445 0 016.444-6.445z"
                ></path>
                <path
                    fill="#24AE5F"
                    d="M429.888 114.829h25.114a5.094 5.094 0 015.094 5.094v39.044h-35.302v-39.044a5.095 5.095 0 015.094-5.094z"
                ></path>
                <path
                    fill="#FF5364"
                    d="M248.243 309.036a8.829 8.829 0 01-6.241-15.069l31.656-31.656c10.482-10.002 26.974-10.002 37.455 0l10.337 10.346a8.828 8.828 0 0012.482 0l36.829-36.829c10.479-10.001 26.968-10.001 37.447 0l10.346 10.346a8.828 8.828 0 0012.482 0l31.656-31.647a8.828 8.828 0 0112.483 12.483l-31.656 31.656c-10.354 10.309-27.093 10.309-37.447 0l-10.346-10.355a9.013 9.013 0 00-12.482 0l-36.829 36.829c-10.342 10.338-27.105 10.338-37.447 0l-10.346-10.346a9.013 9.013 0 00-12.482 0l-31.656 31.656a8.827 8.827 0 01-6.241 2.586z"
                ></path>
                <path
                    fill="#556080"
                    d="M142.312 476.76h229.517c14.626 0 26.483 11.857 26.483 26.483v8.828H115.829v-8.828c0-14.626 11.857-26.483 26.483-26.483z"
                ></path>
                <g fill="#FF5364">
                    <path d="M186.45 106.002a8.829 8.829 0 01-5.888-2.251l-64.733-57.944-64.733 57.944c-3.638 3.197-9.173 2.866-12.404-.743s-2.951-9.146.628-12.41l67.902-60.787a13.011 13.011 0 0117.055-.132l68.061 60.91a8.829 8.829 0 01-5.888 15.413z"></path>
                    <path d="M62.864 90.2a8.828 8.828 0 01-8.828-8.828V57.45a8.828 8.828 0 0117.656 0v23.923a8.829 8.829 0 01-8.828 8.827z"></path>
                </g>
                <g fill="#B49ACA">
                    <path d="M468.933 167.795H265.898c-14.626 0-26.483-11.857-26.483-26.483V35.381a8.828 8.828 0 0117.656 0v105.931a8.828 8.828 0 008.828 8.828h203.034a8.828 8.828 0 010 17.655zM468.933 344.346H265.898c-14.626 0-26.483-11.857-26.483-26.483v-105.93a8.828 8.828 0 0117.656 0v105.931a8.828 8.828 0 008.828 8.828h203.034a8.828 8.828 0 010 17.654z"></path>
                </g>
            </g>
        </svg>
    );
}

Dashboards.propTypes = {
    size: number
}
Dashboards.defaultProps = {
    size: 50
}

export default Dashboards;
