import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function DriverRoutingGuide() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#mapping'> Routing for Mapping</a></li>
                        <li><a href='#where-youre-based'>Where you're based</a></li>
                        <li><a href='#where-youre-going'>Where you're going</a></li>
                        <li><a href='#tools'>Tools</a></li>
                        <li><a href='#once-youre-there'>Once you're there</a></li>
                        <li><a href='#mapping-more-sections'>Mapping more sections</a></li>
                        <li><a href='#end-of-mapping-cycle'>The End of a Mapping Cycle</a></li>
                    </ol>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers'> Driver Routing Guide</h1>
                    <h2 id='mapping' className='sub-headers'> Routing for Mapping</h2>
                    <p className='document-content'>
                        We're continually working on better tools to make routing easier for drivers. As this improves, there will always be best practices for how to plan your route to more effectively map your city. Ineffective route planning can make the job tiresome and frustrating as the map begins to fill up. This document provides some general guidelines in order to make your job more fun and easy. It will also be updated as the tools for routing and planning change.
                        <img src="/driver_routing_guide/driver_routing_guide_image_1.png" width={'50%'} height={'50%'} />
                    </p>
                    <p className='document-content'>
                        Take a minute to look at the layout of our mapping area in Adelaide. We'll be approaching this example as though there is only one driver for the city. In a situation where there are multiple drivers for one city, simply treat the city in the example like your area where you drive.
                    </p>
                    <h2 id='where-youre-based' className='sub-headers'> Where you're based</h2>
                    <p className='document-content'>
                        Where you start your driving every day has an impact on how to plan efficient routes. Do you live on the outskirts or border of the city you're mapping? Right in the middle? Somewhere in between?
                        If you live on the outskirts of a city it might feel like you have to drive more to map your city, but the number of road miles is the same: If the routes are planned effectively, little to no additional driving is needed in any case.
                        For the purposes of our example in this guide, we'll be mapping Adelaide, South Australia. On this first image we've indicated the mapping boundaries and home location on the map.
                    </p>
                    <h2 id='where-youre-going' className='sub-headers'> Where you're going</h2>
                    <p className='document-content'>When looking at the map: </p>
                    <ul className='document-content'>
                        <li style={{ listStyle: 'disc' }}> Plan for areas further away, first</li>
                        <li style={{ listStyle: 'disc' }}>Avoid driving highways more than once per mapping cycle</li>
                        <li style={{ listStyle: 'disc' }}>Use as many unmapped roads on your trip as possible, they'll serve as guidelines for later mapping.</li>
                    </ul>
                    <p className='document-content'>
                        For the drives that require longer trips, it may feel more efficient to drive the highway, but if we've already mapped it once on this mapping cycle… All that driving is actually duplicated in the system and not nearly as valuable. It's much better to have the drive  to the end mapping destination take longer and collect data from new areas along the way.
                    </p>
                    <p className='document-content'>
                        Keep note for our example that we'll be planning a different route home each time and that it takes us a few days to map each of these areas.
                    </p>
                    <img src="/driver_routing_guide/driver_routing_guide_image_1.png" width={'50%'} height={'50%'} />
                    <p className='document-content caption-paragraphs'>
                        Example First Route and Mapping Area: Taking primary roads and driving maximum distance to reach a corner of the map zone.
                    </p>
                    <h2 id='tools' className='sub-headers'> Tools</h2>
                    <p className='document-content'>There are several applications online that allow you to plan a navigation route and send it to your phone. Take a look at the available options online and see if there is an available tool to help you plan the route to your end destination. When safe, you can also view compoundops.com on your mobile phone to assist you in finding your route.
                    </p>
                    <p className='document-content'> There are also a suite of apps that allow you to record GPX or tracks files. These files are recorded in real-time and contain a history of where you've been and when. You can use these when the updates on the website are not fast enough and double checking your historic driving in real time. We have a guide for using the GPX Tracker Pro app on Android and are working on guides for more tools, especially one for iPhone. If you find  something that helps you work, let us know and we'll do everything we can to accommodate.
                    </p>
                    <h2 id='once-youre-there' className='sub-headers'> Once You're There</h2>
                    <p className='document-content'>Now that we've got a good understanding of how to route to our destination and have determined a good mapping area to start with, it's important to  drive efficiently so you don't end up having to revisit these areas during cleanup once the map is nearly complete.
                        <img src="/driver_routing_guide/driver_routing_guide_image_3.png" width={'50%'} height={'50%'} />
                    </p>
                    <p className='document-content'>In an ideal world for mapping, every city would be a perfect grid…  In areas with grid patterns it's easiest to drive a 4x4 pattern up and down, then across. As we know this is not the ideal mapping world, roads with less grid like structures should be planned like the image above. Taking similar size sections that should take about 10-20 minutes to drive. From there it is pretty easy to map out these small sections.
                        <img src="/driver_routing_guide/driver_routing_guide_image_4.png" width={'50%'} height={'50%'} />
                    </p>
                    <p className='document-content'> You can see in the driving example: When dealing with more common road structures, just driving up and down doesn't always work as effectively as finding driving strategies that work for you.
                    </p>

                    <ul className='document-content unordered-list'>
                        <li style={{ listStyle: 'disc' }}>Using the ladder strategy in the example above with the orange and green lines, which starts at the circle</li>
                        <li style={{ listStyle: 'disc' }}>When you have a grid, use the grid</li>
                        <li style={{ listStyle: 'disc' }}>The blue line represents the beginning of a second, modified ladder strategy. Can you figure out an efficient way to complete it?</li>
                    </ul>
                    <p className='document-content'>These ways of filling in the smaller map sections are just a few of examples and a great place to start. Keep in mind that every city and car is different and you have to do some driving to figure out how to best tackle driving in your area.
                    </p>
                    <h2 id='mapping-more-sections' className='sub-headers'> Mapping More Sections</h2>
                    <p className='document-content'>Where do we go after completing our first mapping section? How do we finish the whole city? For the first few mapping sections we continue to follow our previous methodology for the first area.
                    </p>
                    <img src="/driver_routing_guide/driver_routing_guide_image_5.png" width={'50%'} height={'50%'} />
                    <p className='document-content'>Here you can see we continue to head to the furthest areas, taking roads you have not yet collected data from.
                    </p>
                    <p className='document-content'>Now, considering it will take a few days in each area for mapping once you've completed several areas following this guide your map may look something like this:
                    </p>
                    <img src="/driver_routing_guide/driver_routing_guide_image_6.png" width={'50%'} height={'50%'} />
                    <p className='document-content'>Notice how the routes begin to break down the remaining area into sections, indicated by the numbers above? If we continue to follow our steps for determining mapping areas, and breaking them down into smaller sections based on our prior driving, the driving and completing of smaller sections, as well as choosing those sections becomes easier. Let's look at a hypothetical breakdown of of this driving and then think about how we'd split the driving up in each section using the driving as guides.
                    </p>
                    <img src="/driver_routing_guide/driver_routing_guide_image_6.png" width={'50%'} height={'50%'} />
                    <p className='document-content caption-paragraphs'>How would you divide these sections into smaller sections for driving?</p>

                    <h2 id='end-of-mapping-cylce' className='sub-headers'> The End Of A Mapping Cycle</h2>

                    <p className='document-content'>
                        All of this culminates into how easy or difficult it is to finish the driving for a cycle. Are there a bunch of holes in the driving map that are also far away from your house?
                    </p>
                    <p className='document-content'>
                        Inevitably, some of the data from your driving will be corrupted or bad due to hardware, software or operational issues such as weather. Staying up to date on your progress will help to understand where this may have happened and require remapping. By being current you can make remapping these areas more convenient and less tiresome.
                    </p>
                    <p className='document-content'>
                        Any areas that have been missed after following the above guide will need to be covered at the end of the mapping cycle before we can begin a new one. However, by following the above and getting a feel for the strongest driving plan for you, you can keep the driving fun and easy. The Operations team is always there to support you and ensure your success. Please reach out when you need anything or have any questions related to this guide.
                    </p>
                    <ol className='document-content'>
                        <li>Plan to head to your furthest unmapped location</li>
                        <li>Plan a route using unmapped roads to and from your mapping destination </li>
                        <li>If in rare instances it takes you your whole shift to go there and back, that's okay! you're collecting valuable data </li>
                        <li>If there are areas beyond what driving a shift would take without highways, save those for last </li>
                        <li>Break the city down into smaller and smaller pieces by planning to map a section, and breaking that section down further using planning and previous driving</li>
                        <li>As the further areas are completed, routes driven begin to provide borders and guidelines to additional mapping </li>
                        <li>These all help to complete the map faster and easier</li>
                    </ol>
                </div>
            </div>
        </div>

    )
}

export default DriverRoutingGuide;
