import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function FleetSupport() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#driver-support'> Driver Support</a></li>
                        <li><a href='#vendor-support'> Vendor Support</a></li>
                        <li><a href='#training'>Training</a></li>
                        <li><a href='#escalation'> Escalations</a></li>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > Fleet Support Overview</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        Fleet Support is the backbone of data collection operations and handles driver situations in real-time. Fleet Support maintains vendor contact, administrative issues and handles all escalations from drivers. Currently there are no specified resources for fleet support, and it is a general operations responsibility.
                    </p>
                    <h2 className='sub-headers'> Purpose</h2>
                    <p className='document-content'>
                        This document lays out standard operating procedures for the fleet support responsibilities as well as how to handle escalations and emergencies.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='driver-support' className='sub-headers'> Driver Support:</h2>
                    <p className='document-content'>
                        <ul className='unordered-list'>
                            <li style={{ listStyle: 'disc' }}>A phone number is to be dedicated to inbound driver calls during hours of operation</li>
                            <li style={{ listStyle: 'disc' }}>This phone number should only be used for</li>
                            <ul className='unordered-list'>
                                <li>Emergencies</li>
                                <li>Incidents</li>
                                <li>Hardware Failures</li>
                                <li>Illness</li>
                            </ul>
                            <li style={{ listStyle: 'disc' }}>For all other issues such as payroll or general questions, an email address will be provided for the drivers.</li>
                            <li style={{ listStyle: 'disc' }}>During the hours of operation the phone line must be kept free and available.</li>
                            <li style={{ listStyle: 'disc' }}>Any administrative calls that do not require immediate attention should be deferred to email.</li>
                            <li style={{ listStyle: 'disc' }}>The mailbox for the support line should be checked at the beginning and end of the day.</li>
                            <li style={{ listStyle: 'disc' }}>In the case of a hardware failure, a remote support option is available. We still need to determine how this process will work and will update this document.</li>
                            <li style={{ listStyle: 'disc' }}>All Hardware issues are to be documented in the ViDAR rig issues log.</li>
                            <li style={{ listStyle: 'disc' }}>Should a driver be unable to complete their shift they should contact fleet support immediately.</li>
                            <li style={{ listStyle: 'disc' }}>In the event a driver cannot complete their shift and cannot reach fleet support, a message should be left on the hotline with their information and details of the situation. Fleet support should follow up and acknowledge the message as soon as possible on the next business day.</li>
                            <li style={{ listStyle: 'disc' }}>Since the timeclock is available via mobile phone, the driver should clock out once they have left a message with fleet support and be compensated for all hours worked.</li>
                            <li style={{ listStyle: 'disc' }}>Should a support request via email go unacknowledged for 3 business days, we recommend the driver reach out via phone to Fleet Support.</li>
                            <li style={{ listStyle: 'disc' }}>Should an emergency arise:</li>
                            <ul className='unordered-list'>
                                <li>Make sure the driver is okay.</li>
                                <li>Ensure the driver is in a safe location.</li>
                                <li>If the driver is injured or unable to get to a safe location, tell the driver to stay calm. Get the location of the driver and call 911 immediately. (If the driver is unable to identify their location, it may be possible to get that information from the rig.) If possible, keep the driver on the phone with a conference call. If not, let them know that help is on the way and call the driver back immediately. <strong>Keep them on the phone until help arrives.</strong></li>
                                <li>If the driver is okay and safe, ask them to describe the situation in as much detail as possible.</li>
                                <li>Assess the needs of the driver and escalate as required.</li>
                                <li>Log the incident in the Incident Log with as much detail as possible during and after the situation</li>
                                <li>Schedule a follow up meeting with the driver to check in 48-72 hours afterward, if possible. </li>
                                <li>Drivers should also fill out an incident report within 24 hours or as soon as possible. </li>
                            </ul>
                        </ul>
                        <hr className='horizontal-row' />
                    </p>
                    <h2 id='vendor-support' className='sub-headers'> Vendor Support:</h2>
                    <p className='document-content'>
                        <ul className='unordered-list'>
                            <li style={{ listStyle: 'disc' }}>Vendor support works two-ways, both to handle the outgoing requests of the company to the vendors, as well as any inbound issues or questions that vendors may have.</li>
                            <li style={{ listStyle: 'disc' }}>For outbound requests, a list of vendors is forthcoming to be linked here.</li>
                            <li style={{ listStyle: 'disc' }}>For inbound requests, assess the needs of the vendor and make a ticket in the Fleet Support Asana.</li>
                            <li style={{ listStyle: 'disc' }}>Setting up appointment windows with the vendor for remote installations in a launch or single iteration is also managed by fleet support.</li>
                        </ul>
                    </p>
                    <h2 id='training' className='sub-headers'> Training:</h2>
                    <p className='document-content'>
                        <ul className='unordered-list'>
                            <li style={{ listStyle: 'disc' }}>At this time remote training is a Fleet Support responsibility. This falls in line to coordinate installations with remote vendors.</li>
                        </ul>
                    </p>
                    <h2 id='escalations' className='sub-headers'> Escalations:</h2>
                    <p className='document-content'>
                        There are many cases in which the proper manager must be informed of a situation either from a driver or a vendor. The current escalation tree is as follows: (Phone Numbers/Address Book).
                        <ol className='ordered-list'>
                            <li>Driver</li>
                            <li>Launcher</li>
                            <li>Fleet Support</li>
                            <li>Director of Operations</li>
                            <li>Head of People</li>
                            <li>CEO</li>
                        </ol>
                        In the event the next person cannot be reached, the next highest level should be contacted until the situation can be resolved. For all non-hardware escalations, a ticket should be made and assigned to the appropriate party in the escalation chain. A regular meeting is to be held to discuss any escalations that require additional support.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default FleetSupport;
