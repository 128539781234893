import React from 'react';
import { Card, Button, Media } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import '../../Utilities/DocumentStyles.css'

const Part404 = () => {
    return (
        <div>
            <h2 className='card-headers-h2' style={{
                marginTop: '5%',
                textAlign: 'center'
            }}>
                Sorry, part does not exist! Please try a different Asset ID.
            </h2>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '0px auto',
                justifyContent: 'space-evenly'
            }}>
                <div className='card-media-layout' style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                }}>
                    <Card style={{
                        width: '18rem',
                        position: 'relative',
                        margin: '20px',
                        overflow: 'hidden',
                        border: 'none',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.8)',
                        backgroundColor: 'lightblue'
                    }}>
                        <NavLink
                            to="/parts/directory"
                            className='navlink'
                            activeClassName='navlink-active'>
                            <Button style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                <Card.Body style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                    <Card.Img src='/card_photos/unicorn.svg' />
                                </Card.Body>
                            </Button>
                        </NavLink>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Part404;
