import React, { Component } from "react";
import { number } from 'prop-types';

class Hail extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width={size}
                height={size}
            >
                <path
                    fill="#5989b3"
                    d="M336.02 71.984c-33.856 0-64.758 16.245-84.1 42.79-46.024-13.231-91.92 21.386-91.92 69.21-28.21 0-51.55 20.86-55.43 48H64c-30.93 0-56-25.07-56-56s25.07-56 56-56c0-47.91 45.994-82.413 91.92-69.21 47.794-65.594 148.96-53.522 180.1 21.21z"
                ></path>
                <path
                    fill="#436786"
                    d="M321.1 46.874c-46.079-37.029-114.104-28.239-149.18 19.9C125.896 53.543 80 88.161 80 135.984c-45.061 0-70.916 50.225-46.779 86.78C18.02 212.754 8 195.544 8 175.984c0-30.93 25.07-56 56-56 0-47.91 45.994-82.413 91.92-69.21 40.366-55.401 122.233-57.325 165.18-3.9z"
                ></path>
                <path
                    fill="#add9ff"
                    d="M432 295.984H160c-30.93 0-56-25.07-56-56s25.07-56 56-56c0-47.91 45.994-82.413 91.92-69.21 52.411-71.932 165.158-48.807 185.34 37.4 37.311 2.69 66.74 33.82 66.74 71.81 0 39.76-32.24 72-72 72z"
                ></path>
                <path
                    fill="#d6ecff"
                    d="M417.1 110.874c-46.079-37.029-114.104-28.239-149.18 19.9-46.024-13.231-91.92 21.386-91.92 69.21-45.061 0-70.916 50.225-46.779 86.78-15.201-10.01-25.221-27.22-25.221-46.78 0-30.93 25.07-56 56-56 0-47.91 45.994-82.413 91.92-69.21 40.366-55.401 122.233-57.325 165.18-3.9z"
                ></path>
                <circle cx="144" cy="263.984" r="8" fill="#d6ecff"></circle>
                <circle cx="132" cy="251.984" r="4" fill="#d6ecff"></circle>
                <circle cx="48" cy="199.984" r="8" fill="#436786"></circle>
                <circle cx="36" cy="187.984" r="4" fill="#436786"></circle>
                <g fill="#add9ff">
                    <circle cx="32" cy="479.984" r="24"></circle>
                    <circle cx="320" cy="479.984" r="24"></circle>
                    <circle cx="128" cy="479.984" r="24"></circle>
                    <circle cx="416" cy="479.984" r="24"></circle>
                    <circle cx="224" cy="479.984" r="24"></circle>
                </g>
                <path d="M64 239.984h32c0 35.29 28.71 64 64 64h88a8 8 0 100-16h-88c-26.468 0-48-21.533-48-48s21.532-48 48-48a8 8 0 008-8c0-35.29 28.71-64 64-64 35.786 0 64 29.266 64 64 0 4.419 3.582 8 8 8s8-3.581 8-8c0-31.505-18.765-59.95-46.817-72.79 52.765-57.536 146.992-31.064 164.288 42.804a8 8 0 007.214 6.156c33.26 2.398 59.315 30.435 59.315 63.83 0 35.29-28.71 64-64 64H296a8 8 0 000 16h136c44.112 0 80-35.888 80-80 0-39.794-29.603-73.503-68.398-79.162-13.097-45.182-53.642-78.391-102.339-80.703-35.446-75.097-136.896-86.359-188.497-22.374-47.277-10.082-91.993 23.667-96.413 70.694C24.654 116.229 0 143.282 0 175.984c0 35.29 28.71 64 64 64zm0-112a8 8 0 008-8c0-42.63 40.99-73.225 81.71-61.521a8.004 8.004 0 008.676-2.978C203.364-.757 289.212 3.84 323.563 64.686c-29.198 3.292-56.243 18.052-74.797 41.059-47.277-10.082-91.993 23.667-96.413 70.694-26.343 3.15-47.806 22.373-54.311 47.546H64c-26.468 0-48-21.533-48-48s21.532-48.001 48-48.001zM32 447.984c-17.645 0-32 14.355-32 32s14.356 32 32 32 32-14.355 32-32-14.355-32-32-32zm0 48c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16c0 8.823-7.178 16-16 16zM141.121 321.838a8 8 0 00-11.267 1.024l-80 96c-4.37 5.244-.553 13.122 6.142 13.122 2.292 0 4.567-.98 6.149-2.878l80-96a7.999 7.999 0 00-1.024-11.268zM320 447.984c-17.645 0-32 14.355-32 32s14.355 32 32 32 32-14.355 32-32-14.355-32-32-32zm0 48c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16c0 8.823-7.178 16-16 16zM509.121 321.838a8 8 0 00-11.267 1.024l-80 96c-4.37 5.244-.553 13.122 6.142 13.122 2.292 0 4.567-.98 6.149-2.878l80-96a7.999 7.999 0 00-1.024-11.268zM128 447.984c-17.645 0-32 14.355-32 32s14.355 32 32 32 32-14.355 32-32-14.355-32-32-32zm0 48c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16c0 8.823-7.178 16-16 16zM416 447.984c-17.645 0-32 14.355-32 32s14.355 32 32 32 32-14.355 32-32-14.355-32-32-32zm0 48c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16c0 8.823-7.178 16-16 16z"></path>
                <path d="M277.66 301.644c3.116-3.117 3.115-8.203 0-11.32-3.116-3.115-8.203-3.116-11.32 0-3.111 3.111-3.117 8.203 0 11.32 3.115 3.115 8.203 3.117 11.32 0zM138.879 430.13c3.385 2.821 8.432 2.378 11.267-1.024l80-96a8 8 0 00-12.291-10.244l-80 96a7.999 7.999 0 001.024 11.268zM330.879 430.13c3.385 2.821 8.432 2.378 11.267-1.024l80-96a8 8 0 00-12.291-10.244l-80 96a7.999 7.999 0 001.024 11.268zM224 447.984c-17.645 0-32 14.355-32 32s14.355 32 32 32 32-14.355 32-32-14.355-32-32-32zm0 48c-8.822 0-16-7.178-16-16s7.178-16 16-16 16 7.178 16 16c0 8.823-7.178 16-16 16zM234.879 430.13c3.385 2.821 8.432 2.378 11.267-1.024l80-96a8 8 0 00-12.291-10.244l-80 96a7.999 7.999 0 001.024 11.268z"></path>
            </svg>
        );
    }
}

Hail.propTypes = {
    size: number
}
Hail.defaultProps = {
    size: 500
}
export default Hail;
