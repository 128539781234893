import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function DriverOnboarding() {
    const xyzString = '{x}/{y}/{z}'
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#initial-employee-verification'> Initial Employee Verification</a></li>
                        <li><a href='#pre-training-onboarding'> Pre-training Onboarding</a></li>
                        <li><a href='#prelive-training'>Pre-live training</a></li>
                        <li><a href='#remote-deployment'> Remote Deployment</a></li>
                        <li><a href='#post-training'> Post-Training</a></li>
                        <li><a href='#final-notes'> Final Notes</a></li>

                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > Driver Onboarding</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        Data will be collected by drivers who will need to be onboarded to the data collection platform. These drivers, after being hired, will need to go through the registration and training process in order to begin driving.
                    </p>
                    <h2 className='sub-headers'> Purpose</h2>
                    <p className='document-content'>
                        This document serves as the current onboarding document for onboarding drivers to the Compound Eye data collection platform. It serves to detail the steps in the process, with the exception of training, which is covered in the Driver Training document.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='initial-employee-verification' className='sub-headers'> Initial Employee Verification:</h2>
                    <p className='document-content'>
                        <i>*To better understand the workflow of the steps provided in this document: Please see the Driver Hiring Workflow diagram.</i>
                    </p>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li>Toward the completion of the hiring process a background check is run on the applicant. This is performed with Checkr using their integration to Greenhouse. Once the applicant has reached the BG Check stage: Initiate a background check on the applicant and DO NOT PROCEED with the hiring process beyond an interview until the background check is complete. Because this is a job that requires a clean driving record: No job should be offered to any candidate who has not passed the requirements of our background check.</li>
                            <li>Once the candidate has been screened and accepted, employment verification and tax documents should be completed. In a physical deployment this will be handled in person, in a remote situation these documents will be handled by Fleet Support and in all cases these documents are collected in Sequoia One.</li>
                            <ol type='a'>
                                <li>I-9 Verification</li>
                                <li>Tax Documentation</li>
                                <li>Non-Disclosure Agreement</li>
                                <li>A copy of Driver’s License + Valid Insurance </li>
                                <li>Hardware Agreement</li>
                            </ol>
                        </ol>
                    </p>
                    <p className='document-content'>
                        In remote situations a representative must be designated to verify I-9 documents in person. More on this is in the Deployment document. Driver’s licence & vehicle insurance must also be collected manually, however this can be stored in Sequoia One. A clear photograph of the front and back of the ID, as well as both for the insurance card is to be used in all cases.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='pre-training-onboarding' className='sub-headers'> Pre-training Onboarding:</h2>
                    <p className='document-content'>
                        Once employment verification and documents have been completed, the new hire will need to be set up with several tools and services to be successful in the online training. Using the email they register with, we will send them the following:
                        <ol className='ordered-list'>
                            <li>App installation instructions</li>
                            <li>Schedule account registration</li>
                            <li>Payroll account registration</li>
                            <li>An account to the online training portal</li>
                        </ol>
                    </p>
                    <p className='document-content'>
                    <strong>The training account for the driver should only be created after the driver has completed the registration for all other services listed here. Only at that point, a welcome email is sent to the driver inviting them to begin the online training.</strong> 
                    </p>
                    <h2 className='sub-headers' style={{backgroundColor:'salmon'}}>*ONLINE TRAINING OCCURS WITHIN WORKFLOW*  </h2>
                    <h2 id='prelive-training'className='sub-headers'>Pre-Live Training:</h2>
                    <p className='document-content'>
                    A notification is sent when a driver completes online training, the only step to take at this point is to reach out to the driver to schedule live training. Offer the time slots available, schedule and await the results of the live training.
                    </p>
                    <h2 className='sub-headers' style={{backgroundColor:'salmon'}}>*LIVE TRAINING OCCURS WITHIN WORKFLOW*  </h2>
                    <hr className='horizontal-row' />
                    <h2 id='post-training' className='sub-headers'>Post-Training:</h2>
                    <h2 className='sub-headers'>Physical Deployment: </h2>
                    <p className='document-content'>
                    <ol className='ordered-list'>
                            <li>During the scheduled live training, one launcher will perform installations of the data collection hardware on driver vehicles.</li>
                            <li>During the installations, the installing launcher will also inspect the drivers vehicles, ensuring they are fully functional.</li>
                            <li>Drivers are given supplemental operations documentation and supplies for performing the job and told when the first schedule release will take place.</li>
                            <li>Schedule is released.</li>
                        </ol>
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='remote-deployment'className='sub-headers'>Remote Deployment:</h2>
                    <p className='document-content'>
                    <ol className='ordered-list'>
                            <li>Installation is scheduled and coordinated between the driver and the installation partner for the market.</li>
                            <li>If necessary, collect the photos for the vehicle inspection from the driver.</li>
                            <li>Drivers are sent supplemental operations documentation and supplies for performing the job and told when the first schedule release will take place.</li>
                            <li>Schedule is released.</li>
                        </ol>
                    </p>
                    <h2 id='final-notes' className='sub-headers'>Final Notes:</h2>
                    <p className='document-content'>
                    <ol className='ordered-list'>
                            <li>Drivers are to be given ample communication through the onboarding process. </li>
                            <li>Drivers may complete the onboarding at their leisure within the allotted time before live training. Drivers who do not complete the pre-training onboarding within the allotted time are subject to immediate termination.</li>
                            <li>Conflicts of schedule should be resolved on a case by case basis.</li>
                        </ol>
                    </p>
                </div>
            </div>
        </div>
    )
}


export default DriverOnboarding;
