import React from "react";
import { number } from 'prop-types';

function Home(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            viewBox="0 0 511.995 511.995"
        >
            <path
                fill="#acebe2"
                d="M507.308 259.271c-3.12 3.12-7.22 4.69-11.31 4.69s-8.19-1.57-11.31-4.69l-12.69-12.69v203.4c0 25.36-20.64 46-46 46h-340c-25.36 0-46-20.64-46-46v-203.4l-12.69 12.69c-6.24 6.25-16.38 6.25-22.62 0-6.25-6.25-6.25-16.38 0-22.63l198.98-198.99c28.86-28.85 75.8-28.85 104.66 0l198.98 198.99c6.25 6.25 6.25 16.38 0 22.63z"
            ></path>
            <path
                fill="#e2e0e0"
                d="M335.998 337.981v142c0 8.84-7.16 16-16 16h-128c-8.84 0-16-7.16-16-16v-142c0-27.57 22.43-50 50-50h60c27.57 0 50 22.43 50 50z"
            ></path>
            <path
                fill="#98d7ce"
                d="M507.308 259.271c-3.12 3.12-7.22 4.69-11.31 4.69s-8.19-1.57-11.31-4.69l-12.69-12.69v203.4c0 25.36-20.64 46-46 46h-5.15c6.94-8.06 11.15-18.55 11.15-30v-248.65l-163.67-163.68c-16.04-16.04-37.67-23.16-58.68-21.36 29.04-23.36 71.74-21.57 98.68 5.36l198.98 198.99c6.25 6.25 6.25 16.38 0 22.63z"
            ></path>
            <path
                fill="#cecccc"
                d="M335.998 337.981v142c0 8.84-7.16 16-16 16h-32c8.84 0 16-7.16 16-16v-142c0-27.57-22.43-50-50-50h32c27.57 0 50 22.43 50 50z"
            ></path>
        </svg>
    );
}

Home.propTypes = {
    size: number
}
Home.defaultProps = {
    size: 50
}

export default Home;
