import React from "react";
import { number } from 'prop-types';

function DriverDirectoryIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            viewBox="0 0 64 64"
        >
            <path
                fill="#e6e9ed"
                d="M62 10v42c0 1.1-.9 2-2 2H40v-9.13c0-1.18-.53-2.31-1.44-3.07L34 38V26c0-2.21-1.79-4-4-4H8V10z"
            ></path>
            <path
                fill="#5cd6b3"
                d="M8 10V4c0-1.1.9-2 2-2h50c1.1 0 2 .9 2 2v6z"
            ></path>
            <path fill="#fcd770" d="M44 42h14v8H44z"></path>
            <path
                fill="#ff826e"
                d="M58 36v2H42v-2c0-4.42 3.58-8 8-8 2.21 0 4.21.9 5.66 2.34A8.014 8.014 0 0158 36z"
            ></path>
            <circle cx="50" cy="24" r="4" fill="#f0d0b4"></circle>
            <path
                fill="#cf9e76"
                d="M8 14v8H6c-2.21 0-4-1.79-4-4 0-1.1.45-2.1 1.17-2.83C3.9 14.45 4.9 14 6 14z"
            ></path>
            <path
                fill="#d3a06c"
                d="M30 22c2.21 0 4 1.79 4 4v12l4.56 3.8c.91.76 1.44 1.89 1.44 3.07V58c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4V18c0 2.21 1.79 4 4 4h2z"
            ></path>
            <g fill="#fcd770">
                <path d="M18 55v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1zM10 55v2c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z"></path>
            </g>
            <g>
                <path d="M60 1H10C8.346 1 7 2.346 7 4v9H6c-2.757 0-5 2.243-5 5v40c0 2.757 2.243 5 5 5h30c2.757 0 5-2.243 5-5v-3h19c1.654 0 3-1.346 3-3V4c0-1.654-1.346-3-3-3zM10 3h50c.551 0 1 .448 1 1v5H9V4c0-.552.449-1 1-1zM6 15h1v6H6c-1.654 0-3-1.346-3-3s1.346-3 3-3zm33 43c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3V21.998A4.973 4.973 0 006 23h24c1.654 0 3 1.346 3 3v12.469l4.92 4.101A2.988 2.988 0 0139 44.873zm21-5H41v-8.127a4.988 4.988 0 00-1.799-3.841L35 37.531V26c0-2.757-2.243-5-5-5H9V11h52v41c0 .552-.449 1-1 1z"></path>
                <path d="M11 5h2v2h-2zM15 5h2v2h-2zM19 5h2v2h-2zM43 51h16V41H43zm2-8h12v6H45zM53.377 27.663A4.972 4.972 0 0055 24c0-2.757-2.243-5-5-5s-5 2.243-5 5c0 1.451.632 2.749 1.623 3.663C43.33 29.002 41 32.232 41 36v3h18v-3c0-3.768-2.33-6.998-5.623-8.337zM47 24c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3zm10 13H43v-1c0-3.859 3.14-7 7-7s7 3.141 7 7zM11 13h22v2H11zM11 17h2v2h-2zM15 17h18v2H15zM57 13h2v2h-2zM53 13h2v2h-2zM49 13h2v2h-2zM9 53H7c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-2 4v-2h2l.001 2zM17 53h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-2 4v-2h2l.001 2zM5 49h14v2H5zM17 45h2v2h-2zM5 45h10v2H5zM29 29h2v2h-2zM29 25h2v2h-2zM29 33h2v2h-2z"></path>
            </g>
        </svg>
    );
}

DriverDirectoryIcon.propTypes = {
    size: number
}
DriverDirectoryIcon.defaultProps = {
    size: 50
}

export default DriverDirectoryIcon;
