import React from 'react';

const Current = (props) => {
    return (
        <div className='current-weather' style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div className='current-weather-box' style={{
                align: 'center'
                }}
            >
                {props.dailyWeatherImage(props.currentWeather.icon)}
                <p>
                    {props.currentWeather.summary} | {props.currentWeather.temperature}˚F
                </p>
                <h3>
                    {(((props.timeFinder(props.currentWeather.time * 1000).getHours() + 11) % 12) + 1)}:
                    {props.timeFinder(props.currentWeather.time * 1000).getMinutes().toString().padStart(2, "0")}
                    {props.dayOrNight(props.timeFinder(props.currentWeather.time * 1000).getHours())}&nbsp;
                    {props.daysOfWeek(props.timeFinder(props.currentWeather.time * 1000).getDay())},&nbsp;
                    {props.timeFinder(props.currentWeather.time * 1000).getMonth() % 12 + 1}/{props.timeFinder(props.currentWeather.time * 1000).getDate()}/{props.timeFinder(props.currentWeather.time * 1000).getFullYear().toString().slice(-2)}
                </h3>
            </div>
        </div>
    )
}

export default Current;