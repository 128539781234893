import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function AppWalkThrough() {
    const xyzString = '{x}/{y}/{z}'
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#installation'> Installation</a></li>
                        <li><a href='#setup'> Setup</a></li>
                        <li><a href='#using-the-app'>Using the App</a></li>
                        <li><a href='#recording'>Recording</a></li>
                        <li><a href='#notes'>Notes</a></li>
                    </ol>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > App Walkthrough</h1>
                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        The driver app is a companion app put on phones used during data collection to track progress and drive uncollected roads. Currently, the app is 3rd party which uses public map servers in order to operate. In this document we will cover installation, setup and use of the app while collecting data for Compound Eye. A complete visual walkthrough can be found here.
                    </p>
                    <h2 className='sub-headers'> PURPOSE</h2>
                    <p className='document-content'>
                        This document serves to provide step-by-step instructions to functionally use the Driver App for data collection. As data collection needs change, the app is subject to update at any time. This document should be updated to reflect the updated process.
                    </p>
                    <h2 id='installation' className='sub-headers'> Installation:</h2>
                    <ol className='document-content ordered-list'>
                        <li> Go to the Play Store</li>
                        <li>Search for GPX Viewer Pro</li>
                        <li>Use the provided code to purchase the full version of the app</li>
                        <li>Download and Install the app</li>
                        <li>Verify your install and open GPX Viewer Pro</li>
                    </ol>
                    <hr className='horizontal-row'></hr>
                    <h2 id='setup' className='sub-headers'> Setup:</h2>
                    <ol className='document-content ordered-list'>
                        <li> Go to the "Map" Icon on the bottom left</li>
                        <img src="/gpx_photos/gpx_image_1.jpeg" width={'25%'} height={'25%'} />
                        <li> Click the "Record" button in the middle-top of the screen</li>
                        <img src="/gpx_photos/gpx_image_2.jpeg" width={'25%'} height={'25%'} />
                        <li> Stop Recording</li>
                        <img src="/gpx_photos/gpx_image_3.jpeg" width={'25%'} height={'25%'} />
                        <li> Go to Tracks </li>
                        <li> Click the corresponding record of the most recent recording (this is usually generated by date)</li>
                        <img src="/gpx_photos/gpx_image_4.webp" width={'25%'} height={'25%'} />
                        <li> Open that recording and all the drive statistics and data will be there</li>
                        <img src="/gpx_photos/gpx_image_5.webp" width={'25%'} height={'25%'} />
                        <li> Completed tracks will appear in the Map </li>
                    </ol>
                    <hr className='horizontal-row'></hr>
                    <h2 id='using-the-app' className='sub-headers'> Using the App:</h2>
                    <p className='document-content'>
                        In essence, all the app does is record “tracks” files and load “tracks” files onto a map. A tracks file is a .GPX extension file that contains a GPS record or “track” of a journey, trip, run etc… Tracks files can also be generated using a computer or mapping application on the web.
                        <br />
                    </p>
                    <p className='document-content'>For all functional purposes of driving with the app it will perform these main functions: </p>
                    <ul className='document-content unordered-list'>
                        <li style={{ listStyle: 'disc' }}>Provide the boundaries for your driving area.</li>
                        <li style={{ listStyle: 'disc' }}>Record tracks of your driving on shift to keep track of where you’ve been.</li>
                        <li style={{ listStyle: 'disc' }}>Log time driving duration.</li>
                    </ul>
                    <hr className='horizontal-row'></hr>
                    <h2 id='recording' className='sub-headers'> Recording:</h2>
                    <p className='document-content'>
                        As you drive within your area, it will be important to keep a record of where you have been. This is to ensure that while you are driving all roads in the area that you do not miss any or drive one road several times. To record your tracks, simply set the app to to the  "Map" view of the app and press the record button. The app will now record everywhere you go until you press the stop recording button again.
                    </p>
                    <br />
                    <p className='document-content'>The app will now record a timestamped GPX file to your phone which you can load to the app with your other driving to plan your future routes and stay off roads you’ve already driven.
                    </p>
                    <hr className='horizontal-row'></hr>
                    <h2 id='notes' className='sub-headers'> Notes:</h2>

                    <ul className='document-content unordered-list'>
                        <li style={{ fontWeight: 'bold', listStyle: 'disc' }}>Don't forget to stop recording when going on break and to re-record when returning from break for the most accurate tracks and bookkeeping </li>
                        <li style={{ fontWeight: 'bold', listStyle: 'disc' }}>Using the app consistently and correctly maintains accurate record keeping between Sequoia and paper time cards </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default AppWalkThrough;
