import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function DriverHiringGuide() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#driver-requirements'> Driver Requirements</a></li>
                        <li><a href='#age-requirement'> Age Requirement</a></li>
                        <li><a href='#documentation'>Documentation</a></li>
                        <li><a href='#equipment-requirements'>Equipment Requirements</a></li>
                        <li><a href='#background-check'>Background Check & Driving Record:</a></li>
                        <li><a href='#locally-based'>Locally Based</a></li>
                        <li><a href='#hiring-process'>Hiring Process</a></li>
                    </ol>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > Driver Hiring Guide</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        As stated in the Data Collection Document, there are certain criteria and requirements that drivers should meet in order to work on the data collection platform. This document seeks to drill down into each of these requirements and the steps required in hiring drivers.
                    </p>
                    <h2 className='sub-headers'> Purpose</h2>
                    <p className='document-content'>
                        This document serves as the current guidelines and process for driver hiring. It also provides specific detail surrounding each driver requirement.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='driver-requirements'className='sub-headers'> Driver Requirements </h2>
                    <p className='document-content'>
                        <strong>Review of Driver Requirements: </strong> Although some requirements vary from between states, generally we require our drivers to:
                    </p>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li>Be at least 18 years of age</li>
                            <li>Have a Valid Drivers Licence, Registration and Insurance</li>
                            <li>Provide their own vehicle (Mileage Reimbursement provided)</li>
                            <li>Meet Vehicle Qualifications (Separate document, coming soon)</li>
                            <li>Have a cell phone for safety and tracking progress</li>
                            <li>Pass a Background check</li>
                            <li>Have a clean driving record</li>
                            <li>Be based locally within the market</li>
                        </ol>
                    </p>
                    <h4 id='age-requirement'className='paragraph-headers'><strong>Age Requirement:</strong> </h4>
                    <p className='document-content'>
                        As a general rule, we require drivers to be 18, in some states this age is 21. Check the local market requirements in each case to ensure all drivers meet legal requirements.
                    </p>
                    <h4 id='documentation' className='paragraph-headers'><strong>Documentation:</strong> </h4>
                    <p className='document-content'>
                        Valid documentation proving the driver is of legal age and able to drive for us is required. This includes Driver’s License, Vehicle Registration and Insurance. When these documents expire, a renewed copy must be kept on file. These documents can be stored in Sequoia One but will require a separate upload. Keep these documents on file within the ATS and copy them into Sequoia One where applicable.
                    </p>
                    <h4 id='equipment-requirements'className='paragraph-headers'><strong>Equipment Requirements:</strong> </h4>
                    <p className='document-content'>
                        <i>*Please see the Vehicle Requirements document here. </i>
                        <br />
                        <br />
                        Drivers provide their own vehicle and must have a cell phone to use while on duty. Qualification of the vehicle and driver are subject to the Vehicle Requirements Document.
                    </p>
                    <h4 id='background-check' className='paragraph-headers'><strong>Background Check & Driving Record:</strong> </h4>
                    <p className='document-content'>
                        All applicants are subject to an MVR and basic criminal background check. Drivers with more than 3 moving violations in the past 5 years are to be excluded. Applicants with non-violent misdemeanors are not to be excluded from driving based on this status. Non-violent felony records should be considered on a case-by-case basis. Any applicant with a record of a violent crime is to be rejected unless special consideration is to be given to the applicant based on application responses. In this case, the final hiring decision should be made by the Director of Operations.
                    </p>
                    <h4 id='locally-based' className='paragraph-headers'><strong>Locally Based:</strong> </h4>
                    <p className='document-content'>
                        All applicants within a 5 mile radius of the market are to be considered. No consideration is to be made based on where the applicant resides within this zone.
                    </p>
                    <hr className='horizontal-row' />
                    <h4 id='hiring-process' className='paragraph-headers'><strong>Hiring Process:</strong> </h4>
                    <p className='document-content'>
                        The pre-onboarding workflow is straightforward. Follow these steps and the Driving Hiring workflow diagram:
                        <br />
                        <ol className='ordered-list'>
                            <li><strong>Post the Job:</strong> Using the ATS and the help of HR, post the job in the specified market.</li>
                            <li><strong>Phone Screens:</strong> Speak with each candidate on the phone for 5-15 minutes. Ensure they meet all the requirements to drive on the platform. Hold onto their vehicle information for potential inventory.</li>
                            <li><strong>Interview: </strong> This is an opportunity to thin your pool of those that meet the requirements down to those most excited about data collection. A 15 minute video conference is acceptable.</li>
                            <li><strong>Offer:</strong> A conditional offer of employment is made to the driver.</li>
                            <li><strong>Background Check:</strong> An integration between the ATS and Checkr will make the BG check process easy. Once you have phone screened the candidate, initiate the process through the ATS to collect the necessary information and consent from them and await a response.</li>
                        </ol>
                        <strong>Order Roof Rack Hardware: </strong>In most cases, new hires will need some supplemental hardware to begin data collection, such as roof rails or a fit kit. As you transition drivers from hiring to onboarding: Order the correct hardware to your inventory to complete the installation process.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default DriverHiringGuide;
