import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsmobile from '../src/aws-exports';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
Amplify.configure(awsmobile);

ReactDOM.render(
  // <Router>
    <React.StrictMode>
      <AmplifyProvider>
        <App />
      </AmplifyProvider>
    </React.StrictMode>
  // </Router>,
  ,
  document.getElementById('root')
);