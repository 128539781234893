import React from "react";
import { number } from 'prop-types';

function Docs(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 480 480"
            version="1.1"
            viewBox="0 0 480 480"
            xmlSpace="preserve"
            width={props.size}
            height={props.size}
        >
            <path
                fill="#8DC6F3"
                d="M344 8H80c-8.837 0-16 7.163-16 16v432c0 8.837 7.163 16 16 16h320c8.837 0 16-7.163 16-16V80L344 8z"
            ></path>
            <path fill="#5684A8" d="M344 8v72a8 8 0 008 8h64v-8L344 8z"></path>
            <g fill="#FBB040">
                <path d="M112 152h112v80H112v-80zM256 152h112v80H256v-80z"></path>
            </g>
            <g fill="#353535">
                <path d="M416.992 69.816l-62.64-62.768A23.848 23.848 0 00337.36 0H80C66.745 0 56 10.745 56 24v432c0 13.255 10.745 24 24 24h320c13.255 0 24-10.745 24-24V86.768a23.844 23.844 0 00-7.008-16.952zM408 456a8 8 0 01-8 8H80a8 8 0 01-8-8V24a8 8 0 018-8h256v56c0 13.255 10.745 24 24 24h32V80h-32a8 8 0 01-8-8V27.344l53.664 53.776A7.936 7.936 0 01408 86.768V456z"></path>
                <path d="M104 48h16v16h-16V48zM136 48h160v16H136V48zM104 80h192v16H104V80zM224 144H112a8 8 0 00-8 8v80a8 8 0 008 8h112a8 8 0 008-8v-80a8 8 0 00-8-8zm-8 80h-96v-64h96v64zM368 144H256a8 8 0 00-8 8v80a8 8 0 008 8h112a8 8 0 008-8v-80a8 8 0 00-8-8zm-8 80h-96v-64h96v64zM104 272h16v16h-16v-16zM136 272h96v16h-96v-16zM248 272h16v16h-16v-16zM280 272h96v16h-96v-16zM104 320h128v16H104v-16zM104 368h128v16H104v-16zM104 416h128v16H104v-16zM248 368h128v16H248v-16zM248 416h128v16H248v-16zM248 320h128v16H248v-16z"></path>
            </g>
        </svg>
    );
}
Docs.propTypes = {
    size: number
}
Docs.defaultProps = {
    size: 50
}

export default Docs;
