import React, { Component } from "react";
import { number } from 'prop-types';

class Rain extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 480 480"
                version="1.1"
                viewBox="0 0 480 480"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <g fill="#4D4D4D">
                    <path d="M8 328c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM56 408c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM104 312c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM136 456c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM184 360c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM296 440c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM344 360c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM392 456c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM424 312c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8zM472 408c-4.416 0-8-3.576-8-8v-24c0-4.424 3.584-8 8-8s8 3.576 8 8v24c0 4.424-3.584 8-8 8z"></path>
                </g>
                <path
                    fill="#92C7D3"
                    d="M239.88 38.544C186.384 39.28 143.2 128 143.2 248H344c0-120-46.512-208.72-104.12-209.456z"
                ></path>
                <path
                    fill="#77B9C8"
                    d="M239.2 30.272c-110.304 0-199.728 92.848-199.728 203.152 0 3.744.128 14.576.328 14.576h103.4c0-120 43.184-212.72 96.68-213.456-.224 0-.448-2.032-.68-2.032.224 0 .448-.968.68-.968.208 0 .416-.536.632-.536-.208 0-.416 3.784-.632 3.784C297.488 35.528 344 128 344 248h94.6c.2 0 .328-10.64.328-14.384C438.92 123.312 349.504 30.272 239.2 30.272z"
                ></path>
                <path
                    fill="#4D4D4D"
                    d="M248 22.504V8c0-4.424-3.584-8-8-8s-8 3.576-8 8v14.456c-111.216 3.88-200.528 96.992-200.528 210.968 0 17.344 0 22.576 8.328 22.576h398.8c8.328 0 8.328-5.184 8.328-22.384C446.92 120.08 358.472 27.216 248 22.504zm-96.704 216.232c.032-1.464.08-2.92.12-4.376.056-2 .112-4.008.2-6 .048-1.176.112-2.344.168-3.512.112-2.256.224-4.504.368-6.736.056-.936.128-1.864.2-2.8.168-2.408.344-4.816.552-7.2.072-.832.152-1.664.232-2.488.224-2.408.456-4.816.712-7.192.096-.904.208-1.792.312-2.696.256-2.208.52-4.408.8-6.584.16-1.24.336-2.472.512-3.704.232-1.688.48-3.36.728-5.024.304-1.96.616-3.904.944-5.832.136-.792.264-1.584.4-2.368C170.512 98.4 202.072 47.152 239.88 46.544c39.304.592 72.384 47.776 87.432 116.784.144.664.288 1.336.424 2.008a363.361 363.361 0 012.056 10.568c.2 1.096.392 2.2.576 3.304.392 2.304.76 4.616 1.112 6.96.096.656.2 1.304.296 1.96.384 2.648.736 5.312 1.072 8.008.064.488.12.976.184 1.464a388.4 388.4 0 01.904 8.312c.048.52.104 1.04.16 1.56.256 2.664.48 5.36.68 8.064.056.712.112 1.424.16 2.136.176 2.448.312 4.912.44 7.384.056 1.008.112 2.008.16 3.024.104 2.192.168 4.4.24 6.608.04 1.288.088 2.56.112 3.856.008.48.008.968.016 1.448H151.288c.008-.408 0-.832.008-1.256zM135.264 240H47.528a545.395 545.395 0 01-.056-6.576c0-93.944 65.56-172.6 152.552-191.056-37.672 31.224-63.352 105.776-64.76 197.632zm295.608 0h-78.944c-.008-.392-.032-.776-.04-1.168-.104-5.088-.28-10.12-.536-15.088-.024-.456-.056-.904-.088-1.36a435.797 435.797 0 00-1.056-14.44l-.12-1.416a402.455 402.455 0 00-3.864-29.64c-.104-.6-.2-1.2-.304-1.792-.784-4.488-1.616-8.92-2.544-13.248-.32-1.512-.68-2.976-1.016-4.472-.48-2.12-.968-4.232-1.48-6.312-11.872-48.712-32.48-87.144-58.136-107.704 84.816 20.144 148.184 97.808 148.184 190.264a741.41 741.41 0 01-.056 6.376z"
                ></path>
                <circle cx="191.336" cy="408.64" r="17.336" fill="#77B9C8"></circle>
                <path
                    fill="#4D4D4D"
                    d="M240 240c-4.416 0-8 3.576-8 8v200c0 8.824-7.176 16-16 16s-16-7.176-16-16v-15.624c9.696-3.56 16.664-12.8 16.664-23.712 0-13.968-11.368-25.328-25.336-25.328s-25.336 11.36-25.336 25.328c0 11.4 7.624 20.96 18 24.128V448c0 17.648 14.352 32 32 32s32-14.352 32-32V248c.008-4.424-3.576-8-7.992-8zm-48.664 159.336c5.152 0 9.336 4.184 9.336 9.328 0 3.624-2.136 6.688-5.16 8.232-1.072-.536-2.232-.896-3.512-.896-1.536 0-2.896.552-4.112 1.304-3.44-1.376-5.888-4.712-5.888-8.64 0-5.144 4.184-9.328 9.336-9.328z"
                ></path>
                <path
                    fill="#E4F1F4"
                    d="M429.504 111.336c0 25.408-11.416 30-25.504 30s-25.504-4.592-25.504-30 25.504-62 25.504-62 25.504 36.592 25.504 62z"
                ></path>
                <path
                    fill="#4D4D4D"
                    d="M404 149.336c-22.544 0-33.504-12.432-33.504-38 0-27.6 25.832-64.992 26.936-66.568 2.992-4.296 10.136-4.296 13.128 0 1.104 1.576 26.936 38.968 26.936 66.568.008 25.568-10.952 38-33.496 38zm0-85.072c-7.712 13.12-17.504 33.008-17.504 47.072 0 20.424 7.256 22 17.504 22s17.504-1.576 17.504-22c0-14.096-9.792-33.968-17.504-47.072z"
                ></path>
            </svg>
        );
    }
}

Rain.propTypes = {
    size: number
}
Rain.defaultProps = {
    size: 500
}
export default Rain;
