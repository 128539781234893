import { useEffect, useState } from 'react';
//leaflet
import L from 'leaflet';

//pixi-overlay
import * as PIXI from 'pixi.js';
import 'leaflet-pixi-overlay';


import { useLeafletMap } from 'use-leaflet';

PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;
PIXI.utils.skipHello();
const PIXILoader = PIXI.Loader.shared;

// Overlay consists of markers and scaling for those markers to retain their consistent GPS coordinates,
// so it doesn't look like it's offset incorrectly.
const PixiOverlay = ({
    markers,
}) => {
    const [openedPopupData, setOpenedPopupData] = useState(null);
    const [openedTooltipData, setOpenedTooltipData] = useState(null);

    const [openedPopup, setOpenedPopup] = useState(null);
    const [openedTooltip, setOpenedTooltip] = useState(null);

    const [pixiOverlay, setPixiOverlay] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const map = useLeafletMap();

    // load sprites
    
    useEffect(() => {
        let loadingAny = false;
        // resets the loader 
        PIXILoader.reset()
        for (let marker of markers) {
            const resolvedMarkerId = marker.iconId || marker.iconColor;

            // skip if no ID or already cached
            if ((!marker.iconColor && !marker.iconId) || PIXILoader.resources[`marker_${resolvedMarkerId}`]) {
                PIXI.utils.clearTextureCache()
                continue;
            }
            loadingAny = true;
            PIXILoader.add(`marker_${resolvedMarkerId}`,
                marker.customIcon ? getEncodedIcon(marker.customIcon) : getDefaultIcon(marker.iconColor));
        }
        if (loaded && loadingAny) {
            setLoaded(false);
        }

        if (loadingAny) {
            PIXILoader.load(() => setLoaded(true));
        }
        else {
            setLoaded(true);
        }
    }, [markers]);

    // load pixi when map changes
    useEffect(() => {
        let pixiContainer = new PIXI.Container();

        let overlay = L.pixiOverlay(utils => {
            // redraw markers
            const scale = utils.getScale();
            utils.getContainer().children.forEach(child => child.scale.set(1 / scale));

            utils.getRenderer().render(utils.getContainer());
        }, pixiContainer);
        overlay.addTo(map);
        setPixiOverlay(overlay);

        setOpenedPopupData(null);
        setOpenedTooltipData(null);

        return () => pixiContainer.removeChildren();
    }, [map]);

    // draw markers first time in new container
    useEffect(() => {
        if (pixiOverlay && markers && loaded) {
            const utils = pixiOverlay.utils;
            let container = utils.getContainer();
            let renderer = utils.getRenderer();
            let project = utils.latLngToLayerPoint;
            let scale = utils.getScale();

            markers.forEach(marker => {
                const { id, iconColor, iconId, onClick, position, popup, tooltip, popupOpen } = marker;

                const resolvedIconId = iconId || iconColor;

                if (!PIXILoader.resources[`marker_${resolvedIconId}`] || !PIXILoader.resources[`marker_${resolvedIconId}`].texture) {
                    return;
                }

                const markerTexture = PIXILoader.resources[`marker_${resolvedIconId}`].texture;
                markerTexture.anchor = { x: 0.5, y: 0.5 };

                const markerSprite = PIXI.Sprite.from(markerTexture);
                markerSprite.anchor.set(0.5, 0.5);

                const markerCoords = project(position);
                markerSprite.x = markerCoords.x;
                markerSprite.y = markerCoords.y;

                markerSprite.scale.set(1 / scale);

                if (popupOpen) {
                    setOpenedPopupData({
                        id,
                        offset: [0, -35],
                        position,
                        content: popup,
                        onClick,
                    });
                }

                if (popup || onClick || tooltip) {
                    markerSprite.interactive = true;
                }

                if (popup || onClick) {
                    markerSprite.on('click', () => {
                        if (onClick) {
                            onClick(id);
                        }
                    });

                    markerSprite.defaultCursor = 'pointer';
                    markerSprite.buttonMode = true;
                }

                if (tooltip) {
                    markerSprite.on('mouseover', () => {
                        setOpenedTooltipData({
                            id,
                            offset: [0, -35],
                            position,
                            content: tooltip,
                        });
                    });

                    markerSprite.on('mouseout', () => {
                        setOpenedTooltipData(null);
                    });
                }

                container.addChild(markerSprite);
            });

            renderer.render(container);
        }

        return () => pixiOverlay && pixiOverlay.utils.getContainer().removeChildren();

    }, [pixiOverlay, markers, loaded]);

    // handle tooltip
    useEffect(() => {
        if (openedTooltip) {
            map.closePopup(openedTooltip);
        }

        if (openedTooltipData && (!openedPopup || !openedPopupData || openedPopupData.id !== openedTooltipData.id)) {
            setOpenedTooltip(openPopup(map, openedTooltipData));
        }

        // we don't want to reload when openedTooltip changes as we'd get a loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedTooltipData, openedPopupData, map]);

    // handle popup
    useEffect(() => {
        // close only if different popup
        if (openedPopup) {
            map.closePopup(openedPopup);
        }

        // open only if new popup
        if (openedPopupData) {
            setOpenedPopup(openPopup(map, openedPopupData, { autoClose: false }, true));
        }

        // we don't want to reload when whenedPopup changes as we'd get a loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedPopupData, map]);

    return null;
};

function openPopup(map, data, extraOptions = {}, isPopup) {
    const popup = L.popup({ offset: data.offset, ...extraOptions })
        .setLatLng(data.position)
        .setContent(data.content)
        .openOn(map);

    // TODO don't call onClick if opened a new one
    if (isPopup && data.onClick) {
        popup.on('remove', () => {
            data.onClick(null);
        });
    }

    return popup;
}

// Default marker that is displayed on the reconstruction map
function getDefaultIcon(color) {
    const svgIcon = `<svg stroke-width="10" xmlns="http://www.w3.org/2000/svg" fill="${color}" width="36" height="36" viewBox="0 0 100 100"> <circle cx="50" cy="50" r="10"  fill="${color}" /></svg>`;

    return getEncodedIcon(svgIcon);
}
// Custom Markers

function getEncodedIcon(svg) {
    const decoded = unescape(encodeURIComponent(svg));
    const base64 = btoa(decoded);
    return `data:image/svg+xml;base64,${base64}`;
}


export default PixiOverlay;
