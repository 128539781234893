import React from "react";
import { number } from 'prop-types';

function Menu(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            viewBox="0 0 512 512"
        >
            <path fill="#3857bc" d="M512 91H316l-45 44.5 45 45.5h196z"></path>
            <path fill="#3a6fd8" d="M120 91h196v90H120z"></path>
            <circle cx="45" cy="136" r="45" fill="#14cfff"></circle>
            <path fill="#3857bc" d="M512 211H316l-45 44.5 45 45.5h196z"></path>
            <path fill="#3a6fd8" d="M120 211h196v90H120z"></path>
            <circle cx="45" cy="256" r="45" fill="#14cfff"></circle>
            <path fill="#3857bc" d="M512 331H316l-45 44.5 45 45.5h196z"></path>
            <path fill="#3a6fd8" d="M120 331h196v90H120z"></path>
            <circle cx="45" cy="376" r="45" fill="#14cfff"></circle>
        </svg>
    );
}

Menu.propTypes = {
    size: number
}
Menu.defaultProps = {
    size: 50
}


export default Menu;
