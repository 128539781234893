import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { NavLink } from 'react-router-dom'
import AddDriver from '../../NavBar/Icons/AddDriver'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './DriverTable.css'
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
API.configure(awsmobile)

const DriverTable = ({ authToken }) => {
    const apiName = 'opsdrivertable';
    const path = '/drivertable';
    const myInit = {
        response:true
    };
    const [items, setItems] = useState(null)
    const [table, setTableHeaders] = useState(null)
    const [driverState, setDriverState] = useState([])
    const cellEdit =
        cellEditFactory({
            mode: 'dbclick',
            blurToSave: false,
            afterSaveCell(oldValue, newValue, row, column) {
                console.log(newValue)
                console.log(row['UserID'])
                let body = {
                    'UserID': row['UserID'],
                    'columnName': column['dataField'],
                    'newValue': newValue || null,
                }
                console.log({ body })
                API.post(apiName, path, {body})
                .then(res => console.log(body))
                .then(res => { window.location.reload() })

            },
            beforeSaveCell(oldValue, newValue, row, column, done) {
                setTimeout(() => {
                    if (window.confirm('Do you want to accept this change?')) {
                        done(); // contine to save the changes
                    } else {
                        done(false); // reject the changes
                    }
                }, 0);
                return { async: true };
            }

        })

    const selectRowProp = {
        mode: 'checkbox',
        bgColor: 'lightblue',
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setDriverState((rowData) => [...rowData, row['UserID']]);
            }
            else if (!isSelect) {
                setDriverState((rowData) => rowData.filter((x) => x !== row['UserID']))
            }
        }
    };

    const driverStatusFormatter = (cell, row) => row['DriverStatus'] == 'ACTIVE'
        ?
        <span style={{ color: 'green' }}>{cell}</span>
        :
        <span style={{ color: 'red' }}>{cell}</span>

    const columns = [
        {
            dataField: 'DriverStatus',
            text: 'Driver Status',
            sort: true,
            formatter: driverStatusFormatter,
            editor: {
                type: Type.SELECT,
                options: [
                    {
                        value: 'ACTIVE',
                        label: 'ACTIVE'
                    }, {
                        value: 'INACTIVE',
                        label: 'INACTIVE'
                    }
                ]
            }
        },
        {
            dataField: 'FirstName',
            text: 'First Name',
            sort: true
        },
        {
            dataField: 'LastName',
            text: 'Last Name',
            sort: true
        },
        {
            dataField: 'City',
            text: 'City',
            sort: true
        },
        {
            dataField: 'LocationState',
            text: 'State',
            sort: true
        },
        {
            dataField: 'Rig',
            text: 'Rig',
            sort: true,
        },
        {
            dataField: 'Email',
            text: 'Email',
            sort: true
        },
        {
            dataField: 'SSID',
            text: 'SSID',
            sort: true
        },

        {
            dataField: 'Password',
            text: 'SSID Password',
            sort: true
        },
        {
            dataField: 'UploadSpeed',
            text: 'Upload Speed',
            sort: true
        },
        {
            dataField: 'Year',
            text: 'Year',
            sort: true
        },
        {
            dataField: 'Make',
            text: 'Make',
            sort: true
        },
        {
            dataField: 'Model',
            text: 'Model',
            sort: true
        },
        {
            dataField: 'Trim',
            text: 'Trim',
            sort: true
        },
        {
            dataField: 'BaselineMeters',
            text: 'Baseline (meters)',
            sort: true
        },
        {
            dataField: 'KeyCore',
            text: 'Key Core',
            sort: true
        },
        {
            dataField: 'LiveStreamHotSpots',
            text: 'Live Stream Hot Spots',
            sort: true
        },
        {
            dataField: 'InstallNotes',
            text: 'Install Notes',
            sort: true
        },
        {
            dataField: 'UserID',
            text: 'User ID',
            sort: true
        },
    ];

    const sortOrder = [
        {
            dataField: 'LastName',
            order: 'asc'
        },
    ]


    function getTableAttributes(data) {
        let headers = [];
        data.forEach(item => {
            Object.keys(item).map((header) => {
                if (headers.indexOf(header) === -1) {
                    headers.push(header)
                }
            });
        })
        return headers
    }

    useEffect(() => {
        API.get(apiName, path, myInit)
            .then(res => {
                console.log(res)
                setItems(res.data['Items'])
                setTableHeaders(
                    getTableAttributes(res.data['Items'])
                )
            })
    }, [])

    useEffect(() => {
        setDriverState([])
    }, [])

    return (
        <div style={{
            margin: `3% 0  auto`,
        }}>
            <h1> Current Drivers</h1>
            <div style={{
                display: 'flex',
            }}>
                <button
                    className='driver-table-buttons'
                    style={{
                        color: 'white',
                        background: 'lightgreen'
                    }}
                >
                    <NavLink
                        to="/drivers/form">
                        <div style={{ display: 'flex' }}>
                            <AddDriver size={30} />
                            &nbsp; &nbsp;
                            <span style={{
                                fontSize: '25px',
                                textDecoration: 'none',
                                color: 'white'
                            }}
                            >
                                Add a Driver
                            </span>
                        </div>
                    </NavLink>
                </button>
            </div>
            {items ?
                <BootstrapTable
                    data={items.map((item) => item)}
                    columns={columns}
                    keyField='UserID'
                    selectRow={selectRowProp}
                    bootstrap4={true}
                    striped
                    hover
                    condensed
                    rowStyle={{ width: 200, height: 50, wordBreak: 'break-all' }}
                    bordered={false}
                    defaultSorted={sortOrder}
                    expandRow={true}
                    cellEdit={cellEdit}
                    className='driver-table-main'
                >
                </BootstrapTable>
                :
                null
            }
        </div>
    )
}

export default DriverTable;