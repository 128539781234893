import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { NavLink } from 'react-router-dom'
import './PartsDirectory.css'
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
API.configure(awsmobile)

const PartsDirectory = () => {
    const apiName = 'opsassetinventory';
    const path = '/assettable';
    const myInit = {
        response: true
    };
    const [items, setItems] = useState(null)
    const { SearchBar } = Search;
    const [columns, setColumnState] = useState(
        [
            {
                dataField: 'AssetID',
                text: 'Asset ID',
                sort: true,
            },
            {
                dataField: 'Description',
                text: 'Description',
                sort: true
            },
            {
                dataField: 'Location',
                text: 'Location',
                sort: true
            },
            {
                dataField: 'PartNumber',
                text: 'Part Number',
                sort: true,
            },
            {
                dataField: 'CreatedAt',
                text: 'CreatedAt',
                sort: true,
            }
        ]
    )
    const sortOrder = [
        {
            dataField: 'AssetID',
            order: 'asc'
        },
    ]
    
    const cellEdit =
        cellEditFactory({
            mode: 'dbclick',
            blurToSave: false,
            afterSaveCell(oldValue, newValue, row, column) {
                console.log(newValue)
                console.log(row['AssetID'])
                let body = {
                    'AssetID': row['AssetID'],
                    'columnName': column['dataField'],
                    'newValue': newValue || null,
                }
                console.log({ body })
                try {
                    API.post(apiName, path, { body })
                        .then(res => console.log(body))
                        .then(res => { window.location.reload() })
                }
                catch (err) {
                    console.log(err.message)
                }
            },
            beforeSaveCell(oldValue, newValue, row, column, done) {
                setTimeout(() => {
                    if (window.confirm('Do you want to accept this change?')) {
                        done(); // contine to save the changes
                    } else {
                        done(false); // reject the changes
                    }
                }, 0);
                return { async: true };
            }
        })

    useEffect(() => {
        API.get(apiName, path, myInit)
            .then(res => {
                setItems(res.data['Items'])
                const mainRows = ['AssetID', 'Description', 'Location', 'PartNumber', 'URL', 'CreatedAt', 'LastModified']
                const uneditableColumns = ['AssetID', 'CreatedAt']
                let attributeArr = []
                mainRows.map(header => {
                    let editableColumnAttributes = {
                        dataField: header,
                        text: header,
                        sort: true
                    }
                    let uneditableColumnAttributes = {
                        dataField: header,
                        text: header,
                        sort: true,
                        editable: false
                    }
                    if (uneditableColumns.indexOf(header) !== -1) {
                        attributeArr.push({ ...uneditableColumnAttributes })
                    }
                    else if (header === 'URL') {
                        attributeArr.push({ ...uneditableColumnAttributes, formatter: (cell, row) => <a style={{ textDecoration: 'none' }} href={cell}> {cell} </a> })
                    }
                    else if (header === 'LastModified') {
                        attributeArr.push({
                            ...uneditableColumnAttributes,
                            formatter: (cell, row, rowIndex, extraData) => (
                                <div>
                                    {cell ? new Date(cell).toString().split('(')[0] : null}
                                </div>
                            ),
                        })
                    }
                    else if (uneditableColumns.indexOf(header) === -1) {
                        attributeArr.push({ ...editableColumnAttributes })
                    }
                })
                setColumnState(attributeArr)
            })
    }, [])

    return (
        <div style={{
            margin: `3% 0  auto`,
        }}>
            <h1> Current Assets</h1>
            <div style={{
                display: 'flex',
            }}>
                <button
                    className='driver-table-buttons'
                >
                    <NavLink
                        to="/parts/partform">
                        <div style={{ display: 'flex' }}>
                            <span style={{
                                fontSize: '20px',
                            }}
                            >
                                Add new part
                            </span>
                        </div>
                    </NavLink>
                </button>
            </div>
            {items ?
                <ToolkitProvider
                    keyField='AssetID'
                    data={items.map((item) => item)}
                    columns={columns}
                    search
                    columnToggle
                >
                    {
                        props =>
                            <div>
                                <SearchBar
                                    style={{ width: '200px' }}
                                    {...props.searchProps}
                                />
                                <BootstrapTable
                                    data={items.map((item) => item)}
                                    columns={columns}
                                    keyField='AssetID'
                                    bootstrap4={true}
                                    striped
                                    hover
                                    condensed
                                    rowStyle={{ width: 200, height: 50, wordBreak: 'break-all' }}
                                    bordered={false}
                                    defaultSorted={sortOrder}
                                    expandRow={true}
                                    cellEdit={cellEdit}
                                    className='driver-table-main'
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                />
                            </div>
                    }
                </ToolkitProvider>
                :
                null
            }
        </div>
    )
}
export default PartsDirectory;