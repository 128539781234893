// List of Current Drivers, using this in the interim.
// This will change once we have a backend set up and we can just query for this information.

const currentDrivers = {
    'gildataylor@compoundops.com': {
        city: 'Phoenix',
        rig: ['agx-19'],
        userRig: 'agx-19'
    },
    'abiliosilva@compoundops.com': {
        city: 'Houston',
        rig: ['agx-20'],
        userRig: 'agx-20'
    },
    'michaelocampo@compoundops.com': {
        city: 'San Francisco',
        rig: [
            'agx-21',
            'agx-18',
            'agx-13'
        ],
        userRig: 'agx-13'
    },
    'preston@compoundops.com': {
        city: 'San Francisco',
        rig: [
            'agx-21',
            'agx-13',
        ],
        userRig: 'agx-13'
    },
    'lynn@compoundops.com':{
        city:'Detroit',
        rig:[
            'agx-27'
        ],
        userRig:'agx-27'
    },
    'jackfontaine@compoundops.com':{
        city:'Las Vegas',
        rig:[
            'agx-21'
        ],
        userRig:'agx-21'
    },
    'aminaford@compoundops.com':{
        city:'Los Angeles',
        rig:[
            'agx-21'
        ],
        userRig:'agx-21'
    },
    'test@compoundops.com': {
        city: 'San Francisco',
        rig: [
            'agx-13',
        ],
        userRig: 'agx-13'
    },    
}

const allDrivers = {
    city: 'San Francisco',
    rig: [

        'agx-10',
        'agx-13',
        'agx-21',
        'agx-20',
        'agx-19',
        'agx-27'
    ],
    userRig: 'agx-13'
}

module.exports = {
    currentDrivers,
    allDrivers
}