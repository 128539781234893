import { Map, Marker, Popup, TileLayer, Polyline, Tooltip } from 'react-leaflet';
import React, { useState, useEffect, useRef } from 'react';
import * as moment from 'moment';
import { rgbColorsArray, cityCoordinates, availableCities, availableStates } from '../../../Utilities/utilities'

import './Telemetry.css'
import "rc-calendar/assets/index.css"
import 'rc-time-picker/assets/index.css';

import { API } from 'aws-amplify';

const Telemetry = ({ AWS_ACCESS_KEY_ID, AWS_SECRET_KEY_ID }) => {
    const mapRef = useRef();

    // Tile server and telemetry constants
    const TILESERVER_HOST = 'surveyor-tiles.s3-us-west-2.amazonaws.com';
    const [telemetry, setTelemetry] = useState([])
    const apiName = 'telemetry';
    const path = '/querytelemetry';
    const myInit = {

    };

    // Initial location and lat/long coordinates
    const [location, setLocation] = useState('San Francisco')
    const [position, setPosition] = useState(
        cityCoordinates(location)
    )

    const [nextCity, setNextCity] = useState('')
    const [availableRigs, setAvailableRigs] = useState({
        rigs: null
    })
    // Live map toggler and default state for time differential between current time and filter
    const [liveMapsToggle, setLiveMapsToggle] = useState(false)
    const [timeDifferentialInMinutes, setTimeDifferentialInMinutes] = useState(5)
    // ------------------------ HELPER FUNCTIONS  ------------------------//

    // Displays current city 
    const currentCityCheck = (city) => {
        city = location
        if (city !== nextCity)
            return city
    }

    function polyLineConstructor(rig, position, state, lat, lon, time) {
        return (
            <Polyline
                color={colorGenerator(rig)}
                positions={position}
                weight={10}
                opacity={0.5}
            >
                <Tooltip>
                    <ul>
                        <li><strong>rig:</strong> {rig}</li>
                        <li><strong>state: </strong>{state}</li>
                        <li><strong>time:</strong> {timestampReformatter(time)}</li>
                        <li><strong>lat: </strong>{lat}</li>
                        <li><strong>long:</strong> {lon}</li>
                    </ul>
                </Tooltip>
            </Polyline>
        )
    }


    function generateListOfAvailableCities() {
        let cities = [];
        availableCities.list.forEach(city => {
            cities.push(<option value={city}>{city}, {availableStates[city]}</option>)
        })
        return cities.map(city => city)
    }
    function colorGenerator(rig) {
        if (availableRigs.rigs === null) return 'black'
        else return rgbColorsArray(availableRigs.rigs.indexOf(rig), availableRigs.rigs.length)

    }
    function telemetryMessageColor(message) {
        if (message.includes('SUCCEEDED')) return 'green'
        else if (message.includes('FAILED')) return 'red'
    }

    // Dynamically converts timestamp to utc and then calibrates for local timezone offset
    function timestampReformatter(timestamp) {
        // gets the timezone offset by locale 
        let offset = new Date().getTimezoneOffset()
        // takes the timestamp string and splits it to convert to 24 hour format
        let timeOfTimestamp = timestamp.split(' ')
        let conversionTo24HrFormat = moment(`${timeOfTimestamp[1]} ${timeOfTimestamp[2]}`, ["h:mm A"]).format("HH:mm");
        let century = 20;
        timestamp = moment.utc(`${century}${timestamp.slice(0, 2)}-${timestamp.slice(2, 4)}-${timestamp.slice(4, 6)}T${conversionTo24HrFormat}+00:00`).utcOffset(-offset).format('MM-DD-YYYY hh:mm:ss A')
        return `${timestamp.slice(11,)} ${timestamp.slice(0, 2)}-${timestamp.slice(3, 5)}-${timestamp.slice(6, 10)}`
    }
    // Checks to see which cars are on the the map with either agx on/off
    function checkLiveMapSinceRecentTimeInput(time) {
        let milliseconds = 1000
        let minutes = 60
        return Math.abs(Math.round(((new Date().getTime() - new Date(Date.parse(timestampReformatter(time))).getTime()) / milliseconds / minutes))) <= timeDifferentialInMinutes
    }
    // Changes current city state
    function handleChange(evt) {
        console.log('Location set to: ' + evt.target.value)
        setNextCity(evt.target.value)
        setLocation(evt.target.value)
        setPosition(cityCoordinates(evt.target.value))
        currentCityCheck(location)
    }

    function handleLiveViewToggle() {
        setLiveMapsToggle(!liveMapsToggle)
    }

    // Changes current city
    async function handleSubmit(evt) {
        console.log('Location Submitted: ' + evt.target.value)
        evt.persist()
        evt.preventDefault();
        try {
            setNextCity(' ')
            currentCityCheck()
        } catch (error) {
            console.log("Error ", error)
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await API.get(apiName, path, myInit)
                console.log('this is the query result ', result)
                setTelemetry(result)
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        // Contiuous update of which reconstructions should be loaded based off state change then updates array of available rigs
        async function loadRigs() {
            if (telemetry) {
                let rigs = [];
                telemetry && telemetry.map(d => {
                    rigs.push(d['rig_name'])
                })
                rigs = [...new Set(rigs)]
                setAvailableRigs({
                    rigs: rigs
                })
            }
        }
        loadRigs()
    }, [telemetry])


    // Stops animation before map finishes loading
    useEffect(() => {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;
        console.log(map)
        setTimeout(() => {
            map.invalidateSize()
        }, 1000)
    }, [mapRef])

    return (
        <div style={{
            margin: '0px auto',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor:'#474747',
            color:'white'
        }}>
            {/* Displays the current city */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor:'#474747',
                marginTop:'5rem',
                marginLeft:'1rem'
            }}>
                <table>
                    <tbody>
                        <tr style={{
                            marginLeft:'1rem'
                        }}>
                            <td className='row-spacers'>
                                <h3 className='listbox-headers'> Cities</h3>
                                <hr className='listbox-row-divider'></hr>
                                <form onSubmit={handleSubmit}>
                                    <td>
                                        <select onChange={handleChange} id="location">
                                            {generateListOfAvailableCities()}
                                        </select>
                                    </td>

                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
                height: '100%',
                borderLeft: 'solid 3px black',
                paddingLeft: '20px'
            }}>
                {/* Map that gets displayed from surveyor's tileserver */}
                {(nextCity === ' ') ? <h2 > {currentCityCheck().toUpperCase()}</h2> : <h2> {(location == currentCityCheck()) ? location : 'Changing to: ' + nextCity}</h2>}
                <hr style={{
                    width: '70vw',
                    borderColor: 'black'
                }}></hr>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: 'solid 5px lightgrey',
                    backgroundColor: 'darkgrey'
                }}>
                    <h3 style={{
                        display: 'inline',
                        marginRight: '80%',
                        color: 'white',
                    }}>Telemetry Logs</h3>
                    {telemetry.length
                        ?
                        <div className='vidar-table'
                            style={{
                                overflow: 'scroll',
                                height: '600px',
                                borderRadius: '3px'
                            }}>
                            <table style={{
                                border: 'solid 1px darkgrey'
                            }}
                            >
                                <tbody className='vidar-messages'
                                    style={{
                                        border: '1px solid gray'
                                    }}>
                                    <tr style={{
                                        color: 'white'
                                    }}>
                                        <th>rig</th>
                                        <th>message</th>
                                        <th>severity</th>
                                        <th>timestamp</th>
                                    </tr>

                                    {telemetry.slice(0).reverse().map((d, idx) => {
                                        if (d.vidar_message !== undefined) {
                                            return (
                                                <tr key={idx}
                                                    style={(idx % 2 === 0)
                                                        ?
                                                        {
                                                            backgroundColor: 'lightgrey',
                                                            color: 'black'
                                                        }
                                                        :
                                                        {
                                                            backgroundColor: 'white',
                                                            color: 'black'
                                                        }}>

                                                    <td>{d['rig_name']}</td>
                                                    <td
                                                        style={{
                                                            color: telemetryMessageColor(d['vidar_message']['message'])
                                                        }}>
                                                        {d['vidar_message']['message']}
                                                    </td>
                                                    <td>{d['vidar_message']['severity']}</td>
                                                    <td>{timestampReformatter(d['timestamp'])}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div>
                            <h3 style={{
                                color: 'white'
                            }}>loading...</h3>
                            <div class="loadingio-spinner-spinner-o5154xxwmd"><div class="ldio-kb1qnoureh9">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div></div></div>
                    }
                </div>
                <div style={{
                    width: '50%',
                    textAlign: 'left'
                }}>
                    <input onChange={(evt) => setTimeDifferentialInMinutes(evt.target.value)} />
                    <button
                        className='telemetry-buttons'
                        onClick={handleLiveViewToggle}> Live View {(liveMapsToggle === true) ? <span style={{ color: '#52c1b3' }}>on</span> : <span style={{ color: 'red' }}>off</span>}
                    </button>
                </div>
                <div style={{
                    width: '50%',
                    textAlign: 'left'
                }}>
                    <h6 style={{ color: 'lightgrey' }}>change minutes from current time (default 5 minutes)</h6>
                </div>
                <Map
                    center={position}
                    zoom={14}
                    minZoom={9}
                    preferCanvas={true}
                    ref={mapRef}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url={`https://${TILESERVER_HOST}/public/tiles/{z}/{x}/{y}.png`}
                        noWrap={true}
                    />
                    <Marker
                        position={position}>
                        <Popup>
                            {location}
                        </Popup>
                    </Marker>
                    {(liveMapsToggle === false)
                        ?
                        telemetry && telemetry.map((d) => {
                            if (d.hasOwnProperty('watchdog_message')
                                && d.watchdog_message.hasOwnProperty('gps')
                                && (d.watchdog_message.gps.hasOwnProperty('lat')
                                    && d.watchdog_message.gps.hasOwnProperty('long'))) {
                                return (
                                    polyLineConstructor(
                                        d['rig_name'],
                                        [
                                            [d.watchdog_message.gps.lat, d.watchdog_message.gps.long],
                                            [d.watchdog_message.gps.lat, d.watchdog_message.gps.long]
                                        ],
                                        d.watchdog_message.state,
                                        d.watchdog_message.gps.lat,
                                        d.watchdog_message.gps.long,
                                        d['watchdog_message']['gps']['timestamp']
                                    )
                                )
                            }
                        })
                        :
                        telemetry && telemetry.map((d) => {
                            // Checks liveview for any activity during driving. Currently set to either car=on && agx=on/off
                            if (checkLiveMapSinceRecentTimeInput(d['timestamp'])) {
                                if (d.hasOwnProperty('watchdog_message')
                                    && (d.watchdog_message.state === "CAR_ON_AGX_ON" || d.watchdog_message.state === "CAR_ON_AGX_OFF")
                                    && d.watchdog_message.hasOwnProperty('gps')
                                    && (d.watchdog_message.gps.hasOwnProperty('lat')
                                        && d.watchdog_message.gps.hasOwnProperty('long'))) {
                                    return (
                                        polyLineConstructor(
                                            d['rig_name'],
                                            [
                                                [d.watchdog_message.gps.lat, d.watchdog_message.gps.long],
                                                [d.watchdog_message.gps.lat, d.watchdog_message.gps.long]
                                            ],
                                            d.watchdog_message.state,
                                            d.watchdog_message.gps.lat,
                                            d.watchdog_message.gps.long,
                                            d['watchdog_message']['gps']['timestamp']
                                        )
                                    )
                                }
                                else {
                                    return null
                                }
                            }
                        })
                    }
                </Map>
            </div>
        </div>
    );
}
export default Telemetry;