import React, { Component } from "react";
import { number } from 'prop-types';

class PartlyCloudy extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 481.306 481.306"
                version="1.1"
                viewBox="0 0 481.306 481.306"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <circle cx="338.24" cy="231.733" r="131.08" fill="#FCB641"></circle>
                <g fill="#4D4D4D">
                    <path d="M338.24 370.813c-76.688 0-139.08-62.392-139.08-139.08s62.392-139.08 139.08-139.08 139.08 62.392 139.08 139.08c.008 76.688-62.384 139.08-139.08 139.08zm0-262.16c-67.864 0-123.08 55.216-123.08 123.08s55.216 123.08 123.08 123.08 123.08-55.208 123.08-123.08-55.208-123.08-123.08-123.08zM336 64.653c-4.416 0-8-3.584-8-8v-48c0-4.416 3.584-8 8-8s8 3.584 8 8v48c0 4.416-3.584 8-8 8zM439.36 97.957a7.978 7.978 0 01-5.656-2.344 7.991 7.991 0 010-11.312l33.944-33.944c3.128-3.128 8.184-3.128 11.312 0s3.128 8.184 0 11.312l-33.944 33.944a7.978 7.978 0 01-5.656 2.344zM208.968 121.621a7.978 7.978 0 01-5.656-2.344l-33.936-33.936c-3.128-3.128-3.128-8.184 0-11.312s8.184-3.128 11.312 0l33.936 33.936a7.991 7.991 0 010 11.312 7.978 7.978 0 01-5.656 2.344z"></path>
                </g>
                <path
                    fill="#92C7D3"
                    d="M420 360.653h-41.224c.72-7.888 1.2-9.632 1.208-14.256.12-41.248-27.408-77.904-67.696-86.784-22.96-5.056-44.88-1.624-63.016 8.16-22.12-32.928-58.784-55.296-100.792-57.864-5.4-.736-10.872-1.256-16.48-1.256-68.48 0-124 57.248-124 127.872 0 36.8 15.16 69.88 39.288 93.208 21.336 23.672 50.96 38.96 86.04 41.504 0 0 285.584 1.416 286.672 1.416 28.72 0 52-25.072 52-56s-23.28-56-52-56z"
                ></path>
                <path
                    fill="#4D4D4D"
                    d="M420 480.653c-1.096 0-275.048-1.352-286.704-1.416-36.68-2.648-68.288-17.904-91.944-44.152C14.824 409.469 0 374.317 0 336.525c0-74.92 59.216-135.872 132-135.872 5.368 0 10.952.424 17.576 1.336 40.008 2.424 77.936 23.08 102.288 55.608 19.12-8.56 40.464-10.576 62.16-5.792 43 9.48 74.104 49.272 73.976 94.624-.008 2.44-.128 4.16-.336 6.232H420c33.088 0 60 28.712 60 64s-26.912 63.992-60 63.992zm-286.056-16c3.584 0 284.072.696 286.064.696 24.248 0 43.992-21.88 43.992-48.352s-19.736-48.344-44-48.344h-49.984l.792-8.72c.272-2.936.504-5.048.696-6.768.288-2.608.48-4.184.488-6.792.112-37.872-25.72-71.08-61.416-78.944-20.344-4.488-40.232-1.92-57.488 7.384l-6.392 3.448-4.048-6.024c-21.616-32.176-56.12-51.984-94.648-54.336-6.48-.864-11.376-1.24-16-1.24-63.96-.008-116 53.768-116 119.864 0 33.416 13.088 65.176 36.856 88.152 21.216 23.52 49.112 39.976 81.056 39.976h.032z"
                ></path>
            </svg>
        );
    }
}

PartlyCloudy.propTypes = {
    size: number
}
PartlyCloudy.defaultProps = {
    size: 500
}
export default PartlyCloudy;
