import React, { Component } from "react";
import { number } from 'prop-types';

class Fog extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                width={size}
                height={size}
            >
                <path
                    fill="#a8b0bc"
                    d="M32 34h20c5.523 0 10-4.477 10-10s-4.477-10-10-10c-1.831 0-3.542.5-5.018 1.36.003-.121.018-.239.018-.36 0-7.18-5.82-13-13-13-6.238 0-11.445 4.396-12.705 10.258A7.956 7.956 0 0017 11a8 8 0 00-8 8c0 .341.028.674.07 1.004C9.046 20.003 9.023 20 9 20a7 7 0 00-4.899 12"
                ></path>
                <path
                    fill="#c1c8d1"
                    d="M20.5 27c12.274 0 22.734-4.901 26.747-11.773-.087.047-.18.083-.265.133.003-.121.018-.239.018-.36 0-7.18-5.82-13-13-13-6.238 0-11.445 4.396-12.705 10.258A7.956 7.956 0 0017 11a8 8 0 00-8 8c0 .341.028.674.07 1.004C9.046 20.003 9.023 20 9 20a6.99 6.99 0 00-5.904 3.249C7.911 25.599 13.945 27 20.5 27z"
                ></path>
                <g fill="#8892a0">
                    <path d="M50 41a3 3 0 01-3 3H23a3 3 0 01-3-3 3 3 0 00-3-3H5a3 3 0 110-6h24a3 3 0 013 3 3 3 0 003 3h12a3 3 0 013 3zM30 59a3 3 0 01-3 3H16a3 3 0 01-3-3 3 3 0 00-3-3H5a3 3 0 110-6h11a3 3 0 013 3 3 3 0 003 3h5a3 3 0 013 3zM62 59a3 3 0 01-3 3H48a3 3 0 01-3-3 3 3 0 00-3-3h-5a3 3 0 110-6h11a3 3 0 013 3 3 3 0 003 3h5a3 3 0 013 3z"></path>
                </g>
                <path d="M52 35H32v-2h20c4.962 0 9-4.038 9-9s-4.038-9-9-9c-1.578 0-3.14.423-4.515 1.224a1.001 1.001 0 01-1.503-.892L46 15c0-6.617-5.383-12-12-12-5.616 0-10.548 3.982-11.727 9.468a1 1 0 01-1.515.633A6.984 6.984 0 0017 12c-3.86 0-7 3.14-7 7 0 .298.026.59.062.878a1 1 0 01-.242.787 1.058 1.058 0 01-.858.334C5.691 21 3 23.691 3 27c0 1.626.64 3.148 1.801 4.286l-1.399 1.429A7.94 7.94 0 011 27c0-4.073 3.059-7.444 7-7.938V19c0-4.962 4.038-9 9-9 1.264 0 2.506.267 3.654.78C22.472 5.042 27.888 1 34 1c7.309 0 13.328 5.63 13.947 12.78A10.966 10.966 0 0152 13c6.065 0 11 4.935 11 11s-4.935 11-11 11z"></path>
                <path d="M47 45H23c-2.206 0-4-1.794-4-4 0-1.103-.897-2-2-2H5c-2.206 0-4-1.794-4-4s1.794-4 4-4h24c2.206 0 4 1.794 4 4 0 1.103.897 2 2 2h12c2.206 0 4 1.794 4 4s-1.794 4-4 4zM5 33c-1.103 0-2 .897-2 2s.897 2 2 2h12c2.206 0 4 1.794 4 4 0 1.103.897 2 2 2h24c1.103 0 2-.897 2-2s-.897-2-2-2H35c-2.206 0-4-1.794-4-4 0-1.103-.897-2-2-2zM27 63H16c-2.206 0-4-1.794-4-4 0-1.103-.897-2-2-2H5c-2.206 0-4-1.794-4-4s1.794-4 4-4h11c2.206 0 4 1.794 4 4 0 1.103.897 2 2 2h5c2.206 0 4 1.794 4 4s-1.794 4-4 4zM5 51c-1.103 0-2 .897-2 2s.897 2 2 2h5c2.206 0 4 1.794 4 4 0 1.103.897 2 2 2h11c1.103 0 2-.897 2-2s-.897-2-2-2h-5c-2.206 0-4-1.794-4-4 0-1.103-.897-2-2-2zM59 63H48c-2.206 0-4-1.794-4-4 0-1.103-.897-2-2-2h-5c-2.206 0-4-1.794-4-4s1.794-4 4-4h11c2.206 0 4 1.794 4 4 0 1.103.897 2 2 2h5c2.206 0 4 1.794 4 4s-1.794 4-4 4zM37 51c-1.103 0-2 .897-2 2s.897 2 2 2h5c2.206 0 4 1.794 4 4 0 1.103.897 2 2 2h11c1.103 0 2-.897 2-2s-.897-2-2-2h-5c-2.206 0-4-1.794-4-4 0-1.103-.897-2-2-2z"></path>
            </svg>
        );
    }
}

Fog.propTypes = {
    size: number
}
Fog.defaultProps = {
    size: 500
}
export default Fog;
