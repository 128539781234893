import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function DriverTraining() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li><a href='#structure-and-objectives'> Structure & Objectives</a></li>
                        <li><a href='#online-training'> Online Training</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#general-overview'> General overview</a></li>
                            <li><a href='#how-the-role'> How the role is important</a></li>
                            <li><a href='#set-up'> Set up and functionally use the driver app per the walkthrough</a></li>
                            <li><a href='#hardware'> Hardware & Installation Process</a></li>
                            <li><a href='#basic-scheduling'> Basic Scheduling and Payroll</a></li>
                            <li><a href='#overview-of-driver-shift'> Overview of a driver shift</a></li>
                        </ol>
                        <li><a href='#live-training'> Live Training</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#walk-through'> Walk through of the data collection platform</a></li>
                            <li><a href='#qa-demo'> Q&A + Demo of Driver App</a></li>
                            <li><a href='#driving-divided'> How Driving is divided amongst drivers</a></li>
                            <li><a href='#scheduling-walkthrough'> Scheduling Walkthrough + Rules Discussion</a></li>
                            <li><a href='#payroll-walkthrough'> Payroll Walkthrough and Q&A</a></li>
                            <li><a href='#pre-shift-responsibilities'> Pre-Shift Responsibilities</a></li>
                            <li><a href='#rules-while-driving'> Rules while driving</a></li>
                            <li><a href='#data-upload'> Data Upload</a></li>
                            <li><a href='#fleet-support'> Fleet Support</a></li>
                            <li><a href='#trust-and-safety'> Trust & Safety</a></li>
                        </ol>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }} > Driver Training</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        Most importantly, keeping our drivers safe and effective will be proper training. This document outlines the structure & objectives of the training and the steps involved in each. Training slides are here.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 className='sub-headers'> Purpose</h2>
                    <p className='document-content'>
                        This document serves as the current process for driver training and serves as the template for both training sessions. As this document is updated, training material and workflows should be updated to be an accurate reflection of this process doc.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='structure-and-objective' className='sub-headers'> Structure & Objectives:</h2>
                    <p className='document-content'>
                        The structure of the training workflow is one online introductory training and one live training, performed either in person or via video conferencing. The online training is to be completed within 7 days in order to be scheduled for live training. The overall objectives of these training modules are as follows:
                    </p>
                    <p className='document-content'>
                        Online training:
                        <br />
                        <ol className='ordered-list'>
                            <li>General overview of Compound Eye data collection</li>
                            <li>How the role is important</li>
                            <li>Set up and functionally use the driver app per the walkthrough</li>
                            <li>Hardware & Installation Process</li>
                            <li>Basic Scheduling and Payroll</li>
                            <li>Overview of a driver shift</li>
                        </ol>
                    </p>
                    <p className='document-content'>
                        Live Training:
                        <br />
                        <ol iclassName='ordered-list'>
                            <li>Walk through of the Data Collection Platform</li>
                            <li>Q&A + Demo of Driver App</li>
                            <li>How Driving is divided amongst drivers</li>
                            <li>Scheduling Walkthrough + Rules Discussion</li>
                            <li>Payroll Walkthrough and Q&A</li>
                            <li>Pre-Shift Responsibilities</li>
                            <li>Rules while driving</li>
                            <li>Data Upload</li>
                            <li>Fleet Support</li>
                            <li>Trust & Safety</li>
                        </ol>
                        There are several purposes to online training in addition to the live training. It allows the drivers to prepare for next steps, form questions and gives them an initial understanding of the role so as to not become overloaded or confused during the live training. It is also to weed out any trainees that would not be able to perform the duties required for data collection. Whereas the live training is to build upon the foundational knowledge in the online training and prepare drivers for the road.
                    </p>
                    <hr className='horizontal-row' />

                    <h2 id='online-training' className='sub-headers'>Online Training:</h2>
                    <p className='document-content'>
                        <br />
                        Once the initial verification is completed by the driver and have been onboarded to the required services, the new driver can be added to the online training platform and invited to begin the training. Within each of our initial training objectives the training drills down into several topics:
                    <ol className='ordered-list'>
                            <li id='general-overview' className='list-bolders'>General overview of Compound Eye data collection</li>
                            <ol type='a'>
                                <li>What is Compound Eye?</li>
                                <li>What we made</li>
                                <li>Who we are</li>
                                <li>Next steps and data collection</li>
                            </ol>
                            <li id='how-the-role' className='list-bolders'>How the role is important</li>
                            <ol type='a'>
                                <li>What is Compound Eye?</li>
                                <li>What we made</li>
                                <li>Who we are</li>
                                <li>Next steps and data collection</li>
                            </ol>
                            <li id='set-up' className='list-bolders'>Set up and functionally use the driver app per the walkthrough</li>
                            <ol type='a'>
                                <li>A guide for this step is found here</li>
                            </ol>
                            <li id='hardware' className='list-bolders'>Hardware & Installation Process</li>
                            <ol type='a'>
                                <li>What is ViDAR</li>
                                <li>Our Hardware</li>
                                <li>What we do to your car</li>
                                <li>When and how we do installations</li>
                                <li>Rules regarding the hardware</li>
                                <li>Common issues</li>
                            </ol>
                            <li id='basic-scheduling' className='list-bolders'>Basic Scheduling and Payroll</li>
                            <ol type='a'>
                                <li>Setting up your scheduling account</li>
                                <li>Scheduling structure</li>
                                <li>Rules of Scheduling</li>
                                <li>Payroll System walkthrough</li>
                                <li>Payroll Schedule</li>
                            </ol>
                            <li id='overview' className='list-bolders'>Overview of a driver shift</li>
                            <ol type='a'>
                                <li>Preshift</li>
                                <li>Data Collection</li>
                                <li>Data Upload</li>
                            </ol>
                        </ol>
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='live-training' className='sub-headers'>Live Training:</h2>
                    <p className='document-content'>
                        <br />
                        Live training is the most important piece of the onboarding process as far as ensuring the success of new drivers. Building upon and expanding basic knowledge gained from the online training, reinforcing ideas and coaching where necessary. The script of talking points for each topic of live training can be found here.
                        <ol className='ordered-list'>
                            <li id='walk-through' className='list-bolders'>Walk through of the data collection platform</li>
                            <ol type='a'>
                                <li>What our data looks like</li>
                                <li>How the rig works</li>
                                <li>How we collect data </li>
                                <li>Basic pipeline overview</li>
                            </ol>

                            <li id='qa-demo' className='list-bolders'>Q&A + Demo of Driver App</li>
                            <ol type='a'>
                                <li>Setting up the driver app</li>
                                <li>Overview of the map and tracks</li>
                                <li>Recording Tracks </li>
                                <li>Getting Tracks for good and current data</li>
                                <li>Troubleshooting the app</li>
                                <li>Q&A</li>
                            </ol>
                            <li id='driving-divided' className='list-bolders'>How Driving is divided amongst drivers</li>
                            <ol type='a'>
                                <li>Fairness</li>
                                <li>Least Commute</li>
                                <li>Equal Lengths</li>
                            </ol>
                            <li id='scheduling-walkthrough' className='list-bolders'>Scheduling Walkthrough + Rules Discussion</li>
                            <ol type='a'>
                                <li>Rundown of WhenIWork</li>
                                <li>What an openshift is</li>
                                <li>How shifts are selected</li>
                                <li>Trading Shifts</li>
                                <li>Clocking in and out using Cell Phone</li>
                                <li>Required minimums and attendance</li>
                                <li>Scheduling Deadlines</li>
                            </ol>
                            <li id='payroll-walkthrough' className='list-bolders'>Payroll Walkthrough and Q&A</li>
                            <ol type='a'>
                                <li>Rundown of features and where to find things</li>
                                <li>Detailed conversation about payment schedule and important dates</li>
                                <li>Benefits (If any)</li>
                                <li>Direct Deposit </li>
                            </ol>
                            <li id='pre-shift-responsibilities' className='list-bolders'>Pre-Shift Responsibilities</li>
                            <ol type='a'>
                                <li>Read through of pre-shift checklist</li>
                                <li>Required + Recommended Supplies</li>
                                <li>Hardware and software check</li>
                                <li>Refreshing the app tracks (again) </li>
                                <li>Introduction to additional COVID-19 guidelines </li>
                            </ol>
                            <li id='rules-while-driving' className='list-bolders'>Rules while driving</li>
                            <ol type='a'>
                                <li>Responsibility and Respect for the hardware</li>
                                <li>Maintaining a safe environment</li>
                                <li>Obeying traffic laws</li>
                                <li>What to do if you get in an accident or another emergency </li>
                                <li>Using the app while on shift + review of state laws (Don’t drive with your phone in your hand)</li>
                                <li>Monitoring the ViDAR system</li>
                                <li>Finish running through COVID-19 guidelines</li>
                            </ol>
                            <li id='data-upload' className='list-bolders'>Data Upload</li>
                            <ol type='a'>
                                <li>Returning home</li>
                                <li>Verifying the system is connected</li>
                                <li>Verifying upload</li>
                            </ol>
                            <li id='fleet-support' className='list-bolders'>Fleet Support</li>
                            <ol type='a'>
                                <li>What is Fleet Support?</li>
                                <li>Why do you contact Fleet Support?</li>
                                <li>Hours of Operation</li>
                                <li>Emergencies outside hours</li>
                                <li>Placard with Fleet Support Number</li>
                            </ol>
                            <li id='trust-and-safety' className='list-bolders'>Trust & Safety</li>
                            <ol type='a'>
                                <li>The Importance of driving with a clear head</li>
                                <li>Our Hardware, your responsibility</li>
                                <li>Accidents</li>
                                <li>Assault</li>
                                <li>Illness</li>
                                <li>Calling in sick</li>
                                <li>Hardware Failures</li>
                                <li>Situational Awareness</li>
                                <li>Leaving your vehicle unattended</li>
                            </ol>
                        </ol>
                        <hr className='horizontal-row' />
                        <strong>As a general rule, training should be taken seriously by all trainees. Attendance is mandatory once signed up for a training session. Any first time call into a training session 24 hours in advance can be rescheduled, otherwise attendance issues are handled on a case-by-case basis. Drivers are expected to complete all training successfully. If a trainee demonstrates low performance or effort within the training session it is at the sole discretion of the trainer to dismiss the trainee, if deemed warranted. Once the live training has been completed the trainer will begin scheduling the new drivers for installation and vehicle inspections.</strong>
                    </p>
                </div>
            </div>
        </div>
    )
}


export default DriverTraining;
