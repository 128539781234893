const rigs = ['agx-21', 'agx-20', 'agx-19', 'agx-18', 'agx-13', 'agx-10', 'agx-27']
const rigsCity = {
    'San Francisco': ['agx-21', 'agx-18', 'agx-13'],
    'Houston' : ['agx-20'],
    'Phoenix' : ['agx-19'],
    'Detroit' : ['agx-27'],
    'Las Vegas': ['agx-21'],
    'Los Angeles':['agx-21']
}

let rigColors = [
    `rgb(255, 0, 0)`,
    `rgb(255, 6, 0)`,
    `rgb(255, 12, 0)`,
    `rgb(255, 18, 0)`,
    `rgb(255, 24, 0)`,
    `rgb(255, 30, 0)`,
    `rgb(255, 36, 0)`,
    `rgb(255, 42, 0)`,
    `rgb(255, 48, 0)`,
    `rgb(255, 54, 0)`,
    `rgb(255, 60, 0)`,
    `rgb(255, 66, 0)`,
    `rgb(255, 72, 0)`,
    `rgb(255, 78, 0)`,
    `rgb(255, 84, 0)`,
    `rgb(255, 90, 0)`,
    `rgb(255, 96, 0)`,
    `rgb(255, 102, 0)`,
    `rgb(255, 108, 0)`,
    `rgb(255, 114, 0)`,
    `rgb(255, 120, 0)`,
    `rgb(255, 126, 0)`,
    `rgb(255, 131, 0)`,
    `rgb(255, 137, 0)`,
    `rgb(255, 143, 0)`,
    `rgb(255, 149, 0)`,
    `rgb(255, 155, 0)`,
    `rgb(255, 161, 0)`,
    `rgb(255, 167, 0)`,
    `rgb(255, 173, 0)`,
    `rgb(255, 179, 0)`,
    `rgb(255, 185, 0)`,
    `rgb(255, 191, 0)`,
    `rgb(255, 197, 0)`,
    `rgb(255, 203, 0)`,
    `rgb(255, 209, 0)`,
    `rgb(255, 215, 0)`,
    `rgb(255, 221, 0)`,
    `rgb(255, 227, 0)`,
    `rgb(255, 233, 0)`,
    `rgb(255, 239, 0)`,
    `rgb(255, 245, 0)`,
    `rgb(255, 251, 0)`,
    `rgb(253, 255, 0)`,
    `rgb(247, 255, 0)`,
    `rgb(241, 255, 0)`,
    `rgb(235, 255, 0)`,
    `rgb(229, 255, 0)`,
    `rgb(223, 255, 0)`,
    `rgb(217, 255, 0)`,
    `rgb(211, 255, 0)`,
    `rgb(205, 255, 0)`,
    `rgb(199, 255, 0)`,
    `rgb(193, 255, 0)`,
    `rgb(187, 255, 0)`,
    `rgb(181, 255, 0)`,
    `rgb(175, 255, 0)`,
    `rgb(169, 255, 0)`,
    `rgb(163, 255, 0)`,
    `rgb(157, 255, 0)`,
    `rgb(151, 255, 0)`,
    `rgb(145, 255, 0)`,
    `rgb(139, 255, 0)`,
    `rgb(133, 255, 0)`,
    `rgb(128, 255, 0)`,
    `rgb(122, 255, 0)`,
    `rgb(116, 255, 0)`,
    `rgb(110, 255, 0)`,
    `rgb(104, 255, 0)`,
    `rgb(98, 255, 0)`,
    `rgb(92, 255, 0)`,
    `rgb(86, 255, 0)`,
    `rgb(80, 255, 0)`,
    `rgb(74, 255, 0)`,
    `rgb(68, 255, 0)`,
    `rgb(62, 255, 0)`,
    `rgb(56, 255, 0)`,
    `rgb(50, 255, 0)`,
    `rgb(44, 255, 0)`,
    `rgb(38, 255, 0)`,
    `rgb(32, 255, 0)`,
    `rgb(26, 255, 0)`,
    `rgb(20, 255, 0)`,
    `rgb(14, 255, 0)`,
    `rgb(8, 255, 0)`,
    `rgb(2, 255, 0)`,
    `rgb(0, 255, 4)`,
    `rgb(0, 255, 10)`,
    `rgb(0, 255, 16)`,
    `rgb(0, 255, 22)`,
    `rgb(0, 255, 28)`,
    `rgb(0, 255, 34)`,
    `rgb(0, 255, 40)`,
    `rgb(0, 255, 46)`,
    `rgb(0, 255, 52)`,
    `rgb(0, 255, 58)`,
    `rgb(0, 255, 64)`,
    `rgb(0, 255, 70)`,
    `rgb(0, 255, 76)`,
    `rgb(0, 255, 82)`,
    `rgb(0, 255, 88)`,
    `rgb(0, 255, 94)`,
    `rgb(0, 255, 100)`,
    `rgb(0, 255, 106)`,
    `rgb(0, 255, 112)`,
    `rgb(0, 255, 118)`,
    `rgb(0, 255, 124)`,
    `rgb(0, 255, 129)`,
    `rgb(0, 255, 135)`,
    `rgb(0, 255, 141)`,
    `rgb(0, 255, 147)`,
    `rgb(0, 255, 153)`,
    `rgb(0, 255, 159)`,
    `rgb(0, 255, 165)`,
    `rgb(0, 255, 171)`,
    `rgb(0, 255, 177)`,
    `rgb(0, 255, 183)`,
    `rgb(0, 255, 189)`,
    `rgb(0, 255, 195)`,
    `rgb(0, 255, 201)`,
    `rgb(0, 255, 207)`,
    `rgb(0, 255, 213)`,
    `rgb(0, 255, 219)`,
    `rgb(0, 255, 225)`,
    `rgb(0, 255, 231)`,
    `rgb(0, 255, 237)`,
    `rgb(0, 255, 243)`,
    `rgb(0, 255, 249)`,
    `rgb(0, 255, 255)`,
    `rgb(0, 249, 255)`,
    `rgb(0, 243, 255)`,
    `rgb(0, 237, 255)`,
    `rgb(0, 231, 255)`,
    `rgb(0, 225, 255)`,
    `rgb(0, 219, 255)`,
    `rgb(0, 213, 255)`,
    `rgb(0, 207, 255)`,
    `rgb(0, 201, 255)`,
    `rgb(0, 195, 255)`,
    `rgb(0, 189, 255)`,
    `rgb(0, 183, 255)`,
    `rgb(0, 177, 255)`,
    `rgb(0, 171, 255)`,
    `rgb(0, 165, 255)`,
    `rgb(0, 159, 255)`,
    `rgb(0, 153, 255)`,
    `rgb(0, 147, 255)`,
    `rgb(0, 141, 255)`,
    `rgb(0, 135, 255)`,
    `rgb(0, 129, 255)`,
    `rgb(0, 124, 255)`,
    `rgb(0, 118, 255)`,
    `rgb(0, 112, 255)`,
    `rgb(0, 106, 255)`,
    `rgb(0, 100, 255)`,
    `rgb(0, 94, 255)`,
    `rgb(0, 88, 255)`,
    `rgb(0, 82, 255)`,
    `rgb(0, 76, 255)`,
    `rgb(0, 70, 255)`,
    `rgb(0, 64, 255)`,
    `rgb(0, 58, 255)`,
    `rgb(0, 52, 255)`,
    `rgb(0, 46, 255)`,
    `rgb(0, 40, 255)`,
    `rgb(0, 34, 255)`,
    `rgb(0, 28, 255)`,
    `rgb(0, 22, 255)`,
    `rgb(0, 16, 255)`,
    `rgb(0, 10, 255)`,
    `rgb(0, 4, 255)`,
    `rgb(2, 0, 255)`,
    `rgb(8, 0, 255)`,
    `rgb(14, 0, 255)`,
    `rgb(20, 0, 255)`,
    `rgb(26, 0, 255)`,
    `rgb(32, 0, 255)`,
    `rgb(38, 0, 255)`,
    `rgb(44, 0, 255)`,
    `rgb(50, 0, 255)`,
    `rgb(56, 0, 255)`,
    `rgb(62, 0, 255)`,
    `rgb(68, 0, 255)`,
    `rgb(74, 0, 255)`,
    `rgb(80, 0, 255)`,
    `rgb(86, 0, 255)`,
    `rgb(92, 0, 255)`,
    `rgb(98, 0, 255)`,
    `rgb(104, 0, 255)`,
    `rgb(110, 0, 255)`,
    `rgb(116, 0, 255)`,
    `rgb(122, 0, 255)`,
    `rgb(128, 0, 255)`,
    `rgb(133, 0, 255)`,
    `rgb(139, 0, 255)`,
    `rgb(145, 0, 255)`,
    `rgb(151, 0, 255)`,
    `rgb(157, 0, 255)`,
    `rgb(163, 0, 255)`,
    `rgb(169, 0, 255)`,
    `rgb(175, 0, 255)`,
    `rgb(181, 0, 255)`,
    `rgb(187, 0, 255)`,
    `rgb(193, 0, 255)`,
    `rgb(199, 0, 255)`,
    `rgb(205, 0, 255)`,
    `rgb(211, 0, 255)`,
    `rgb(217, 0, 255)`,
    `rgb(223, 0, 255)`,
    `rgb(229, 0, 255)`,
    `rgb(235, 0, 255)`,
    `rgb(241, 0, 255)`,
    `rgb(247, 0, 255)`,
    `rgb(253, 0, 255)`,
    `rgb(255, 0, 251)`,
    `rgb(255, 0, 245)`,
    `rgb(255, 0, 239)`,
    `rgb(255, 0, 233)`,
    `rgb(255, 0, 227)`,
    `rgb(255, 0, 221)`,
    `rgb(255, 0, 215)`,
    `rgb(255, 0, 209)`,
    `rgb(255, 0, 203)`,
    `rgb(255, 0, 197)`,
    `rgb(255, 0, 191)`,
    `rgb(255, 0, 185)`,
    `rgb(255, 0, 179)`,
    `rgb(255, 0, 173)`,
    `rgb(255, 0, 167)`,
    `rgb(255, 0, 161)`,
    `rgb(255, 0, 155)`,
    `rgb(255, 0, 149)`,
    `rgb(255, 0, 143)`,
    `rgb(255, 0, 137)`,
    `rgb(255, 0, 131)`,
    `rgb(255, 0, 126)`,
    `rgb(255, 0, 120)`,
    `rgb(255, 0, 114)`,
    `rgb(255, 0, 108)`,
    `rgb(255, 0, 102)`,
    `rgb(255, 0, 96)`,
    `rgb(255, 0, 90)`,
    `rgb(255, 0, 84)`,
    `rgb(255, 0, 78)`,
    `rgb(255, 0, 72)`,
    `rgb(255, 0, 66)`,
    `rgb(255, 0, 60)`,
    `rgb(255, 0, 54)`,
    `rgb(255, 0, 48)`,
    `rgb(255, 0, 42)`,
    `rgb(255, 0, 36)`,
    `rgb(255, 0, 30)`,
    `rgb(255, 0, 24)`,
    `rgb(255, 0, 18)`,
    `rgb(255, 0, 12)`,
    `rgb(255, 0, 6)`
]
const rigColor = (index, lengthOfInput) => {
    return rigColors[Math.floor(index * rigColors.length / lengthOfInput)]
}


module.exports = {
    rigs,
    rigsCity,
    rigColors,
    rigColor
}