/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:47afc47e-2ff6-41ca-b616-5458b13379a2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_PPX9P1Omy",
    "aws_user_pools_web_client_id": "3gdruf9v4f060laprr04mdklqu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "opsdrivertable",
            "endpoint": "https://rlxxnqre7k.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "opsassetinventory",
            "endpoint": "https://c9cm6uxwz7.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "opsrigcomments",
            "endpoint": "https://6tc1xmgwyk.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "opsweatherapi",
            "endpoint": "https://3wpr58etbh.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
