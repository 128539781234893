import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form, Button, Row, Col } from 'react-bootstrap'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { NavLink } from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker';
import AddDriver from '../../NavBar/Icons/AddDriver'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './RigCommentTable.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
API.configure(awsmobile)

const RigCommentTable = () => {
    const [items, setItems] = useState(null)
    const [table, setTableHeaders] = useState(null)
    const [errorDT, setErrorDT] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const apiName = 'opsrigcomments'
    const path = '/rigcomments'

    const cellEdit =
        cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            afterSaveCell(oldValue, newValue, row, column) {
                let body = {
                    'comment_id': row['comment_id'],
                    'columnName': column['dataField'],
                    'newValue': newValue || null,
                }
                try {
                    setRefresh(true)
                    API.post(apiName, path, { body })
                        .then(res => console.log(body))
                        .then(res => { window.location.reload() })
                    setRefresh(false)
                }
                catch (err) {
                    console.log(err.message)
                }
            },
            beforeSaveCell(oldValue, newValue, row, column, done) {
                setTimeout(() => {
                    if (window.confirm('Do you want to accept this change?')) {
                        done(); // contine to save the changes
                    } else {
                        done(false); // reject the changes
                    }
                }, 0);
                return { async: true };
            }
        })

    const columns = [
        {
            dataField: 'time_of_error',
            text: 'Error timestamp (Your Browser\'s Timezone)',
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) =>
                <Form.Control
                    as={DateTimePicker}
                    value={new Date(value)}
                    onChange={async (evt) => {
                        setErrorDT(evt)
                        let dateVersion = new Date(evt)
                        let dateVersionValue = new Date(value)
                        if (
                            dateVersion.getFullYear() !== dateVersionValue.getFullYear()
                            ||
                            dateVersion.getDay() !== dateVersionValue.getDay()
                            ||
                            dateVersion.getMonth() !== dateVersionValue.getMonth()
                        ) {
                            editorProps.onUpdate(evt.toUTCString())
                        }
                        else {
                            return
                        }
                    }}
                    onKeyPress={(e) => {
                        e.key === 'Enter' ? editorProps.onUpdate(errorDT.toUTCString()) : setErrorDT(new Date(value))
                        setErrorDT(null)
                    }}
                    closeWidgets={true}
                    openWidgets={true}
                    clearIcon={null}
                />,
            formatter: (cell, row, rowIndex, extraData) => (
                <div>
                    {new Date(cell).toLocaleString()}
                </div>
            ),
            sort: true,
            headerAlign: 'center'
        },
        {
            dataField: 'rig_id',
            text: 'Rig',
            sort: true
        },
        {
            dataField: 'city',
            text: 'City',
            sort: true
        },
        {
            dataField: 'state',
            text: 'State',
            sort: true
        },
        {
            dataField: 'weather',
            text: 'Weather',
            sort: true,
        },
        {
            dataField: 'temperature_f',
            text: 'Temperature (F°)',
            sort: true
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true
        },
        {
            dataField: 'submitted_by',
            text: 'Submitted By',
            sort: true,
            editable: false
        },
        {
            dataField: 'timestamp_modified_utc',
            text: 'Last Modified (local tz)',
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex, extraData) => (
                <div>
                    {new Date(cell).toLocaleString()}
                </div>
            ),
        },
        {
            dataField: 'comment_id',
            text: 'Comment ID',
            sort: true,
            editable: false
        }
    ];

    const sortOrder = [
        {
            dataField: 'rig_id',
            order: 'asc'
        },
    ]

    function getTableAttributes(data) {
        let headers = [];
        data.forEach(item => {
            Object.keys(item).map((header) => {
                if (headers.indexOf(header) === -1) {
                    headers.push(header)
                }
            });
        })
        return headers
    }

    useEffect(() => {
        if (refresh) {
            try {
                API.get(apiName, path, { response: true })
                    .then(res => {
                        setItems(res.data['Items'])
                        setTableHeaders(
                            getTableAttributes(res.data['Items'])
                        )
                    }).then(res => { window.location.reload() })
            }
            catch (err) {
                console.log(err)
            }
        }
        setRefresh(false)
    }, [refresh])
    useEffect(() => {
        try {
            API.get(apiName, path, { response: true })
                .then(res => {
                    setItems(res.data['Items'])
                    setTableHeaders(
                        getTableAttributes(res.data['Items'])
                    )
                })
        }
        catch (err) {
            console.log(err)
        }
        setRefresh(false)
    }, [])

    return (
        <div style={{
            margin: `3% 0  auto`,
        }}>
            <h1> Comments </h1>
            <div style={{
                display: 'flex',
            }}>
                <button
                    className='driver-table-buttons'
                    style={{
                        color: 'white',
                        background: 'lightgreen'
                    }}
                >
                    <NavLink
                        to="/drivers/rigcommentform">
                        <div style={{ display: 'flex' }}>
                            <AddDriver size={30} />
                            &nbsp; &nbsp;
                            <span style={{
                                fontSize: '25px',
                                textDecoration: 'none',
                                color: 'white'
                            }}
                            >
                                Add a Comment
                            </span>
                        </div>
                    </NavLink>
                </button>
            </div>
            {items ?
                <BootstrapTable
                    data={items.map((item) => item)}
                    columns={columns}
                    keyField='comment_id'
                    bootstrap4={true}
                    striped
                    hover
                    condensed
                    rowStyle={{ width: 100, height: 50, wordBreak: 'break-all' }}
                    bordered={false}
                    defaultSorted={sortOrder}
                    expandRow={true}
                    cellEdit={cellEdit}
                >
                </BootstrapTable>
                :
                null
            }
        </div>
    )
}

export default RigCommentTable;