import React, { Component } from "react";
import { number } from 'prop-types';

class Windy extends Component {
    render() {
        const { size } = this.props
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 511.999 511.999"
                version="1.1"
                viewBox="0 0 511.999 511.999"
                xmlSpace="preserve"
                width={size}
                height={size}
            >
                <path
                    fill="#83D4ED"
                    d="M228.155 289.59H63.573c-30.356 0-54.97-24.61-54.97-54.968 0-30.364 24.614-54.972 54.97-54.972 5.732 0 11.263.878 16.46 2.506-.027-.83-.063-1.664-.063-2.506 0-40.837 33.101-73.94 73.933-73.94 35.21 0 64.655 24.623 72.102 57.58a44.317 44.317 0 0114.052-2.285c15.127 0 28.478 7.574 36.484 19.136a55.766 55.766 0 0113.987-1.777c30.718 0 55.617 24.898 55.617 55.613 0 30.714-24.899 55.614-55.617 55.614h-31.825"
                ></path>
                <path
                    fill="#9FDEF7"
                    d="M293 178.364c-4.829 0-9.518.619-13.987 1.777-8.005-11.561-21.355-19.136-36.484-19.136-4.913 0-9.633.811-14.052 2.285-7.446-32.958-36.891-57.58-72.102-57.58a73.84 73.84 0 00-22.87 3.623 169.119 169.119 0 00-5.021 40.986c0 57.821 29.073 108.842 73.391 139.27h91.123c30.718 0 55.617-24.9 55.617-55.614.002-30.713-24.897-55.611-55.615-55.611z"
                ></path>
                <g fill="#231F20">
                    <path d="M100.174 333.027H88.445a8.604 8.604 0 000 17.208h11.729a8.604 8.604 0 000-17.208zM266.553 333.027H128.486a8.604 8.604 0 000 17.208h138.068c22.161 0 40.191 18.03 40.191 40.192 0 21.734-17.345 39.482-38.919 40.157a8.743 8.743 0 00-1.272-.104c-22.123 0-40.122-17.999-40.122-40.124a8.604 8.604 0 00-17.208 0c0 31.023 24.771 56.352 55.57 57.286a8.636 8.636 0 001.758.182c31.65 0 57.398-25.747 57.398-57.397.001-31.651-25.748-57.4-57.397-57.4zM454.602 333.027h-114.41a8.604 8.604 0 000 17.208h114.41c22.161 0 40.191 18.03 40.191 40.192 0 21.734-17.345 39.482-38.919 40.157a8.743 8.743 0 00-1.272-.104c-22.124 0-40.124-17.999-40.124-40.124a8.604 8.604 0 00-17.208 0c0 31.023 24.772 56.352 55.572 57.286a8.636 8.636 0 001.758.182c31.65 0 57.398-25.747 57.398-57.397.002-31.651-25.747-57.4-57.396-57.4zM254.717 130.247a8.604 8.604 0 008.604-8.604c0-22.124 17.998-40.123 40.122-40.123.433 0 .856-.042 1.272-.104 21.575.675 38.921 18.423 38.921 40.157 0 13.373-6.627 25.833-17.728 33.333a8.602 8.602 0 004.825 15.733 8.561 8.561 0 004.809-1.476c15.844-10.703 25.302-28.494 25.302-47.591 0-31.65-25.75-57.397-57.4-57.397-.603 0-1.191.063-1.758.182-30.799.936-55.57 26.264-55.57 57.286a8.6 8.6 0 008.601 8.604zM376.913 239.82h72.016c31.648 0 57.396-25.749 57.396-57.398 0-31.651-25.747-57.399-57.396-57.399-.603 0-1.191.063-1.758.182-30.8.936-55.572 26.264-55.572 57.286a8.604 8.604 0 0017.208 0c0-22.124 17.999-40.124 40.124-40.124.433 0 .856-.042 1.272-.104 21.573.675 38.916 18.424 38.916 40.16 0 22.162-18.029 40.191-40.189 40.191h-72.016a8.603 8.603 0 10-.001 17.206zM474.118 280.906a8.604 8.604 0 000 17.208h12.893a8.604 8.604 0 000-17.208h-12.893z"></path>
                    <path d="M236.759 289.59a8.604 8.604 0 00-8.604-8.604H63.573c-25.566 0-46.366-20.799-46.366-46.364 0-25.567 20.8-46.368 46.366-46.368 4.741 0 9.413.711 13.887 2.112a8.604 8.604 0 0011.171-8.493l-.017-.484c-.02-.576-.042-1.155-.042-1.739 0-36.027 29.307-65.336 65.33-65.336 30.256 0 57.05 21.395 63.709 50.872a8.609 8.609 0 004.167 5.599 8.597 8.597 0 006.949.667 35.7 35.7 0 0111.329-1.843c11.726 0 22.72 5.768 29.41 15.43a8.6 8.6 0 009.23 3.431 47.218 47.218 0 0111.831-1.501c25.923 0 47.013 21.087 47.013 47.009 0 25.027-19.661 45.544-44.351 46.93h-.458c-.302 0-.601.016-.896.047-.436.012-.869.033-1.308.033h-31.825a8.604 8.604 0 000 17.208h31.825c1.059 0 2.113-.029 3.16-.081h150.654a8.604 8.604 0 000-17.208H334.299c12.569-11.73 20.45-28.423 20.45-46.93 0-35.409-28.809-64.216-64.221-64.216-3.525 0-7.041.288-10.507.86a52.99 52.99 0 00-39.964-18.218c-2.697 0-5.382.204-8.034.612a83.05 83.05 0 00-26.244-37.56c-14.617-11.831-33.039-18.347-51.876-18.347-42.786 0-78.073 32.725-82.144 74.462a64.33 64.33 0 00-8.185-.523C28.52 171.047 0 199.566 0 234.622c0 35.053 28.52 63.571 63.573 63.571h164.582a8.604 8.604 0 008.604-8.603z"></path>
                </g>
            </svg>
        );
    }
}

Windy.propTypes = {
    size: number
}
Windy.defaultProps = {
    size: 500
}
export default Windy;
