import React from "react";
import { number } from 'prop-types';

const SVGLogo = (props) => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 792 612"
    width={props.size}
    height={props.size}
    style={{
      enableBackground: "new 0 0 792 612",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{opacity:0.49;fill:url(#SVGID_1_);}\n\t.st1{opacity:0.49;fill:url(#SVGID_2_);}\n\t.st2{opacity:0.65;fill:url(#SVGID_3_);}\n\t.st3{opacity:0.65;fill:url(#SVGID_4_);}\n\t.st4{fill:#0093BB;}\n\t.st5{fill:#FFFFFF;}\n\t.st6{fill:#F05A24;}\n"
      }
    </style>
    <g>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1={5997.2925}
          y1={-483.4417}
          x2={6061.9336}
          y2={-483.4417}
          gradientTransform="matrix(-1 0 0 -1 6326.438 -242.343)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#0093BB",
              stopOpacity: 0,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#0093BB",
            }}
          />
        </linearGradient>
        <polygon
          className="st0"
          points="328.56,179.18 390.48,241.1 328.56,303.02 266.64,241.1  "
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1={5935.1616}
          y1={-483.4417}
          x2={5999.8027}
          y2={-483.4417}
          gradientTransform="matrix(-1 0 0 -1 6326.438 -242.343)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#0093BB",
              stopOpacity: 0,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#0093BB",
            }}
          />
        </linearGradient>
        <polygon
          className="st1"
          points="390.69,179.18 452.61,241.1 390.69,303.02 328.77,241.1  "
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1={5203.2373}
          y1={-483.4417}
          x2={5267.8779}
          y2={-483.4417}
          gradientTransform="matrix(1 0 0 -1 -4742.1338 -242.343)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#F05A24",
              stopOpacity: 0,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#F05A24",
            }}
          />
        </linearGradient>
        <polygon
          className="st2"
          points="461.69,179.18 399.77,241.1 461.69,303.02 523.61,241.1  "
        />
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1={5140.2373}
          y1={-483.4417}
          x2={5204.8779}
          y2={-483.4417}
          gradientTransform="matrix(1 0 0 -1 -4742.1338 -242.343)"
        >
          <stop
            offset={0}
            style={{
              stopColor: "#F05A24",
              stopOpacity: 0,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: "#F05A24",
            }}
          />
        </linearGradient>
        <polygon
          className="st3"
          points="398.69,179.18 336.77,241.1 398.69,303.02 460.61,241.1  "
        />
        <circle className="st4" cx={334.56} cy={179.96} r={14.13} />
        <circle className="st4" cx={334.56} cy={302.23} r={14.13} />
        <circle className="st5" cx={334.56} cy={241.1} r={14.13} />
        <circle className="st4" cx={271.43} cy={241.1} r={14.13} />
        <circle className="st4" cx={397.36} cy={179.96} r={14.13} />
        <circle className="st4" cx={397.36} cy={302.23} r={14.13} />
        <circle className="st6" cx={397.36} cy={241.1} r={14.13} />
        <circle className="st4" cx={334.56} cy={241.1} r={14.13} />
        <circle className="st6" cx={460.69} cy={179.96} r={14.13} />
        <circle className="st6" cx={460.69} cy={302.23} r={14.13} />
        <circle className="st6" cx={460.69} cy={241.1} r={14.13} />
        <circle className="st6" cx={523.82} cy={241.1} r={14.13} />
        <circle className="st6" cx={523.83} cy={241.1} r={14.13} />
      </g>
      <g>
        <g>
          <path
            className="st6"
            d="M182.32,429.51c-3.27,0.75-6.78,1.13-10.55,1.13c-13.32,0-19.97-6.85-19.97-20.54 c0-12.69,6.66-19.03,19.97-19.03c3.77,0,7.29,0.38,10.55,1.13v3.39c-3.52-0.75-6.91-1.13-10.18-1.13 c-10.68,0-16.02,5.21-16.02,15.64c0,11.43,5.34,17.15,16.02,17.15c3.27,0,6.66-0.38,10.18-1.13V429.51z"
          />
          <path
            className="st6"
            d="M189.67,410.86c0-13.44,6.16-20.16,18.47-20.16c12.31,0,18.47,6.72,18.47,20.16 c0,13.42-6.16,20.12-18.47,20.12C195.88,430.98,189.72,424.28,189.67,410.86z M208.14,427.63c9.67,0,14.51-5.64,14.51-16.92 c0-11.08-4.84-16.62-14.51-16.62s-14.51,5.54-14.51,16.62C193.63,421.99,198.46,427.63,208.14,427.63z"
          />
          <path
            className="st6"
            d="M236.78,430.64v-39.57h2.83l0.41,4.97c4.04-3.32,8.43-4.97,13.15-4.97c6.23,0,10.26,1.81,12.1,5.43 c4.55-3.62,9.16-5.43,13.83-5.43c8.84,0,13.27,4.38,13.27,13.15v26.42h-3.96v-26.42c0-6.41-3.12-9.61-9.35-9.61 c-4.97,0-9.08,1.71-12.32,5.12v30.9h-3.96v-27.06c0-5.98-3.22-8.97-9.65-8.97c-4.67,0-8.81,1.71-12.4,5.12v30.9H236.78z"
          />
          <path
            className="st6"
            d="M304.43,392.69c4.5-1.08,9.55-1.62,15.15-1.62c12.69,0,19.03,6.27,19.03,18.81 c0,13.84-6.38,20.77-19.14,20.77c-3.17,0-6.86-0.63-11.08-1.88v16.21h-3.96V392.69z M308.38,424.84c4,1.36,7.68,2.04,11.04,2.04 c10.23,0,15.34-5.69,15.34-17.07c0-10.12-5.09-15.19-15.26-15.19c-3.52,0-7.22,0.3-11.12,0.9V424.84z"
          />
          <path
            className="st6"
            d="M346.9,410.86c0-13.44,6.16-20.16,18.47-20.16c12.31,0,18.47,6.72,18.47,20.16 c0,13.42-6.16,20.12-18.47,20.12C353.1,430.98,346.95,424.28,346.9,410.86z M365.37,427.63c9.67,0,14.51-5.64,14.51-16.92 c0-11.08-4.84-16.62-14.51-16.62s-14.51,5.54-14.51,16.62C350.86,421.99,355.69,427.63,365.37,427.63z"
          />
          <path
            className="st6"
            d="M426.61,391.07v39.57h-2.9l-0.45-5.05c-4.55,3.37-9.62,5.05-15.22,5.05c-9.35,0-14.02-4.91-14.02-14.74 v-24.84h3.96v24.95c0,7.39,3.42,11.08,10.25,11.08c5,0,9.81-1.67,14.44-5.01v-31.02H426.61z"
          />
          <path
            className="st6"
            d="M438.67,430.64v-39.57h2.83l0.49,5.05c5.05-3.37,10-5.05,14.85-5.05c9.62,0,14.43,4.16,14.43,12.47v27.1 h-3.96v-27.21c0-5.88-3.56-8.82-10.67-8.82c-4.75,0-9.42,1.67-14.02,5.01v31.02H438.67z"
          />
          <path
            className="st6"
            d="M516.11,428.27c-4.3,1.58-9.71,2.37-16.24,2.37c-12.29,0-18.43-6.86-18.43-20.58 c0-12.66,6.26-18.99,18.77-18.99c3.92,0,7.9,0.63,11.95,1.88v-16.21h3.96V428.27z M512.16,396.5c-3.82-1.36-7.79-2.03-11.91-2.03 c-9.72,0-14.58,5.11-14.58,15.34c0,11.53,4.86,17.3,14.58,17.3c4.12,0,8.09-0.49,11.91-1.47V396.5z"
          />
          <path
            className="st4"
            d="M544.11,391.07c10.83,0,16.24,5.99,16.24,17.98c0,0.85-0.03,1.75-0.08,2.68h-30.04 c0,10.35,5.48,15.53,16.43,15.53c4.5,0,8.49-0.63,11.98-1.88v3.39c-3.49,1.26-7.49,1.88-11.98,1.88 c-13.59,0-20.39-6.75-20.39-20.24C526.29,397.52,532.23,391.07,544.11,391.07z M530.25,408.18h26.31 c-0.15-9.2-4.3-13.79-12.44-13.79C535.32,394.39,530.7,398.99,530.25,408.18z"
          />
          <path
            className="st4"
            d="M565.82,391.07h4.56l14.7,35.73l14.47-35.73h4.37l-17.18,40.74c-2.92,7.19-7.29,11.67-13.12,13.45 l-1.17-3.17c4.57-1.66,8.06-5.28,10.48-10.85L565.82,391.07z"
          />
          <path
            className="st4"
            d="M627.21,391.07c10.83,0,16.24,5.99,16.24,17.98c0,0.85-0.03,1.75-0.08,2.68h-30.04 c0,10.35,5.48,15.53,16.43,15.53c4.5,0,8.49-0.63,11.98-1.88v3.39c-3.49,1.26-7.49,1.88-11.98,1.88 c-13.59,0-20.39-6.75-20.39-20.24C609.39,397.52,615.33,391.07,627.21,391.07z M613.34,408.18h26.31 c-0.15-9.2-4.3-13.79-12.44-13.79C618.42,394.39,613.8,398.99,613.34,408.18z"
          />
        </g>
      </g>
    </g>
  </svg>
);

SVGLogo.propTypes = {
    size: number
}
SVGLogo.defaultProps = {
    size: '30%'
}

export default SVGLogo;
