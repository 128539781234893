import React from 'react';
import FadeInSection from '../Utilities/FadeInSection/FadeInSection'
import '../Utilities/DocumentStyles.css'

function Overview() {
    return (
        <div>
            <div style={{
                margin: '0px auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '400px'
                }}>
                    <ol className='jumplink-ordered-list'>
                        <li style={{ listStyle: 'disc' }}><a href='#introduction'>Introduction</a></li>
                        <li style={{ listStyle: 'disc' }}><a href='#modes-of-operation'>Modes of Operation</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#launch'>Launch</a></li>
                            <li><a href='#single-iteration'>Single Iteration</a></li>
                            <li><a href='#continual-operations'>Continual Operations</a></li>
                        </ol>

                        <li style={{ listStyle: 'disc' }}><a href='#documenting-differences'>Documenting Differences Between Physical & Remote</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#dynamic-operations'>Dynamic Operations</a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#hiring'>Hiring</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#driver-role'>Driver Role</a></li>
                            <li><a href='#driver-requirements'>Driver Requirements</a></li>
                            <li><a href='#driver-qualifications'>Driver Qualifications</a></li>
                            <li><a href='#hiring-tools'>Hiring Tools</a></li>
                            <li><a href='#timeline'>Timeline</a></li>
                            <li><a href='#workflow'>Workflow</a></li>
                            <li><a href='#driver-compensation'>Driver Compensation</a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#onboarding'>Onboarding</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#required-documents'>Required Documents</a></li>
                            <li><a href='#vehicle-inspection'>Vehicle Inspection</a></li>
                            <li><a href='#peo'>PEO</a></li>
                            <li><a href='#hardware'>Hardware</a></li>
                            <li><a href='#installation'>Installation </a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#training'>Training</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#format'>Format</a></li>
                            <li><a href='#training-order'>Training Order</a></li>
                            <li><a href='#training-results'>Training Results</a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#scheduling'>Scheduling</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#philosophy'>Philosophy</a></li>
                            <li><a href='#solution'>Solution</a></li>
                            <li><a href='#tools'>Tools </a></li>
                            <li><a href='#timetable'>Timetable </a></li>
                            <li><a href='#overtime'>Overtime</a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#driver-companion'>Driver Companion Application</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#introduction'>Introduction</a></li>
                            <li><a href='#description'>Description</a></li>
                            <li><a href='#companion-app-operations'>Companion App Operations </a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#driver-workflow'>Driver Workflow and Admin</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#overview'>Overview  </a></li>
                            <li><a href='#assigning-sections'>Assigning Sections</a></li>
                            <li><a href='#driver-equipment'>Driver Equipment</a></li>
                            <li><a href='#data-upload'>Data Upload</a></li>
                            <li><a href='#performance'>Performance</a></li>
                            <li><a href='#disciplinary-action'>Disciplinary Action</a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#fleet-support'>Fleet Support</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#fleet-support'>What is Fleet Support</a></li>
                            <li><a href='#vendor-management'>Vendor Management</a></li>
                            <li><a href='#escalations'>Escalations</a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#troubleshooting'>Troubleshooting</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#remote-support'>Remote Support </a></li>
                            <li><a href='#onboard-information'>Onboard Information </a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#launching-onsite'>Launching On-Site</a></li>
                        <ol className='jumplink-ordered-list'>
                            <li><a href='#requirements'>Requirements </a></li>
                            <li><a href='#trade-offs'>Trade-offs </a></li>
                        </ol>
                        <li style={{ listStyle: 'disc' }}><a href='#final-section'>Final Section</a></li>
                    </ol>
                </div>
                {/* Displays the current city */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    borderLeft: 'solid 3px black',
                    paddingLeft: '80px'
                }}>
                    <h1 className='topic-headers' style={{
                    }}> Data Collection Overview</h1>

                    <h2 className='sub-headers'> OVERVIEW</h2>
                    <p className='document-content'>
                        With the progression of our MMS technology, our initial product has also been realized. In line with Compound Eye’s goal to map every street in the US and the world, a data collection platform for operations has been developed to accomplish this. In addition to the various process overviews; Data Collection Operations have several different modes, which are covered in this document.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 className='sub-headers'> Purpose</h2>
                    <p className='document-content'>
                        This document serves as the high level guide to data collection operations processes. It outlines processes, procedures and high level operational exception handling. Hardware operations processes are not covered in the scope of this document.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='introduction' className='sub-headers'> Introduction</h2>
                    <p className='document-content'>
                        MMS Data Collection is the primary operation of Compound Eye. It facilitates R&D and provides the data required to launch and maintain our products in a city. There are three “Modes” of data collection in a market: Launch, Single Iteration and Continual Operation. All three are covered in the next section. Part-time drivers are onboarded, trained and their vehicle is outfitted with a MMS system. How this is done precisely will depend on the mode of data collection operations. Drivers will then schedule themselves on an open shift system and be given a section of the market to be mapped. Normal data collection intervals are 30, 60 & 90 days. However, in a Single Iteration market the interval may be up to one year. Using our companion application, drivers track their data collection progress for the current iteration and map section. At the end of each shift, drivers secure their vehicle in an area with stable WiFi and remotely upload the data collected from the shift into the data pipeline and added to the market dataset until a full data collection interval has been completed.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='modes-of-operation' className='sub-headers'> Modes of Operation</h2>
                    <p className='document-content'>
                        <i>(Please see the Deployment document for more details on specific mode procedure)</i>
                    </p>
                    <p id='launch' className='document-content'>
                        <strong>Launch:</strong> The initial data collection interval for a new market. Standard data collection interval for launch is 30 days. Can be executed on-site or remotely. It is recommended that markets which require over 10 drivers be physically deployed during launch. Certain exceptions can and are made to this general rule and is at the sole discretion of the Executive team to decide the launch format.
                    </p>
                    <p id='single-iteration' className='document-content'>
                        <strong>Single Iteration: </strong> This mode allows us to rapidly collect unique data for different markets without a permanent presence. Essentially a prospecting mode as costs are minimized.
                    </p>
                    <p className='document-content'>
                        This mode is the same as launch with distinct differences:
                        <ol className='ordered-list'>
                            <li>Data collection interval can be set at 60 or 90 days.</li>
                            <li>Barring HR or resource constraints, a single iteration can be done remotely with up to 15 drivers.</li>
                            <li>After the initial data collection interval, data collection in the market is suspended until another Launch or Single Iteration is performed in the market.</li>
                        </ol>
                    </p>
                    <p id='continual-operations' className='document-content'>
                        <strong>Continual Operation: </strong>This mode is the continuation of operations in a market post-launch. The data collection interval and driver staffing are reduced at the conclusion of launch and in most cases all physical deployment will end. Data Collection Operations will continue with remote oversight.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='documenting-differences' className='sub-headers'> Documenting Differences Between Physical & Remote:</h2>
                    <p id='dynamic-operations' className='document-content'>
                        <strong>Dynamic Operations:  </strong> With every new market, we will face new and unique challenges… Therefore, we cannot expect a particular mold to fit a city. That is why in any situation we have the option to choose whether to deploy to a new location physically, or launch the market remotely... Each deployment type has its own challenges, as well. In each applicable section of this document, you will find a subsection for both. When following this document as a guide, pay close attention to how the sections are labeled so as to follow the correct process.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='hiring' className='sub-headers'> Hiring</h2>
                    <p id='driver-role' className='document-content'>
                        <strong>Driver Role:  </strong>All drivers hired outside of markets in continual operation are part-time temporary employees and can work a maximum of 20 hours a week. Drivers in continual operations roles are standard part-time employees with a general 20 hour work schedule.
                    </p>
                    <p id='driver-requirements' className='document-content'>
                        <strong>Driver Requirements:  </strong>Although some requirements vary from between states, generally we require our drivers to:
                        <ol className='ordered-list'>
                            <li>Be at least 18 years of age</li>
                            <li>Have a Valid Drivers Licence, Registration and Insurance</li>
                            <li>Provide their own vehicle (Mileage Reimbursement provided)</li>
                            <li>Meet Vehicle Qualifications</li>
                            <li>Secure Parking and WiFi</li>
                            <li>Have a cell phone for safety and tracking progress</li>
                            <li>Pass a Background check</li>
                            <li>Have a clean driving record</li>
                            <li>Be based locally within the market</li>
                        </ol>
                    </p>
                    <p id='driver-qualifications' className='document-content'>
                        <strong>Driver Qualifications:  </strong>A positive attitude and excitement for the unique opportunity. Launch and Single Iteration modes give us the opportunity to vet drivers on the job before hiring any of them permanently. More scrutiny should be given for candidates applying to roles in continually operating markets.
                    </p>
                    <p id='hiring-tools' className='document-content'>
                        <strong>Hiring Tools:  </strong>All jobs are posted via the Greenhouse ATS. Hiring needs can be determined by using the Operations & Deployment Reference workbook
                    </p>
                    <p id='timeline' className='document-content'>
                        <strong>Timeline:  </strong>Timeline: In all cases, Job postings should go up at least 18 days prior to expected launch date. This allows enough time to hire, onboard, train and install equipment.
                    </p>
                    <p id='workflow' className='document-content'>
                        <strong>Workflow:  </strong>Workflow: Please see hiring workflow here
                    </p>
                    <p id='driver-compensation' className='document-content'>
                        <strong>Driver Compensation: </strong>Driver Compensation: Drivers are to be compensated hourly at a <a href='https://about.gitlab.com/handbook/total-rewards/compensation/compensation-calculator/calculator/'>cost-of living adjusted rate</a> equal to $17 + mileage in a California Metro. Drivers will also be compensated for <a href='https://www.irs.gov/tax-professionals/standard-mileage-rates'>mileage accrued </a> on their vehicle while working. Mileage is to be logged and reported by the driver and audited using system GPS data. Cell phone & internet reimbursement will be determined on a market-to-market basis.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='onboarding' className='sub-headers'> Onboarding</h2>
                    <h4 id='required-documents' className='paragraph-headers'> Required Documents</h4>
                    <p className='document-content'>
                        <ol type='a'>
                            <li>I-9 Verification</li>
                            <li>Tax Documentation</li>
                            <li>Non-Disclosure Agreement</li>
                            <li>A copy of Driver’s License + Valid Insurance </li>
                            <li>Hardware Agreement </li>
                        </ol>
                    </p>
                    <p id='vehicle-inspection' className='document-content'>
                        <strong>Vehicle Inspection: </strong>In all cases, the driver vehicle is inspected for potential hazards or other issues during the installation process. If the vendor cannot be trusted to inspect the vehicles, drivers will submit photos of their vehicle as a part of the onboarding. These pictures must include a photo of each side of the vehicle and one of the roof.
                    </p>
                    <p id='peo' className='document-content'>
                        <strong>PEO: </strong>All new drivers must register for payroll through Sequoia One.
                    </p>
                    <p id='hardware' className='document-content'>
                        <strong>Hardware: </strong>Compound Eye retains ownership of the data collection hardware and other loaned property at all times. Assembly team ships newly fabricated hardware to the launch office/install vendor upon drivers successful enrollment in Sequoia One. Drivers are required to submit a  Hardware Agreement as a part of onboarding. Failure to return equipment may result in further legal action to retrieve property by the company.
                    </p>
                    <p id='installation' className='document-content'>
                        <strong>Installation:  </strong>
                    </p>
                    <p className='document-content' style={{ marginLeft: '10px' }}>
                        <i>Remote:  </i> An installation vendor is contracted to install equipment. Fleet Support coordinates scheduling between drivers and install vendors. 3-5 day window for installations ending at least 24 hours before expected launch.
                    </p>
                    <p className='document-content' style={{ marginLeft: '10px' }}>
                        <i>Physical:  </i> Launch team coordinates & performs installs during in person training + onboarding session.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='training' className='sub-headers'> Training</h2>
                    <p className='document-content'>
                        <i>Training outline can be found <a href='https://docs.google.com/document/u/1/d/1PC3A6ogE3ww4yinwyKB9W21Dutc823A9v_RFsjg4E70/edit'>here</a></i>
                    </p>
                    <p id='format' className='document-content'>
                        <strong>Format: </strong> In all cases drivers must complete one online training and one live training. For remote hires: Live training is completed via video conference. Online training must be completed prior to scheduling live training. Drivers are educated on the equipment, day-to-day driver operations, installing & using the fleet app, uploading data and troubleshooting.
                    </p>
                    <p id='training-order' className='document-content'>
                        <strong>Training Order: </strong>Giving drivers the opportunity to train online first allows them the chance to comprehend at their own pace and form questions for the live training. It also informs the trainer which drivers grasp the concepts from the online training. Should a trainer determine any driver is not interested, disruptive or genuinely refuses to learn the material, they may dismiss the driver on the spot.
                    </p>
                    <p id='training-results' className='document-content'>
                        <strong>Training Results: </strong>Online training can be passed with a simple multiple choice test. The overall goal of the training is for the drivers to be able to perform their duties safely and accurately to expectation. If for any reason a trainer deems that a driver would be unable to meet our standards during live training: It is left to the discretion of the trainer whether to retrain or dismiss the driver.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='scheduling' className='sub-headers'> Scheduling</h2>
                    <p id='philosophy' className='document-content'>
                        <strong>Philosophy: </strong>Compound Eye strives to give employees in the driver role the highest degree of freedom. Honoring this, drivers have broad discretion to select their shifts and how they work them. Drivers will continue to be evaluated on their performance regularly as described in the daily operations section.
                    </p>
                    <p id='solution' className='document-content'>
                        <strong>Solution: </strong>In order to achieve our scheduling philosophy, drivers are scheduled using open shifts. In this model, unassigned shifts are released at a specific time each week. Drivers will have a set number of hours they must claim and work. A small amount of shift scarcity is implemented on a day-to-day basis to ensure timely scheduling. Drivers are free to trade shifts for a different open shift or amongst themselves.
                    </p>
                    <p id='tools' className='document-content'>
                        <strong>Tools: </strong>All scheduling and administration is done through WhenIWork. Payroll is run every 2 weeks with a one week delay and requires a 2-way audit before exporting hours to Sequoia One.
                    </p>
                    <p id='timetable' className='document-content'>
                        <strong>Timetable: </strong>PTO requests should be made at least one week prior to the day off being requested. Schedules are to be released Wednesday the week before the schedule week at 9am. Any drivers unscheduled or missing shifts on Friday (2 days after schedule release) receive support to remind/assist drivers in completing their schedule. Should a driver not fill their schedule by the first working day of the scheduled week, they are marked no-call-no-show for the entire week.
                    </p>
                    <p id='overtime' className='document-content'>
                        <strong>Overtime: </strong>Overtime for data collection drivers is not authorized unless expressly permitted by the Director of Operations.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='driver-companion' className='sub-headers'> Driver Companion Application</h2>
                    <p id='introduction' className='document-content'>
                        <strong>Introduction: </strong>While performing their duties, drivers require a way to track their progress, understand if an area needs to be redriven, or due to operational exception needs to understand a change in their section of the market. The current Driver Application is an MVP and is several services hacked together into a $5 mobile app.
                    </p>
                    <p id='description' className='document-content'>
                        <strong>Description: </strong>Currently we are using the USGS WMS servers for TIGER and the National Map to serve maps to the GPX Viewer Pro application. As this app allows for the recording of GPS location, it acts as an excellent interim solution to fulfill tracking needs. On the backend, an API will query the historic data collection routes with good data and aggregate a GPX file with driven areas and the data collection boundaries for the driver, which is sent as an SMS on regular intervals. A walkthrough for 1st time app setup is <a href='https://docs.google.com/document/u/1/d/1Wjbd3m41-hMLzIN9v5PNxQ23B0dVK_kkTCnKkppzR84/edit'>here.</a>
                    </p>
                    <p id='companion-app-operations' className='document-content'>
                        <strong>Companion App Operations: </strong>Drivers are trained on how to install and use the app. Compound Eye will purchase the GPX Viewer Pro app for each driver. Drivers press the record button and everything else is handled by the app. As issues arise with the application it is important to maintain documentation of the issues and solutions to common problems. Checking the app’s efficacy on regular intervals is necessary to determine when a more robust solution is necessary.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='driver-workflow' className='sub-headers'> Driver Workflow and Admin</h2>
                    <p id='overview' className='document-content'>
                        <strong>Overview: </strong>Drivers schedule for and work 5 x 4 hours shifts per week with no scheduled or paid breaks. After completing a preflight checklist, drivers clock in, start from home and head straight to their designated data collection area. Tracking progress, drivers collect data from roads which have not yet been explored in the data collection interval. Once completed, drivers will return to origin and initiate the data upload process.
                    </p>
                    <p id='assigning-sections' className='document-content'>
                        <strong>Assigning Sections: </strong>Once per interval, drivers are reassigned sections of the market. When assigning these sections to drivers: It is imperative to prioritize the smallest travel distances from driver’s home to designated section. The programmatic process for this assignment is still under development. Market sections can be created in several mapping apps on the web, as a backup should any issues arise. Should a driver leave the platform, his remaining section is to be reassigned between the remaining drivers.
                    </p>
                    <p id='driver-equipment' className='document-content'>
                        <strong>Driver Equipment: </strong>Drivers are expected to bring all Equipment & Tools necessary for collecting data safely and efficiently while on shift. The following is an authoritative list of the items drivers are expected to have with them on every shift:
                    </p>
                    <p id='data-upload' className='document-content'>
                        <ol className='ordered-list'>
                            <li>Operational MMS system</li>
                            <li>Fully functional vehicle</li>
                            <li>Cell phone</li>
                            <li>Companion app running</li>
                            <li>Any and all troubleshooting guides + contact lists</li>
                            <li>Water - For hydration</li>
                        </ol>
                    </p>
                    <p id='data-upload' className='document-content'>
                        <strong>Data Upload: </strong>In order for a shift to be fully completed, the driver must safely park their vehicle at its home location and begin the upload process. It is the responsibility of the driver to ensure the upload completes. Datasets are currently 50-100GB.
                    </p>
                    <p id='performance' className='document-content'>
                        <strong>Performance: </strong>As drivers are given the freedom to choose their schedule, driver performance is measured on the following:
                    </p>
                    <p className='document-content'>
                        <ol className='ordered-list'>
                            <li>Completing schedule registration</li>
                            <li>Attendance</li>
                            <li>Data Collected vs. Average</li>
                            <li>Time to complete section</li>
                            <li>Consistency</li>
                        </ol>
                        This list of qualifiers and metrics is an indicative way of identifying high performing drivers. Use your judgement to determine if a driver who comes up short on this list exhibits other qualities of a top performing driver.
                    </p>
                    <p id='disciplinary-action' className='document-content'>
                        <strong>Disciplinary Action: </strong>While we foster an inclusive and supportive work environment, violations of company policy and misconduct are not tolerated and must be well documented. If the issue is low performance, check in with the driver to ensure the issue isn’t related to something else. The following is the formal disciplinary chain:
                        <ol className='ordered-list'>
                            <li>Verbal Warning</li>
                            <li>1st Written Warning</li>
                            <li>2nd Written Warning + 1:1</li>
                            <li>Termination</li>
                        </ol>
                        Major infractions can skip steps in this chain, as deemed appropriate by the Operations Manager, up to and including termination. They may also use their discretion in issuing a second verbal warning for minor violations.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='fleet-support' className='sub-headers'> Fleet Support</h2>
                    <p id='fleet-support' className='document-content'>
                        <strong>What is Fleet Support?: </strong> Currently <a href='https://docs.google.com/document/u/1/d/1eDMk_kuaK0UZGqDZ3RIec_HdAq1ryYa5R7Pxlc19_iM/edit'>Fleet Support</a> is simply a dedicated phone number that drivers can call during operating hours to troubleshoot, ask administrative questions or report an emergency or incident. It is the central point of contact for the department. Needs for this role will be assessed until a permanent position is formed.
                    </p>
                    <p id='vendor-management' className='document-content'>
                        <strong>Vendor Management: </strong>The fleet support line will also be the point of contact for vendors. A vendor list is to be created once we sign our first vendor agreement.
                    </p>
                    <p id='escalations' className='document-content'>
                        <strong>Escalations: </strong>In all cases, All escalations are to be routed through the fleet support desk. The current escalation chain is as follows:
                        <ol className='ordered-list'>
                            <li>Driver</li>
                            <li>Launcher</li>
                            <li>Fleet Support</li>
                            <li>Director of Operations</li>
                            <li>Head of People</li>
                            <li>CEO</li>
                        </ol>
                    </p>

                    <h2 id='troubleshooting' className='sub-headers'> Troubleshooting</h2>
                    <p id='remote-support' className='document-content'>
                        <strong>Remote Support: </strong>Once an issue is received by fleet support, the MMS rig is enabled with remote support for debugging. Escalate the issue to the Hardware team for resolution.
                    </p>
                    <p id='onboard-information' className='document-content'>
                        <strong>Onboard Information: </strong>Compound Eye provides drivers with a list of important phone numbers, addresses and helpful information pertinent to data collection. As stated above, drivers are required to carry this information while driving.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='launching-onsite' className='sub-headers'> Launching On-Site</h2>
                    <p className='document-content'>
                        <i>*Launcher guide can be found as a part of the <a href='https://docs.google.com/document/u/1/d/1a2tVFQIGsfePrftroRiFWFZeNakt88Xfe6uFtwAfSgM/edit#'>Deployment Doc.</a></i>
                    </p>
                    <p id='requirements' className='document-content'>
                        <strong>Requirements:</strong>Launching a market is incredibly exciting and rewarding, however it comes with unique challenges. Please fully read the launcher guide as it’s likely you will launch a market. Launch Teams are always 2 or more people for safety. A normal launch deployment will last a total of 31-36 days.
                    </p>
                    <p id='trade-offs' className='document-content'>
                        <strong>Trade-offs:</strong>Based on prior cost modeling: remote deployment is about 20% less expensive than a launch deployment in the market. However it is important to consider the resource trade-offs with HR during onboarding, as HR will onboard all drivers in a remote deployment and the associated costs are outside the scope of our model.
                    </p>
                    <hr className='horizontal-row' />
                    <h2 id='final-section' id='final-section' className='sub-headers'>Final Section</h2>
                    <p className='document-content'>
                        <ul className='unordered-list'>
                            <li style={{ listStyle: 'disc' }}>Final Section</li>
                            <li style={{ listStyle: 'disc' }}>Do not sacrifice quality for speed.</li>
                            <li style={{ listStyle: 'disc' }}>Reach out to Adam with any questions.</li>
                        </ul>
                    </p>



                </div>
            </div>
        </div>
    )
}


export default Overview;
