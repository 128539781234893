import React, { useState, useEffect } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import LandingPage from './LandingPage/LandingPage'
import Menu from './NavBar/Icons/Menu'
import Weather from './Dashboards/Weather/Weather'
import './App.css';
import Reconstructions from './Dashboards/Reconstructions/Components/Reconstructions'
import Telemetry from './Dashboards/Telemetry/Components/Telemetry';
import ShiftChecklist from './Drivers/ShiftChecklist'
import AppWalkthrough from './Drivers/AppWalkThrough'
import DriverOnboarding from './DataCollectionOperations/DriverOnboarding'
import FleetSupport from './DataCollectionOperations/FleetSupport'
import DriverHiringGuide from './DataCollectionOperations/DriverHiringGuide'
import VehicleRequirementsGuide from './DataCollectionOperations/VehicleRequirementsGuide'
import Overview from './DataCollectionOperations/Overview'
import DriverTraining from './DataCollectionOperations/DriverTraining'
import EmployeeHandbook from './Docs/EmployeeHandbook'
import DocsMain from './Docs/DocsMain'
import DashboardsMain from './Dashboards/DashboardsMain'
import AnnotationMetrics from './Dashboards/CloudFactoryDashboard/AnnotationMetrics'
import AnnotationCosts from './Dashboards/CostDashboard/AnnotationCosts'
import CuboidJobs from './Dashboards/CloudFactoryDashboard/CuboidJobs'
import DriverMetrics from './Dashboards/Summary/DriverMetrics'
import DriverForm from './Drivers/DriverForm'
import DriverTable from './Drivers/DriverTable/DriverTable'
import DriverDirectory from './NavBar/Icons/DriverDirectoryIcon'
import DriversMain from './Drivers/DriversMain'
import DriverRoutingGuide from './Drivers/DriverRoutingGuide';
import RigCommentForm from './Drivers/RigCommentForm/RigCommentForm';
import RigCommentTable from './Drivers/RigCommentTable/RigCommentTable';
import Part from './Parts/Part/Part'
import PartsMain from './Parts/PartsMain'
import PartForm from './Parts/Part/PartForm'
import PartsDirectory from './Parts/Directory/PartsDirectory'
// Navigation bar
import NavBar from './NavBar/Navbar';
import LogOutButton from './NavBar/Icons/LogOutButton'
import { BrowserRouter as Router, Redirect, Route, Routes } from "react-router-dom";
import UnauthorizedIcon from './ErrorPages/403'
import Safety from './Safety/Safety'
import Covid19Procedures from './Safety/Covid19Procedures'

//Utiliies
import { domainCheck, compoundEyesOnly, driverAccess, cfAccess } from './Utilities/RouteAccess'

// Amplify imports
import { Amplify, Auth, API, input, hint, } from 'aws-amplify';
import { Authenticator, useTheme, Heading, Text, View, Image, useAuthenticator, Button, AmplifyProvider, withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
import { onAuthUIStateChange, } from '@aws-amplify/ui-components';
import awsmobile from './aws-exports'
import '@aws-amplify/ui-react/styles.css';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as aws from 'aws-sdk';
Amplify.configure(awsmobile);



const App = ({ user }) => {

  const [theme, setTheme] = useState({ mode: 'light' })
  const [authState, setAuthState] = useState({})
  const [userName, setUserName] = useState({})
  const [useremailData, setUseremailData] = useState({})
  const [stsParams, setStsParams] = useState()
  const [authToken, setAuthToken] = useState()
  let AWS_ACCESS_KEY_ID;
  let AWS_SECRET_KEY_ID;
  let AWS_SESSION_TOKEN;


  const poolData = {
    UserPoolId: awsmobile.aws_user_pools_id, // Your user pool id here
    ClientId: awsmobile.aws_user_pools_web_client_id, // Your client id here
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();


  AWS.config.region = 'us-west-2';
  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      console.log('session validity: ' + session.isValid());
      cognitoUser.getUserAttributes(function (err, attributes) {
        if (err) {
        } else {
          console.log(attributes)
        }
      });
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsmobile.aws_cognito_identity_pool_id, // your identity pool id here
        Logins: {
          [`cognito-idp.us-west-2.amazonaws.com/${awsmobile.aws_user_pools_id}`]: session
            .getIdToken()
            .getJwtToken(),
        },
      });

      AWS.config.credentials.get(function (err) {
        if (err) {
          console.log(err.message);
        }
        else {
          AWS_ACCESS_KEY_ID = AWS.config.credentials.accessKeyId;
          AWS_SECRET_KEY_ID = AWS.config.credentials.secretAccessKey;
          AWS_SESSION_TOKEN = AWS.config.credentials.sessionToken
        }
      })
    });
  }

  function getStsParams(authEmail) {
    if (cognitoUser !== null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        } else {
          AWS.config.region = 'us-west-2'
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: awsmobile.aws_cognito_identity_pool_id, // your identity pool id here
            Logins: {
              [`cognito-idp.us-west-2.amazonaws.com/${awsmobile.aws_user_pools_id}`]: session.getAccessToken().getJwtToken()
            },
          });

          let params = {
            IdentityPoolId: `${awsmobile.aws_cognito_identity_pool_id}`, // your identity pool id here
            Logins: {
              [`cognito-idp.us-west-2.amazonaws.com/${awsmobile.aws_user_pools_id}`]: session['idToken'].jwtToken
            }
          };
          let cognitoIdentity = new AWS.CognitoIdentity();
          cognitoIdentity.getId(params, function (err, data) {
            if (err) console.log(err, err.stack);
            else {
              data.Logins = {
                // your logins here
                [`cognito-idp.us-west-2.amazonaws.com/${awsmobile.aws_user_pools_id}`]: session['idToken'].jwtToken
              }
              cognitoIdentity.getOpenIdToken(data, function (err, openIdToken) {
                if (err) {
                  console.log(err, err.stack);
                } else {
                  setStsParams({
                    RoleSessionName: 'websession1',
                    WebIdentityToken: openIdToken.Token,
                    RoleArn: process.env.REACT_APP_COG_IDP_ROLE,
                    email: authEmail
                  })
                }
              })
            }
          }
          )
        }
      });
    }
  }

  function generateUnuthorizedPage() {
    return (
      <div>
        <UnauthorizedIcon />
        <h1>403</h1>
        <h3> Oops! Looks like you are not authorized to view this page.</h3>
      </div>
    )
  }


  const [toggle, setToggle] = useState({ mode: true })

  const toggleMenu = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    if (toggle.mode === false) {
      setToggle({ mode: true })
    }
    else if (toggle.mode === true) {
      setToggle({ mode: false })
    }
  }
  let sidebar = (toggle.mode === true) ? 'sidebar open' : 'sidebar';
  let menuButton = (toggle.mode === true) ? 'menuButton open' : 'menuButton'
  let body = (toggle.mode === false) ? 'body open' : 'body';
  let logOutButton = (toggle.mode === true) ? 'logOutButton open' : 'logOutButton';


  useEffect(() => {
    setUseremailData(user.attributes.email)
    getStsParams(user)
  }, [])

  const signOut = async () => {
    await Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  return (
    <Router>

      <ThemeProvider theme={theme}>
        <>
          <div className="App">
            <div className={menuButton} >
              <button className='menuIcon' onClick={() => toggleMenu()} >
                <Menu size={25} />
              </button>
              <span style={{
                float: 'right',
                height: '100%'
              }}>
                <button className={logOutButton} onClick={() => signOut()}
                  style={{ height: '100%', backgroundColor: 'white' }}
                >
                  Log Out &nbsp;
                  <LogOutButton
                    size={15}
                  />
                </button>
              </span>
              <div className={sidebar}>
                <NavBar
                  username={useremailData}
                  theme={theme.mode}
                  toggleMenu={toggleMenu}
                  signOut={signOut}
                />
              </div>
            </div>
            <div className='body'>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                {/* </Route> */}


                {/* Data Collection */}
                <Route path='/datacollection' element={compoundEyesOnly(useremailData, <Overview />, generateUnuthorizedPage)} />
                <Route path='/datacollection/driveronboarding' element={compoundEyesOnly(useremailData, <DriverOnboarding />, generateUnuthorizedPage())} />
                <Route path='/datacollection/fleetsupport' element={compoundEyesOnly(useremailData, <FleetSupport />, generateUnuthorizedPage())} />
                <Route path='/datacollection/hiringguide' element={compoundEyesOnly(useremailData, <DriverHiringGuide />, generateUnuthorizedPage())} />
                <Route path='/datacollection/vehiclerequirementsguide' element={compoundEyesOnly(useremailData, <VehicleRequirementsGuide />, generateUnuthorizedPage())} />
                <Route path='/datacollection/drivertraining' element={compoundEyesOnly(useremailData, <DriverTraining />, generateUnuthorizedPage())} />


                {/* Safety   */}
                <Route path='/safety' element={driverAccess(useremailData, <Safety />, generateUnuthorizedPage())} />
                <Route path='/safety/covid19procedures' element={driverAccess(useremailData, <Covid19Procedures />, generateUnuthorizedPage())} />

                {/* Documentation  */}
                <Route path='/documentation' element={driverAccess(useremailData, <DocsMain />, generateUnuthorizedPage())} />
                <Route path='/documentation/employeehandbook' element={driverAccess(useremailData, <EmployeeHandbook />, generateUnuthorizedPage())} />
                <Route path='/documentation/driverroutingguide' element={driverAccess(useremailData, <DriverRoutingGuide />, generateUnuthorizedPage())} />
                <Route path='/documentation/appwalkthrough' element={driverAccess(useremailData, <AppWalkthrough />, generateUnuthorizedPage())} />

                {/* Incidents */}
                <Route path='/incidents'>
                  TODO:Main page with jump to links
                </Route>
                <Route path='/incidents/log'>
                  TODO: incidents logs
                </Route>
                <Route path='/incidents/reports'>
                  TODO: data of incident reports
                </Route>
                <Route path='/incidents/form'>
                  TODO: driver form to report an incident
                </Route>
                <Route path='/incidents/responses'>
                  TODO: respond to driver incident forms
                </Route>

                {/* Inventory */}
                <Route path='/parts' element={driverAccess(useremailData, <PartsMain />, generateUnuthorizedPage())} />
                <Route path='/parts/partform' element={compoundEyesOnly(useremailData, <PartForm />, generateUnuthorizedPage())} />
                <Route path='/parts/directory' element={compoundEyesOnly(useremailData, <PartsDirectory />, generateUnuthorizedPage())} />
                <Route path='/parts/:assetID' element={compoundEyesOnly(useremailData, <Part STS_PARAMS={stsParams} />, generateUnuthorizedPage())} />

                {/* Drivers  */}
                <Route path='/drivers' element={compoundEyesOnly(useremailData, <DriversMain />, generateUnuthorizedPage())} />
                <Route path='/drivers/vehiclerequirements'>
                  driver vehicle requirements
                </Route>
                <Route path='/drivers/shiftchecklist' element={driverAccess(useremailData, <ShiftChecklist />, generateUnuthorizedPage())} />
                <Route path='/drivers/exitresponses'>
                  exit responses
                </Route>
                <Route path='/drivers/hiringguide'>
                  drivers hiring guide
                </Route>
                <Route path='/drivers/form' element={compoundEyesOnly(useremailData, <DriverForm />, generateUnuthorizedPage())} />
                <Route path='/drivers/driverdirectory' element={compoundEyesOnly(useremailData, <DriverTable authToken={authToken} />, generateUnuthorizedPage())} />
                <Route path='/drivers/rigcommentform' element={compoundEyesOnly(useremailData, <RigCommentForm userName={useremailData} />, generateUnuthorizedPage())} />
                <Route path='/drivers/rigcomments' element={compoundEyesOnly(useremailData, <RigCommentTable userName={useremailData} />, generateUnuthorizedPage())} />
                <Route path='/drivers/appwalkthrough' element={driverAccess(useremailData, <AppWalkthrough />, generateUnuthorizedPage())} />


                {/* Dashboards */}
                <Route path='/dashboards' element={<DashboardsMain />} />
                <Route path='/dashboards/weather' element={driverAccess(useremailData, <Weather />, generateUnuthorizedPage())} />
                <Route path='/dashboards/annotationmetrics' element={cfAccess(useremailData, <AnnotationMetrics STS_PARAMS={stsParams} />, generateUnuthorizedPage())} />
                <Route path='/dashboards/cuboidmetrics' element={cfAccess(useremailData, <CuboidJobs STS_PARAMS={stsParams} />, generateUnuthorizedPage())} />
                <Route path='/dashboards/rigmetrics' element={compoundEyesOnly(useremailData, <DriverMetrics STS_PARAMS={stsParams} />, generateUnuthorizedPage())} />
                <Route path='/dashboards/annotationcosts' element={compoundEyesOnly(useremailData, <AnnotationCosts STS_PARAMS={stsParams} />, generateUnuthorizedPage())} />
                <Route path='/dashboards/reconstructions' element={driverAccess(useremailData,
                  <Reconstructions
                    AWS_ACCESS_KEY_ID={AWS_ACCESS_KEY_ID}
                    AWS_SECRET_KEY_ID={AWS_SECRET_KEY_ID}
                    AWS_SESSION_TOKEN={AWS_SESSION_TOKEN}
                    currentUser={useremailData.toString()}
                  />, generateUnuthorizedPage())}
                />
                <Route path='/dashboards/telemetry' element={compoundEyesOnly(useremailData,
                  <Telemetry
                    AWS_ACCESS_KEY_ID={AWS_ACCESS_KEY_ID}
                    AWS_SECRET_KEY_ID={AWS_SECRET_KEY_ID}
                    AWS_SESSION_TOKEN={AWS_SESSION_TOKEN}
                  />, generateUnuthorizedPage())}
                />
              </Routes>
            </div>
          </div >
        </>
      </ThemeProvider >
    </Router >
  );
}

export default withAuthenticator(App);