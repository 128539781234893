import React from 'react';
import RainDrop from '../Components/WeatherImages/RainDrop'

const Daily = (props) => {

    return (
        <div className='daily-box' style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            border: 'solid 2px black',

        }}
        >
            {props.dailyWeather.map((weather, index) => {
                return (
                    <div className='daily' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'scroll',

                    }}
                        key={index}
                    >
                        <div style={{
                            textAlign: 'center',
                            border: 'solid 1px black',
                            background: 'lightgrey'
                        }}
                        >
                            <p>
                                {props.daysOfWeek(props.timeFinder(weather.time * 1000).getDay())} | &nbsp;{props.timeFinder(weather.time * 1000).getMonth() % 12 + 1}/{props.timeFinder(weather.time * 1000).getDate()}
                            </p>
                            {props.dailyWeatherImage(weather.icon)}
                        </div>
                        <div style={{
                            border: 'solid 1px black',
                            borderBottomStyle: 'none',
                        }}
                        >
                            <p> <span style={{ textDecoration: 'underline' }}>{props.weatherSummary(weather.icon)}</span></p>
                            <p> <span style={{ color: 'rgb(95,205,245)' }}>{weather.temperatureLow}˚F</span>/ </p>
                            <p><span style={{ color: 'rgb(255,5,1' }}>{weather.temperatureHigh}˚F</span></p>
                            <p>Chance of <RainDrop size={15} />: {Math.floor(weather.precipProbability * 100)}%</p>
                            <p>'{weather.summary}'</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Daily;