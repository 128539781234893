import React from "react";
import { number } from 'prop-types';

function PartsMain(props) {
   return  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 64 64"
    >
      <path
        fill="#d3a06c"
        d="M61 42H42v19c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V43c0-.55-.45-1-1-1zM59 2H41c-.55 0-1 .45-1 1v19h19c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zM37 22c-.55 0-1 .45-1 1v10.15l-.01.01A8.898 8.898 0 0138 38.81V42h18V22z"
      ></path>
      <path fill="#b27946" d="M46 2h8v6h-8zM42 22h8v6h-8zM48 42h8v6h-8z"></path>
      <path
        fill="#656d78"
        d="M24 10h-8c-2.21 0-4 1.79-4 4h-1c-.8 0-1.53.32-2.06.82l-.5-1.5C8.15 12.45 8 11.53 8 10.6c0-2.38.96-4.52 2.52-6.08S14.22 2 16.6 2h6.8a8.6 8.6 0 018.16 11.32l-.5 1.5c-.53-.5-1.26-.82-2.06-.82h-1v-1h-3c-.64-.64-1-1.51-1-2.41z"
      ></path>
      <path
        fill="#e6e9ed"
        d="M28 56v6H12v-6h2c1.1 0 2-.9 2-2h3c.83 0 1.58-.34 2.12-.88S22 51.83 22 51c0-1.66-1.34-3-3-3h-7v-7c0-.55.45-1 1-1h3v3c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-3h3c.55 0 1 .45 1 1v7z"
      ></path>
      <path
        fill="#fcd770"
        d="M28 56h4v6h-4zM8 56h4v6H8zM2 50V38.81c0-4.48 3.29-8.28 7.73-8.91l3.41-.49L15 35l5-2 5 2 1.86-5.59 3.41.49c2.33.33 4.35 1.54 5.72 3.26A8.898 8.898 0 0138 38.81V50c0 3.31-2.69 6-6 6h-4V41c0-.55-.45-1-1-1H13c-.55 0-1 .45-1 1v7h4v6c0 1.1-.9 2-2 2H8c-3.31 0-6-2.69-6-6z"
      ></path>
      <path
        fill="#ffc729"
        d="M26.86 29.41L25 35l-5-2 4-4zM20 33l-5 2-1.86-5.59L16 29z"
      ></path>
      <path
        fill="#b4dd7f"
        d="M24 40v3c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1v-3z"
      ></path>
      <path
        fill="#f0d0b4"
        d="M19 48a2.996 2.996 0 012.12 5.12c-.54.54-1.29.88-2.12.88h-3v-6zM23.09 25.38L24 29l-4 4-4-4 .91-3.62c.95.4 2 .62 3.09.62s2.14-.22 3.09-.62zM31.06 14.82a2.993 2.993 0 01.06 4.3c-.54.54-1.29.88-2.12.88h-1.25c.16-.64.25-1.31.25-2v-4h1c.8 0 1.53.32 2.06.82zM12.25 20H11c-.83 0-1.58-.34-2.12-.88a2.993 2.993 0 01.06-4.3c.53-.5 1.26-.82 2.06-.82h1v4c0 .69.09 1.36.25 2z"
      ></path>
      <path
        fill="#f0d0b4"
        d="M25 13h3v5c0 .69-.09 1.36-.25 2a8.014 8.014 0 01-4.66 5.38c-.95.4-2 .62-3.09.62s-2.14-.22-3.09-.62c-.97-.4-1.84-.99-2.57-1.72-.99-1-1.72-2.25-2.09-3.66-.16-.64-.25-1.31-.25-2v-4c0-2.21 1.79-4 4-4h8v.59c0 .9.36 1.77 1 2.41z"
      ></path>
      <g>
        <circle cx="16" cy="16" r="1"></circle>
        <circle cx="24" cy="16" r="1"></circle>
        <path d="M23.789 20.658l.105-.211-1.789-.895-.105.212c-.381.762-1.147 1.236-2 1.236s-1.619-.474-2-1.236l-.105-.211-1.789.895.105.211C16.933 22.103 18.385 23 20 23s3.067-.897 3.789-2.342zM21.707 12.293A2.4 2.4 0 0121 10.586V9h-2v1.586c0 1.179.459 2.287 1.293 3.121zM25.707 12.293A2.4 2.4 0 0125 10.586V9h-2v1.586c0 1.179.459 2.287 1.293 3.121z"></path>
        <path d="M61 41h-4V23h2c1.103 0 2-.897 2-2V3c0-1.103-.897-2-2-2H41c-1.103 0-2 .897-2 2v18h-2c-1.103 0-2 .897-2 2v7.832a9.903 9.903 0 00-4.586-1.926l-5.607-.801-.545-2.181A9.042 9.042 0 0028.476 21H29c2.206 0 4-1.794 4-4 0-.901-.31-1.724-.814-2.393l.322-.967A9.618 9.618 0 0033 10.604C33 5.309 28.691 1 23.396 1h-6.793C11.309 1 7 5.309 7 10.604c0 1.033.166 2.055.492 3.037l.322.967A3.956 3.956 0 007 17c0 2.206 1.794 4 4 4h.525a9.038 9.038 0 004.214 4.924l-.545 2.181-5.607.801C4.691 29.605 1 33.86 1 38.806V50c0 3.519 2.614 6.432 6 6.92V63h26v-6.08c3.386-.488 6-3.401 6-6.92v-7h2v18c0 1.103.897 2 2 2h18c1.103 0 2-.897 2-2V43c0-1.103-.897-2-2-2zM47 3h2v4h-2zm4 0h2v4h-2zM41 3h4v6h10V3h4v18H41zm2 20h2v4h-2zm4 0h2v4h-2zm-21.464 7.23l-1.149 3.448-2.607-1.043 2.574-2.574zM29 19h-.059c.037-.329.059-.662.059-1v-3c1.103 0 2 .897 2 2s-.897 2-2 2zm-18 0c-1.103 0-2-.897-2-2s.897-2 2-2v3c0 .338.022.671.059 1zm2-1v-4c0-1.654 1.346-3 3-3h1V9h-1a5.01 5.01 0 00-4.899 4H11c-.535 0-1.045.11-1.512.301l-.097-.292A7.577 7.577 0 019 10.604C9 6.411 12.411 3 16.604 3h6.793C27.589 3 31 6.411 31 10.604c0 .818-.131 1.627-.391 2.404l-.098.293A3.977 3.977 0 0029 13v-1h-2v6c0 3.859-3.141 7-7 7s-7-3.141-7-7zm7 9c.827 0 1.625-.121 2.387-.331l.506 2.024L20 31.586l-2.893-2.893.506-2.024c.762.21 1.56.331 2.387.331zm-4.354 3.06l2.574 2.574-2.607 1.043-1.149-3.448zM9 57h2v4H9zm10-8c1.103 0 2 .897 2 2s-.897 2-2 2h-2v-4zm-2.184 6H19c2.206 0 4-1.794 4-4s-1.794-4-4-4h-6v-6h2v2c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2v-2h2v20H13v-4h1a2.996 2.996 0 002.816-2zM17 41h6v2h-6zm12 20v-4h2v4zm8-11c0 2.757-2.243 5-5 5h-3v-6h6v-2h-2v-8h-2v8h-2v-6c0-1.103-.897-2-2-2H13c-1.103 0-2 .897-2 2v6H9v-8H7v8H5v2h10v5a1 1 0 01-1 1H8c-2.757 0-5-2.243-5-5V38.806c0-3.956 2.953-7.36 6.869-7.919l2.583-.369 1.934 5.805L20 34.077l5.613 2.245 1.934-5.805 2.583.369c3.917.559 6.87 3.964 6.87 7.92zm2-9v-2.194a9.954 9.954 0 00-2-5.966V23h4v6h10v-6h4v18zm10 2h2v4h-2zm4 0h2v4h-2zm8 18H43V43h4v6h10v-6h4z"></path>
      </g>
    </svg>
  );
}

PartsMain.propTypes = {
    size: number
}
PartsMain.defaultProps = {
    size: 50
}

export default PartsMain;
